import React, {useEffect, useState} from "react";
import {fetchApi} from "../../../../../../../services/HTTP";
import AppText from "../../../../../../common/AppText";
import {Colors} from "../../../../../../../styles/Colors";
import {CREATE_EVENT_MODAL, openModal} from "../../../../../../../redux/modalManagerSlice";
import {flowOptions} from "../../../CreateEvent/FlowOptions";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import SimpleInput from "../../../../../../common/SimpleInput";
import {HStack} from "native-base";
import CustomButtonV2 from "../../../../../../common/CustomButtonV2";
import {t} from "../../../../../../../services/i18n";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {light} from "@fortawesome/fontawesome-svg-core/import.macro";
import {GlobalConsts} from "../../../../../../../styles/GlobalConsts";
import ConfirmModal from "../../../../../../common/ConfirmModal";
import {isMobile} from "../../../../../../../services/Helpers";
import {getScheduleCustomFields} from "../../../../../../../redux/ScheduleSlice";
import {SCHEDULE_TYPE} from "../../../../../../../services/ConstHelper";
import usePermissions from "../../../../../../../services/Permissions";

const CustomFields = (props) => {
    const [data, setData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [confirm, setConfirm] = useState(null);
    const [confirmLoader, setConfirmLoader] = useState(null);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const createEventModal = useSelector(state => state.modalManager.createEventModal)
    const scheduleTypeFields = useSelector(state => state.schedule.scheduleCustomFields);
    const {has} = usePermissions();

    useEffect(() => {
        if(!createEventModal.isOpen)
            dispatch(getScheduleCustomFields());
        },[createEventModal])

    useEffect(() => {
        setData(scheduleTypeFields);
        setFilteredData(scheduleTypeFields);
    },[scheduleTypeFields])

    const openCreateEditCustomField = (field) => {
        dispatch(openModal({modalName: CREATE_EVENT_MODAL, props: { initSelection: flowOptions.CUSTOM_FIELD, navigate, target: field ?? null, flow_type: field ? null : SCHEDULE_TYPE}}))
    }

    const handleSearch = (val) => {
        setSearchValue(val);
        if(val.length >= 2) {
            const filtered = data.filter(item => item.custom_field_name.toLowerCase().includes(val.toLowerCase()));
            setFilteredData(filtered);
        }
        else {
            setFilteredData(data);
        }
    }

    const handleDeleteField = async (fieldId) => {
        try {
            setConfirmLoader(true);
            await fetchApi('customField/deleteCustomField', 'POST', {custom_field_id: fieldId}, false, true);
            setFilteredData(prev => [...prev].filter(field => field.id !== fieldId));
            dispatch(getScheduleCustomFields());
        }
        catch(e) {
            console.log('Error deleting CustomField:', e);
        }
        finally {
            setConfirm(null);
            setConfirmLoader(null);
        }
    }

    const handleConfirm = (fieldId) => {
        setConfirm({
            isOpen: true,
            notAnError: false,
            header: t('deleteCustomFieldConfirmHeader'),
            subheader: t('deleteCustomFieldConfirmSubHeader'),
            onConfirm: () => handleDeleteField(fieldId),
        });
    }

    return (
        <>
            <HStack alignItems={'center'} justifyContent={'space-between'} space={'5px'}>
                <SimpleInput
                    placeholder={t('search')}
                    prefix={<FontAwesomeIcon icon={light('magnifying-glass')} size={GlobalConsts.iconSize.XS} color={Colors.newDesign.darkGrey}/>}
                    value={searchValue}
                    onChangeCallback={handleSearch}
                />
                {has('addCustomField') && <CustomButtonV2 wrapperStyle={{width: isMobile && '25%' }} type={'primary'} text={t('create')} onClick={() => openCreateEditCustomField()} />}
            </HStack>
            <div style={{ maxHeight: '380px', marginTop: '10px' }}>
                {
                    filteredData?.map((field) => (
                        <HStack key={'custom_field_' + field.custom_field_id} alignItems={'center'} style={{ cursor: has('editCustomField') ? 'pointer' : 'auto' ,paddingTop: '15px', paddingBottom: '15px', borderBottom: 'solid 1px ' + Colors.newDesign.mediumGrey }}>
                            <AppText onClick={has('editCustomField') ? () => openCreateEditCustomField(field) : null} style={{ flexGrow: 1 }}>{field.custom_field_name}</AppText>
                            {has('deleteCustomField') && <CustomButtonV2 type={'link'} iconName={'fa-trash'} onClick={() => handleConfirm(field.id)} style={{ paddingInlineEnd: '5px' }} />}
                        </HStack>
                    ))
                }
            </div>
            {
                confirm && <ConfirmModal
                    isOpen={confirm}
                    notAnError={confirm?.notAnError}
                    header={confirm?.header}
                    subheader={confirm?.subheader}
                    onConfirm={confirm?.onConfirm}
                    onCancel={() => setConfirm(null)}
                    onClose={() => setConfirm(null)}
                    confirmLoading={confirmLoader}
                    containerDOM={isMobile && '.event-click-popup'}
                />
            }
        </>
    );
};

export default CustomFields;
