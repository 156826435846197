import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {updateScheduleSetting} from "../../../../../../../redux/ScheduleSlice";
import {HStack, Switch, VStack} from "native-base";
import {Colors} from "../../../../../../../styles/Colors";
import AppText from "../../../../../../../components/common/AppText";
import {t} from "../../../../../../../services/i18n";
import SimpleInput from "../../../../../../../components/common/SimpleInput";
import {GlobalStyleAttributes} from "../../../../../../../styles/GlobalStyles";
import {isMobile} from "../../../../../../../services/Helpers";
import CustomSwitch from "../../../../../../common/CustomSwitch";

const WaitListEntrance = (props) => {
    const { values, setFieldValue, errors, handleChange } = props;
    const shouldSave = useSelector((state) => state.schedule.settingsSaveFlag);
    const [isChecked, setIsChecked] = useState(values.waitListOffset > 0);
    const dispatch = useDispatch();
    const mobile = isMobile
    const fontSize = GlobalStyleAttributes.fonts.standard16

    useEffect(() => {
        if(shouldSave) {
            save()
        }
    }, [shouldSave]);

    const save = () => {
        if(mobile) {
            const val = isChecked ? values.waitListOffset : 0
            setFieldValue('waitListOffset', val)
            dispatch(updateScheduleSetting({schedule_standby_offset: val}))
        }
    }

    return (
        <>
            {
                mobile ?
                    <VStack space={'1rem'}>
                        <HStack justifyContent={'space-between'} alignItems={'center'}>
                            <AppText fontSize={fontSize}>{t('ignoreWaitingListLbl')}</AppText>
                            <CustomSwitch isChecked={isChecked} onToggle={setIsChecked}/>
                        </HStack>
                        {isChecked &&
                        <>
                            <HStack space={'1rem'} alignItems={'center'}>
                                <SimpleInput value={values.waitListOffset}
                                             onChangeCallback={val => setFieldValue('waitListOffset', val)}
                                             width={'4rem'}
                                             inputType={'float'}
                                             status={errors.waitListOffset ? 'error' : null}
                                />
                                <AppText fontSize={fontSize}>{t('beforeClassStarts')}</AppText>
                            </HStack>
                            {errors.waitListOffset && <AppText fontSize={fontSize} color={Colors.error}>{errors.waitListOffset}</AppText>}
                        </>
                        }
                    </VStack>
                    :
                    <VStack space={'0.5rem'}>
                        <AppText fontSize={fontSize}>{t('ignoreWaitingListTxt')}</AppText>
                        <HStack space={'1rem'} alignItems={'center'}>
                            <SimpleInput value={values.waitListOffset}
                                         onChangeCallback={val => setFieldValue('waitListOffset', val)}
                                         width={'4rem'}
                                         inputType={'float'}
                                         status={errors.waitListOffset ? 'error' : null}
                            />
                            <AppText fontSize={fontSize}>{t('beforeClassStarts')}</AppText>
                        </HStack>
                        {errors.waitListOffset && <AppText fontSize={fontSize} color={Colors.error}>{errors.waitListOffset}</AppText>}
                    </VStack>
            }
        </>
    );
};

export default WaitListEntrance;
