import {fetchApi} from "./HTTP";
import {mediaUpdateFunctions} from "../Hooks/useMediaUpload";


    export  const generateSignatureCloudinary = async (params_to_sign, token, functionName, func) => {

        const mediaUploadWidget = window.cloudinary?.createUploadWidget({
            cloudName: 'arbox',
            apiKey: '382552225483965',
            sources: ['local', 'url'],
            multiple: false,
            uploadSignature: null,
            tags: [functionName === mediaUpdateFunctions.updateBoxLogo ? 'logo' : 'space', 'business'],
            cropping: true,
            croppingShowDimensions: true,
            croppingCoordinatesMode: 'custom',
            croppingShowBackButton: true,
            resourceType: 'image',
            clientAllowedFormats: ['svg', 'jpg', 'png', 'jpeg'],
            uploadPreset: 'jbb3bipm',
            maxImageFileSize: 2100000,
            folder: functionName=== mediaUpdateFunctions.updateSpaceImage ? 'spaces' : null

        }, (error, result) => {
            if (!error && result && result.event === "success") {
                const info = result.info;
                const data = {url: info.url,secure_url: info.secure_url,public_id: info.public_id, original_file_name: info.original_filename,
                    format: info.format,dimensions:{"width":624,"height":552}};
                    func(data);
            } else {

            }
        });

        const response = await fetchApi('cloudinary/generateSignature', 'POST', {params_to_sign, token} , true, true);
        mediaUploadWidget.open();
    }