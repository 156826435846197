import React, { useState } from 'react';
import { Button, HStack } from "native-base";
import SimpleInput from './SimpleInput';
import AppText from './AppText';
import { Colors } from '../../styles/Colors';
import { GlobalStyleAttributes } from '../../styles/GlobalStyles';
import { Flex, Popover } from 'antd';
import usePermissions from '../../services/Permissions';
import { isRTL, t } from '../../services/i18n';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data'
import { emojisTranslations, isMobile } from '../../services/Helpers';
import CustomButtonV2 from './CustomButtonV2';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';



const MessageTextArea = ({tags, onTagClick, setFieldValue, values, maxLength, touched, errors, name, placeholder, id}) => {
    const {has} = usePermissions();
    const [isEmojiOpen, setIsEmojiOpen] = useState(false);
    const onEmojiClick = (x) => {
        setFieldValue(name, (values[name] || '') + x.native)
    }
    
    return <>
    <Flex vertical>
        <HStack borderColor={Colors.borderColor} flexWrap={'wrap'} borderWidth={1} p={'0.3rem'} space={'0.3rem'} borderRadius={'4px 4px 0 0'} top={'1px'} alignItems={'center'} style={{rowGap: '0.3rem'}}>
            <AppText variant={GlobalStyleAttributes.textVariants.heading4}>{`${t('tags')}:`}</AppText>
            {tags.map(tag =>
                tag.id === 'space' ? has('editSpace') &&
                    (<Button
                        bgColor={Colors.newDesign.menuBar}
                        borderColor={Colors.newDesign.menuBar}
                        onPress={() => onTagClick(tag.label)}
                        key={tag.id}
                        width={'auto'}
                        // mb={'0.3rem'}
                        size={'md'} h={8}>
                        <AppText variant={GlobalStyleAttributes.textVariants.heading4}>{tag.label}</AppText>
                    </Button>) :
                    (<Button
                        bgColor={Colors.newDesign.menuBar}
                        borderColor={Colors.newDesign.menuBar}
                        onPress={() => onTagClick(tag.label)}
                        key={tag.id}
                        width={'auto'}
                        // mb={'0.3rem'}
                        size={'md'} h={8}>
                        <AppText variant={GlobalStyleAttributes.textVariants.heading4}>{tag.label}</AppText>
                    </Button>)
            )}
        </HStack>
        <SimpleInput inputType={'multiline'}
            rows={6}
            id={id}
            maxLength={maxLength}
            placeholder={placeholder}
            value={values[name]}
            onChangeCallback={(val) => setFieldValue(name, val)}
            status={touched[name] && errors[name] ? 'error' : null}
            styleOverride={{borderRadius: '0 0 4px 4px'}}
        />
        {!isMobile && <div style={{position: 'absolute', [isRTL() ? 'left' : 'right']: '10px', bottom: '10px'}}>
            <Popover trigger="click" onOpenChange={open => setIsEmojiOpen(open)} overlayInnerStyle={{padding: 0}} content={
                <Picker i18n={emojisTranslations()} data={data} onEmojiSelect={onEmojiClick} emojiButtonSize={32}/>
                } title="">
                <svg style={{width: 0, height: 0, position: 'absolute'}}>
                    <linearGradient x1="0%" x2="0%" y1="0%" y2="100%" id="yellowish-gradient">
                        <stop offset="50%" stopColor="#FFD00D"/>
                        <stop offset="100%" stopColor="#FFAC33"/>
                    </linearGradient>
                </svg>
                <CustomButtonV2 override={({isHovered}) => <FontAwesomeIcon style={{ color: isEmojiOpen ? 'url(#yellowish-gradient)' :(isHovered ? Colors.newDesign.mainText : Colors.newDesign.darkGrey) }} icon={regular('face-smile-wink')}/>}/>
            </Popover>
        </div>}
    </Flex>
    </>
}
export default MessageTextArea;