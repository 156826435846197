import React, {useEffect, useMemo, useState} from 'react';
import EStyleSheet from "react-native-extended-stylesheet";
import {dbTimeFormat, isMobile} from "../../../../../../../services/Helpers";
import {HStack, VStack, Text as NBText} from "native-base";
import {t} from "../../../../../../../services/i18n";
import {useDispatch, useSelector} from "react-redux";
import {updateScheduleSetting} from "../../../../../../../redux/ScheduleSlice";
import AppText from "../../../../../../common/AppText";
import {Colors} from "../../../../../../../styles/Colors";
import DropDownMenu from "../../../../../../common/DropDownMenu";
import {GlobalStyleAttributes, WebGlobalStyles} from "../../../../../../../styles/GlobalStyles";
import SharedTimepicker from "../../../../../../common/SharedTimepicker";
import moment from "moment";
import FormInput from '../../../../../../common/FormInput';
import {Radio, Space} from "antd";

const RevealTime = (props) => {
    const { constantPadding, values, touched, errors, setFieldValue, setFieldTouched } = props;
    const shouldSave = useSelector((state) => state.schedule.settingsSaveFlag);
    const box = useSelector((state) => state.box.box);
    const [selectedOption, setSelectedOption] = useState(values.revealTime ? 'setDate' : 'showAll');
    const dispatch = useDispatch()
    const mobile = isMobile
    const fontSize = GlobalStyleAttributes.fonts.standard16

    const days = useMemo(() => {
        const items = [t('sunday'), t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday')]
        return items.map((item, i) => ({id: i, name: item}))
    }, []);

    useEffect(() => {
        if(selectedOption === 'showAll') {
            setFieldValue('revealWeeks', 0)
            setFieldValue('revealDay', 0)
            setFieldValue('revealTime', null)
        } else if(selectedOption === 'setDate') {
            setFieldValue('revealWeeks', box.showing_classes_week_ago ?? 0)
            setFieldValue('revealDay', box.showing_classes_day_ago ?? 0)
            setFieldValue('revealTime', box.showing_classes_time_ago ?? '08:00:00')
        }
    }, [selectedOption]);

    useEffect(() => {
        if(shouldSave) {
            save()
        }
    }, [shouldSave]);

    const save = () => {
        if(mobile) {
            const data = {
                showing_classes_week_ago: values.revealWeeks,
                showing_classes_day_ago: values.revealDay,
                showing_classes_time_ago: values.revealTime //TODO: USE BOX TIME FORMAT AND PASS IT AS DB FORMAT!!!
            }
            dispatch(updateScheduleSetting(data))
        }
    }

    const setVal = (e, name) => {
        setFieldTouched(name);
        setFieldValue(name, e)
    }

    const renderRadioGroup = () => {
        return (
            <Radio.Group value={selectedOption} onChange={e => setSelectedOption(e.target.value)} space={'1rem'} bg={Colors.newDesign.white} p={constantPadding}>
                <Space direction="vertical">
                    <Radio value="showAll" size="sm">
                        <AppText fontSize={fontSize}>{t('showAllFutureSessions')}</AppText>
                    </Radio>
                    <Radio value="setDate" size="sm">
                        <AppText fontSize={fontSize}>{t('selectWhen')}</AppText>
                    </Radio>
                </Space>
            </Radio.Group>
        )
    }

    const renderDayAndTimeInputs = () => {
        return (
            <>
                <AppText fontSize={fontSize}>{t('every')}</AppText>
                <DropDownMenu title={days[values.revealDay].name}
                              items={days}
                              onPress={(e) => setVal(e, 'revealDay')}
                              fontSize={fontSize}
                              showBorder={true}
                              width={'8.2rem'}
                              styleOverride={{height: '28px'}}
                />
                <AppText fontSize={fontSize}>{t('at')}</AppText>
                {values.revealTime && <VStack style={{width: '110px'}}><SharedTimepicker type={'time'} value={moment(values.revealTime, dbTimeFormat)} onChange={(e) => setVal(e.m.format(dbTimeFormat), 'revealTime')} inputStyle={{alignItems: 'center', height: `${EStyleSheet.value('$smallInputHeight')}px`}}/></VStack>}
            </>
        )
    }

    return (
        <VStack space={'1rem'}>
            <VStack space={'1rem'}>
                {!mobile && <AppText fontSize={fontSize}>{t('SetTimeToRevealTxt')}</AppText>}
                {renderRadioGroup()}
            </VStack>
            {selectedOption === 'setDate' &&
            <VStack space={'1rem'}>
                <HStack space={'0.5rem'} alignItems={'center'}>
                    {renderDayAndTimeInputs()}
                </HStack>
                <NBText style={{alignItems: 'center', direction: 'inherit'}}>
                    <AppText fontSize={fontSize}>{t('revealFutureSessionsTxt')}</AppText>
                    <FormInput value={values.revealWeeks}
                               onChangeCallback={(e) => setVal(e, 'revealWeeks')}
                               containerProps={{mb: 0, marginX: '0.5rem'}}
                               numericOnly={true}
                               width={'3.5rem'}
                               status={errors.revealWeeks && touched.revealWeeks ? 'error' : null}
                    />
                    <AppText fontSize={fontSize}>{t('weeksAheadSinceThe')}</AppText>
                </NBText>
                {(touched.revealWeeks && errors.revealWeeks) && <AppText fontSize={fontSize} color={Colors.error}>{errors.revealWeeks}</AppText>}
            </VStack>
            }
        </VStack>
    );
};

export default RevealTime;
