import React, {useMemo} from 'react';
import RevealTime from "./SettingOptions/Bookings/RevealTime";
import * as yup from "yup";
import {t} from "../../../../../services/i18n";
import {store} from "../../../../../redux/store";
import ClassSwapping from "./SettingOptions/Bookings/ClassSwapping";
import BookingNotifications from "./SettingOptions/Notifications/BookingNotifications";
import WaitListEntrance from "./SettingOptions/WaitingList/WaitListEntrance";
import CancellationTime from "./SettingOptions/WaitingList/CancellationTime";
import SmsNotification from "./SettingOptions/Notifications/SmsNotification";
import AppointmentPolicy from "./SettingOptions/AppointmentPolicy/AppointmentPolicy";
import EmbedSchedule from "./SettingOptions/EmbedSchedule/EmbedSchedule";
import {isMobile} from "../../../../../services/Helpers";
import WaitListEntranceApproval from './SettingOptions/WaitingList/WaitListEntranceApproval';
import QuickViewDisplay from "./SettingOptions/EmbedSchedule/QuickViewDisplay";
import CustomFields from "./SettingOptions/CustomFields/CustomFields";
import {closeModal, SCHEDULE_SETTINGS_MODAL, openModal, FLOW_MANAGER_MODAL} from "../../../../../redux/modalManagerSlice";
import WhatsappNotification from './SettingOptions/Notifications/WhatsappNotification';
import { NOTIFICATION_SETTINGS } from '../../../../../services/ConstHelper';
import AutoMessages from "./SettingOptions/Notifications/AutoMessages";
import ActiveIndicator from "./SettingOptions/Notifications/ActiveIndicator";
import {modalFlowOptions} from "../../../Popups/ModalFlow/ModalFlowOptions";

export const getScheduleSettingsConfig = (has, dispatch) => {
    const box = store.getState().box.box
    return [
        {
            id: 'bookings',
            title: 'bookings',
            options: [
                {
                    header: 'futureSessionRevealingTime',
                    subheader: t('SetTimeToRevealTxt'),
                    explanation: 'SetTimeToRevealTxt',
                    tooltipText: t('display-classes-tooltip-text'),
                    body: <RevealTime/>,
                    validationSchema: yup.object().shape({
                        revealWeeks: yup.number().min(0, t('onlyPositive')).required(t('thisFieldIsMandatory')).typeError(t('onlyPositive')),
                        revealDay: yup.number().min(0).max(6).required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
                        revealTime: yup.string().nullable().typeError(t('thisFieldIsMandatory'))
                    }),
                    initialValues: {revealWeeks: box.showing_classes_week_ago || 0, revealDay: box.showing_classes_day_ago || 0, revealTime: box.showing_classes_time_ago || null}
                },
                {
                    header: 'sessionSwappingLbl',
                    subheader: `${box.schedule_swapping_time} ${t('hoursAfterCancellation')}`,
                    explanation: 'sessionSwappingTxt',
                    tooltipText: t('classes-swapping-tooltip-text'),
                    body: <ClassSwapping/>,
                    validationSchema: yup.object().shape({
                        swapMinutes: yup.number().min(0, t('onlyPositive')).required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
                        allowWaitlist: yup.boolean()
                    }),
                    initialValues: { swapMinutes: box.schedule_swapping_time || 0, allowWaitlist: box.schedule_swapping_standby_time > 0}
                },
            ]
        },
        {
            id: 'notifications',
            title: 'notificationsSettingsLbl',
            options: [
                {
                    header: isMobile ? 'sessionReminderMobileLbl' : '',
                    subheader: t('sessionReminderMobileTxt'),
                    body: isMobile ? null : <AutoMessages messageType={autoMessageTypes.REMINDER_SESSION} header={t('sessionReminderLbl')} subheader={t('sessionReminderTxt')} showSectionHeader={true}/>,
                    validationSchema: yup.object().shape({}),
                    initialValues: {},
                    headerStart: <ActiveIndicator isActive={getIsMessageTypeActive(autoMessageTypes.REMINDER_SESSION)}/>,
                    hideDivider: true,
                    permission: 'appointmentAutoMessages',
                    onClick: () => openModalFlowManager(dispatch, modalFlowOptions.SESSION_AUTO_MESSAGE, {
                        initProps: {messageType: autoMessageTypes.REMINDER_SESSION},
                    })
                },
                {
                    header: isMobile ? 'bookSessionAutoMessageMobileLbl' : '',
                    subheader: t('bookSessionReminderMobileTxt'),
                    body: isMobile ? null : <AutoMessages messageType={autoMessageTypes.BOOK_SESSION} header={t('bookSessionAutoMessageLbl')} subheader={t('bookSessionReminderTxt')}/>,
                    validationSchema: yup.object().shape({}),
                    initialValues: {},
                    headerStart: <ActiveIndicator isActive={getIsMessageTypeActive(autoMessageTypes.BOOK_SESSION)}/>,
                    hideDivider: true,
                    permission: 'appointmentAutoMessages',
                    onClick: () => openModalFlowManager(dispatch, modalFlowOptions.SESSION_AUTO_MESSAGE, {
                        initProps: {messageType: autoMessageTypes.BOOK_SESSION},
                    })
                },
                {
                    header: isMobile ? 'editSessionAutoMessageMobileLbl' : '',
                    subheader: t('editSessionReminderMobileTxt'),
                    body: isMobile ? null : <AutoMessages messageType={autoMessageTypes.EDIT_SESSION} header={t('editSessionAutoMessageLbl')} subheader={t('editSessionReminderTxt')}/>,
                    validationSchema: yup.object().shape({}),
                    initialValues: {},
                    headerStart: <ActiveIndicator isActive={getIsMessageTypeActive(autoMessageTypes.EDIT_SESSION)}/>,
                    hideDivider: true,
                    permission: 'appointmentAutoMessages',
                    onClick: () => openModalFlowManager(dispatch, modalFlowOptions.SESSION_AUTO_MESSAGE, {
                        initProps: {messageType: autoMessageTypes.EDIT_SESSION},
                    })
                },
                {
                    header: isMobile ? 'cancelSessionAutoMessageMobileLbl' : '',
                    subheader: t('cancelSessionReminderMobileTxt'),
                    body: isMobile ? null : <AutoMessages messageType={autoMessageTypes.CANCEL_SESSION} header={t('cancelSessionAutoMessageLbl')} subheader={t('cancelSessionReminderTxt')}/>,
                    validationSchema: yup.object().shape({}),
                    initialValues: {},
                    headerStart: <ActiveIndicator isActive={getIsMessageTypeActive(autoMessageTypes.CANCEL_SESSION)}/>,
                    permission: 'appointmentAutoMessages',
                    onClick: () => openModalFlowManager(dispatch, modalFlowOptions.SESSION_AUTO_MESSAGE, {
                        initProps: {messageType: autoMessageTypes.CANCEL_SESSION},
                    })
                },
                {
                    header: 'bookingReminderLbl',
                    subheader: `${box.notification_scheduling} ${t('beforeClassStarts')}`,
                    explanation: 'bookingReminderMobileTxt',
                    body: <BookingNotifications/>,
                    validationSchema: yup.object().shape({
                        notifyHour: yup.number().min(1, t('onlyPositive')).required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory'))
                    }),
                    initialValues: {notifyHour: box.notification_scheduling || 1}
                },
                {
                    header: isMobile ? 'smsNotificationLbl' : '',
                    subheader: t('smsNotificationTxt'),
                    explanation: null,
                    body: isMobile ? null : <SmsNotification/>,
                    sideOverride: isMobile ? <SmsNotification/> : null,
                    validationSchema: yup.object().shape({
                        allowSmsWaitList: yup.boolean()
                    }),
                    initialValues: { allowSmsWaitList: box.allow_sms_to_waiting_list || 0 }
                },
                ...(box.integrations?.find(i => i.name === 'greenApi') ? [{
                    header: isMobile ? 'whatsappNotificationLbl' : '',
                    subheader: t('whatsappNotificationTxt'),
                    explanation: null,
                    body: isMobile ? null : <WhatsappNotification/>,
                    sideOverride: isMobile ? <WhatsappNotification/> : null,
                    validationSchema: yup.object().shape({
                        allowWhatsappWaitList: yup.boolean().nullable()
                    }),
                    initialValues: { allowWhatsappWaitList: box.allow_whatsapp_to_waiting_list ?? (box.settings?.[NOTIFICATION_SETTINGS]?.allow_whatsapp_to_waiting_list || 0) }
                }] : []),
            ]
        },
        {
            id: 'waitingList',
            title: 'waitingListSettingsLbl',
            options: [
                {
                    header: 'waitListEntranceApproval',
                    subheader: null,
                    tooltipText: t('waitListEntranceApprovalTxt'),
                    body: <WaitListEntranceApproval/>,
                    validationSchema: yup.object().shape({
                        standbyTimeToApprove: yup.number().nullable().min(1, t('time-to-approve-range-1-60')).max(60, t('time-to-approve-range-1-60')).typeError(t('only-numbers'))
                    }),
                    initialValues: { standbyTimeToApprove: box.standby_time_to_approve},
                    permission: 'waitingListApproval'
                },
                {
                    header: 'ignoreWaitingListLbl',
                    subheader: `${box.schedule_standby_offset} ${t('beforeClassStarts')}`,
                    explanation: 'stopAddingClientsTxt',
                    // tooltipText: t('ignoreWaitingList-tooltip-text'),
                    body: <WaitListEntrance/>,
                    validationSchema: yup.object().shape({
                        waitListOffset: yup.number().min(0, t('onlyPositive')).required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory'))
                    }),
                    initialValues: { waitListOffset: box.schedule_standby_offset || 0}
                },
                {
                    header: isMobile ? 'cancelationTimeLbl' : '',
                    subheader: `${box.standby_cancellation_time} ${t('afterJoining')}`,
                    explanation: 'cancelationTimeTxt',
                    // tooltipText: t('cancellation-time-tooltip-text'),
                    body: <CancellationTime/>,
                    validationSchema: yup.object().shape({
                        cancellationTime: yup.number().min(0, t('onlyPositive')).required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory'))
                    }),
                    initialValues: { cancellationTime: box.standby_cancellation_time || 0}
                },
            ]
        },
        {
            id: 'cancellation',
            title: 'cancellationsPolicy',
            options: [
                {
                    header: isMobile ? 'cancellationsPolicy' : '',
                    subheader: null,
                    explanation: null,
                    body: <AppointmentPolicy/>,
                    validationSchema: yup.object().shape({
                        apptCancelTime: yup.number().min(0, t('onlyPositive')).typeError(t('thisFieldIsMandatory')),
                        apptCancelEnabled: yup.boolean().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
                        apptCancelPolicy: yup.string().nullable()
                    }),
                    initialValues: { apptCancelTime: box?.locations_box[0].availability_time_cancellation || 0,
                                     apptCancelEnabled: box?.locations_box[0].availability_enable_late_cancellation || false,
                                     apptCancelPolicy: box?.locations_box[0].cancellation_policy }
                },
            ]
        },
        {
            id: 'embedSchedule',
            title: 'embedScheduleLabel',
            hideOnMobile: true,
            options: [
                {
                    header: '',
                    subheader: null,
                    explanation: null,
                    body: <QuickViewDisplay />,
                    validationSchema: yup.object().shape({
                        showSchedulePreview: yup.number().min(0)
                    }),
                    initialValues: { showSchedulePreview: box?.show_schedule_preview },
                    hide: box.isBoxHasNewSite
                },
                {
                    header: 'embedSchedule',
                    subheader: null,
                    explanation: null,
                    body: <EmbedSchedule/>,
                    validationSchema: yup.object().shape({}),
                    initialValues: {}
                },
            ]
        },
        ...((has('customFieldSettingsLock') || has('editCustomField') || has('deleteCustomField') || has('addCustomField')) ? [{
            id: 'customFields',
            title: 'customFieldsLabel',
            hasLock: has('customFieldSettingsLock'),
            optionalLockCallback: () => dispatch(closeModal({ modalName: SCHEDULE_SETTINGS_MODAL })),
            options: [
                {
                    header: 'customFieldsLabel',
                    subheader: null,
                    explanation: null,
                    body: <CustomFields />,
                    initialValues: { showSchedulePreview: box?.show_schedule_preview }
                }
            ]
        }] : []),
        {
            id: 'quickView',
            title: 'quickViewLabel',
            hideOnMobile: true,
            hide: !box.isBoxHasNewSite,
            options: [
                {
                    header: '',
                    subheader: null,
                    explanation: null,
                    body: <QuickViewDisplay />,
                    validationSchema: yup.object().shape({
                        showSchedulePreview: yup.number().min(0)
                    }),
                    initialValues: { showSchedulePreview: box?.show_schedule_preview }
                },
            ]
        },
    ]
}

const getTeamNotificationSubheader = (box) => {
    switch (box.notify_session_owner) {
        case 1:
            return 'notifySessionOwnerBySms'
        case 2:
            return 'notifySessionOwnerByEmail'
        case 3:
            return 'notifySessionOwnerBySmsAndEmail'
        default:
            return null
    }
}

export const autoMessageTypes = {
    BOOK_SESSION: 'BOOK_SESSION',
    EDIT_SESSION: 'EDIT_SESSION',
    CANCEL_SESSION: 'CANCEL_SESSION',
    REMINDER_SESSION: 'REMINDER_SESSION'
}

export const autoMessageTimeUnits = {
    HOUR: 'HOUR',
    DAY: 'DAY',
    WEEK: 'WEEK',
}

const openModalFlowManager = (dispatch, initSelection, props = {}, onClose = null) => {
    dispatch(openModal({
        modalName: FLOW_MANAGER_MODAL,
        props: {
            initSelection,
            ...props
        },
        onClose
    }))
}

const getIsMessageTypeActive = (messageType) => {
    const box = store.getState().box.box
    const autoActions = box?.auto_actions
    const messageTypeAutoActions = autoActions && autoActions.length ? autoActions.filter(item => item.auto_actions.action === messageType) : []
    return messageTypeAutoActions.some(item => item.active);
}