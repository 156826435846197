import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import {Box, HStack, VStack} from 'native-base';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from '../../../../../../../services/Helpers';
import { t } from '../../../../../../../services/i18n';
import usePermissions from '../../../../../../../services/Permissions';
import { Colors } from '../../../../../../../styles/Colors';
import { GlobalStyleAttributes } from '../../../../../../../styles/GlobalStyles';
import AppText from '../../../../../../common/AppText';
import SimpleInput from '../../../../../../common/SimpleInput';
import {Radio} from "antd";
import {updateScheduleSetting} from "../../../../../../../redux/ScheduleSlice";

const WaitListEntranceApproval = props => {
    const { values, setFieldValue, errors, handleChange } = props;
    const shouldSave = useSelector((state) => state.schedule.settingsSaveFlag);
    const dispatch = useDispatch();
    const { hasLock } = usePermissions();
    const isDisabled = hasLock('waitingListApproval');

    useEffect(() => {
        if(shouldSave) {
            save()
        }
    }, [shouldSave]);

    const save = () => {
        if(isMobile) {
            dispatch(updateScheduleSetting({standby_time_to_approve: values.standbyTimeToApprove}))
        }
    }

    return (
        <>
            <Radio.Group value={isDisabled ? null : values.standbyTimeToApprove} onChange={e => setFieldValue('standbyTimeToApprove', e.target.value)} space={'1rem'} bg={Colors.newDesign.white}>
                <Radio value={null} size="sm">
                    <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('automatic-entry-from-a-waiting-list')}</AppText>
                </Radio>
                <Radio disabled={isDisabled} value={values.standbyTimeToApprove ?? '1'} size="sm">
                    <AppText style={isMobile ? {height: '1.8rem'} : {alignItems: 'center', display: 'flex'}}>
                        <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('confirm-entry-to-the-session-from-a-waiting-list')}</AppText>
                        <SimpleInput value={values.standbyTimeToApprove || 0}
                                     onChangeCallback={nextValue => setFieldValue('standbyTimeToApprove', parseInt(nextValue))}
                                     width={'4rem'}
                                     styleOverride={{margin: '0 0.5rem'}}
                                     numericOnly={true}
                        />
                        <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('minutes')}</AppText>
                        {isDisabled && <Box mx={'0.2rem'} top={'0.2rem'}><FontAwesomeIcon icon={'fa-light fa-lock-keyhole'}/></Box>}
                    </AppText>
                </Radio>
            </Radio.Group>
            <AppText fontSize={GlobalStyleAttributes.fonts.standard16} mt={isMobile && '2rem'} color={Colors.error}>{t(errors.standbyTimeToApprove)}</AppText>
        </>
    );
};

export default WaitListEntranceApproval;