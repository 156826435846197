import React from 'react';
import {Colors} from "../../../../../../../styles/Colors";

const ActiveIndicator = (props) => {
    const { isActive } = props;

    return (
        <div style={{
            background: isActive ? Colors.active : Colors.inactive,
            width: '8px',
            height: '8px',
            content: 'hi',
            borderRadius: '50%'
        }}/>
    );
};

export default ActiveIndicator;