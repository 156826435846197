import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchApi } from "../services/HTTP";
import { store } from '../redux/store';
import {clearAll, clearOnBoxSwitch} from "./userSettingsSlice";
import { freshpaintReset } from '../services/Helpers';
import {isAccessControl, logoutAC} from "./accessControlSlice";
import {setSessionFlag} from "./rootManagerSlice";
import {clearTasksOnBoxSwitch} from "./tasksSlice";

export const login = createAsyncThunk('login', async (params, { rejectWithValue }) => {
  try {
    const response = await fetchApi('login', 'post', { email: params?.email, password: params?.password, ssoLogin: params?.ssoLogin, boxesId: params?.boxesId, checkin: params?.checkin }, true, true);
    return {
      token: response.token,
      box_id: response.boxesId,
      lang_id: null,
    };
  } catch (e) {
    return rejectWithValue(e.error.messageToUser)
  }
})

export const loginAccessControl = createAsyncThunk('loginAccessControl', async (params, { dispatch, rejectWithValue }) => {
  try {
    const response = await fetchApi('loginAccessControl', 'post', { email: params?.email, password: params?.password, ssoLogin: params?.ssoLogin, boxesId: params?.boxesId }, true, true);
    dispatch(isAccessControl())
    return {
      token: response.token,
      box_id: response.boxesId,
      lang_id: null,
    };
  } catch (e) {
    return rejectWithValue(e.error.messageToUser)
  }
})

export const loginWithToken = createAsyncThunk('loginWithToken', async (params, { rejectWithValue }) => {
  try {
    const response = await fetchApi('loginWithToken', 'post', params, true, true);
    if (params.isOldServerToken) {
      localStorage.setItem('ls.userOld', localStorage.getItem('ls.user'));
      localStorage.removeItem('ls.user');
    }
    return {
      token: response.token,
      box_id: response.boxesId,
      lang_id: null,
    };
  } catch (e) {
    if (params.isOldServerToken) {
      localStorage.setItem('ls.user', localStorage.getItem('ls.userOld'));
      localStorage.removeItem('ls.userOld');
    }
    return rejectWithValue(e.error.messageToUser)
  }
})

export const changeBox = createAsyncThunk('changeBox', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await fetchApi('changeBox', 'POST', params, false, true);
    dispatch(clearOnBoxSwitch())
    dispatch(clearTasksOnBoxSwitch())
    return {
      token: response.token,
      box_id: response.boxesId,
      lang_id: null,
    };
  } catch (e) {
    return rejectWithValue(e.error.message)
  }
})

export const logout = createAsyncThunk('logout', async (_, { rejectWithValue, dispatch }) => {
  try {
    freshpaintReset();
    const response = await fetchApi('logout', 'POST', null, false, true);
    return null;
  } catch (e) {
    return null;
  }
  finally {
    dispatch(setSessionFlag(false))
    dispatch(clearAll())
    dispatch(logoutAC())
    Intercom?.('shutdown');
  }
})

export const assumeUser = createAsyncThunk('assumeUser', async (params, { rejectWithValue , dispatch}) => {
  try {
    const response = await fetchApi('assumeUser', 'POST', params, false, true);
    return {
      token: response.token,
      box_id: response.boxesId,
    };
  } catch (e) {
    return rejectWithValue(e.error.message)
  } finally {
    dispatch(clearAll())
  }
})


const authSlice = createSlice({
  name: "auth",
  initialState: {
    data: null,
    loading: false,
    error: ''
  },
  reducers: {
    setLang: (state, data) => {
      if (state.data) {
        state.data.lang_id = data.payload;
      }
    },
    assumeUserLogout: (state, data) => {
      state.data.token = data.payload.token;
      state.data.box_id = data.payload.box_id;
      state.data.originalUser = null;
      state.data.redirectToArgaz = true;
    },
    setIdentified: (state, data) => {
      state.data.identified = data.payload;
    },
    initAuth: (state) => {
      state.loading = false;
      state.error = '';
    }
  },
  extraReducers: builder => {
    // login
    builder.addCase(login.pending, state => {
      state.loading = true
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
      state.error = ''
    })
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false
      state.data = null
      state.error = action.payload
    })
    builder.addCase(loginAccessControl.pending, state => {
      state.loading = true
    })
    builder.addCase(loginAccessControl.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
      state.error = ''
    })
    builder.addCase(loginAccessControl.rejected, (state, action) => {
      state.loading = false
      state.data = null
      state.error = action.payload
    })
    // loginWithToken
    builder.addCase(loginWithToken.pending, state => {
      state.loading = true
    })
    builder.addCase(loginWithToken.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
      state.error = ''
    })
    builder.addCase(loginWithToken.rejected, (state, action) => {
      state.loading = false
      state.data = null
      state.error = action.payload
    })
    // change box
    builder.addCase(changeBox.pending, state => {
      state.loading = true
    })
    builder.addCase(changeBox.fulfilled, (state, action) => {
      state.loading = false
      state.data = { ...state.data, ...action.payload }
      state.error = ''
    })
    builder.addCase(changeBox.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    // logout
    builder.addCase(logout.pending, state => {
      state.loading = true
    })
    builder.addCase(logout.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
      state.error = ''
    })
    builder.addCase(logout.rejected, (state, action) => {
      state.loading = false
      state.data = null
      state.error = action.payload
    })
    // assumeUser
    builder.addCase(assumeUser.pending, state => {
      state.loading = true
    })
    builder.addCase(assumeUser.fulfilled, (state, action) => {
      state.loading = false
      state.data = { ...state.data, ...action.payload, originalUser: state.data, redirectToArgaz: false };
      state.error = '';
      // window.location.href = '/';
    })
    builder.addCase(assumeUser.rejected, (state, action) => {
      state.loading = false
    })
  }
});

export const { setLang, assumeUserLogout, setIdentified, initAuth } = authSlice.actions;

export default authSlice.reducer;