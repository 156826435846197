import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {isMobile} from "../../../../../../../services/Helpers";
import CustomSwitch from "../../../../../../common/CustomSwitch";
import {HStack, VStack} from "native-base";
import AppText from "../../../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../../../styles/GlobalStyles";
import {t} from "../../../../../../../services/i18n";
import {Colors} from "../../../../../../../styles/Colors";
import {FLOW_MANAGER_MODAL, openModal} from "../../../../../../../redux/modalManagerSlice";
import {modalFlowOptions} from "../../../../../Popups/ModalFlow/ModalFlowOptions";
import ActiveIndicator from "./ActiveIndicator";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {light} from "@fortawesome/fontawesome-svg-core/import.macro";
import CustomButtonV2 from "../../../../../../common/CustomButtonV2";

const AutoMessages = (props) => {
    const { messageType, header, subheader, showSectionHeader} = props;
    const dispatch = useDispatch();
    const autoActions = useSelector(state => state.box.box.auto_actions)
    const messageTypeAutoActions = useMemo(() => {
        if(autoActions && autoActions.length) {
            return autoActions.filter(item => item.auto_actions.action === messageType)
        }
        return []
    }, [autoActions]);
    const hasActiveAction = useMemo(() => messageTypeAutoActions.some(item => item.active), [messageTypeAutoActions]);

    const openAutoMessageModal = () => {
        dispatch(openModal({
            modalName: FLOW_MANAGER_MODAL,
            props: {
                initSelection: modalFlowOptions.SESSION_AUTO_MESSAGE,
                initProps: {messageType},
                target: messageTypeAutoActions,
                onSubmit: (values) => console.log('save this', values),
            },
            onClose: () => console.log('closed')}))
    }

    return (
        <CustomButtonV2 onClick={openAutoMessageModal} wrapperStyle={{width: '100%'}} override={({isHovered}) =>
            <VStack space={'0.7rem'} width={'inherit'}>
                {showSectionHeader && <AppText fontSize={GlobalStyleAttributes.fonts.standard16} boldFont>{t('autoMessageLbl')}</AppText>}
                <VStack space={'0.3rem'} borderWidth={'1px'} borderColor={isHovered ? Colors.newDesign.mainBlue : Colors.borderColor} borderRadius={'5px'} padding={'15px'}>
                    <HStack space={'0.5rem'} alignItems={'center'}>
                        <ActiveIndicator isActive={hasActiveAction}/>
                        <AppText fontSize={GlobalStyleAttributes.fonts.standard16} boldFont>{header}</AppText>
                    </HStack>
                    <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{subheader}</AppText>
                </VStack>
            </VStack>
        }/>
    );
};

export default AutoMessages;