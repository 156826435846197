import { Flex } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import DaysHoursConfiguration from '../../../common/DaysHoursConfiguration';
import FoldableSettingSection from '../../../common/FoldableSettingSection';
import { t } from '../../../../services/i18n';
import { days as weekDays } from '../../../../services/Helpers';


const GatesActivityHours = ({values, setFieldValue, errors}) => {
    const box = useSelector((state) => state.box.box);
    const locations = useMemo(() => box?.locations_box, [box]);
    const hasMultiLocation = locations.length > 1;
    const [gatesFoldable, setGatesFoldable] = useState({0: true});
    const foldableEdit = !(values?.apply === 'all' || values?.gates.length === 1);
    const days = useMemo(() => weekDays(), [])

    const getActivityHoursSummary = (gate) => {
        if (gate?.multiDayConfig?.length) {
            return t('activity-hours-set-description', 
                {days: gate?.multiDayConfig?.map(setting => days[setting.day].name).join(', ')});
        }
        return t('no-activity-hours-set');
    }

    return (
        <Flex vertical gap={'0.5rem'}>
            {foldableEdit ? values?.gates.map((gate, index) => (
                <FoldableSettingSection 
                    header={`${gate.name}${hasMultiLocation ? ` (${locations.find(location => location.id === gate.locations_box_id)?.location})` : ''}`} 
                    key={gate.id} 
                    isOpen={gatesFoldable[index]} onToggle={() => setGatesFoldable({...gatesFoldable, [index]: !gatesFoldable[index]})}
                    summary={getActivityHoursSummary(gate)}>
                    <DaysHoursConfiguration values={values.gates?.[index]} setFieldValue={(name, value) => setFieldValue(`gates.${index}.${name}`, value)} errors={errors.gates?.[index]} />
                </FoldableSettingSection>
            )) : 
                <DaysHoursConfiguration {...{values, setFieldValue, errors, generateSummary: () => getActivityHoursSummary(values)}} /> }
        </Flex>
    );
}

export default GatesActivityHours;