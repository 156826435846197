import {createSlice} from '@reduxjs/toolkit';
import {isMobile} from "../services/Helpers";

const userSettingsSlice = createSlice({
    name: "userSettings",
    initialState: {
        lang: null,
        dir: 'ltr',
        visitedDesktop: !isMobile,
        scheduleLocation: null,
        hasClient: false,
        scheduleViews: {},
        showScheduleUpgrade: null,
        viewProductUpdates: null,
        reportsPreferences: {},
        sidebarCollapsed: false,
        scheduleAllLocationSelected: false,
        upgradeBarLastClosed: null,
        scheduleCoachFilter: null
    },
    reducers: {
        setVisitedDesktop: (state) => {
            state.visitedDesktop = true;
        },
        setHasClient: (state) => {
            state.hasClient = true;
        },
        setLang: (state, data) => {
            state.lang = data.payload;
        },
        setDir: (state, data) => {
            state.dir = data.payload;
        },
        setScheduleLocation: (state, data) => {
            state.scheduleLocation = data.payload
        },
        setScheduleViews: (state, data) => {
            state.scheduleViews = data.payload
        },
        setShowScheduleUpgarde: (state, data) => {
            state.showScheduleUpgrade = data.payload
        },
        setViewProductUpdates: (state, data) => {
            state.viewProductUpdates = data.payload
        },
        setReportsPreferences: (state, data) => {
            console.log("data.payload.preference", data.payload.preference)
            state.reportsPreferences = {...state.reportsPreferences, [data.payload.report]: data.payload.preference}
        },
        setScheduleAllLocationSelected: (state, data) => {
            state.scheduleAllLocationSelected = data.payload
        },
        setSidebarCollapsed: (state, data) => {
            state.sidebarCollapsed = data.payload;
        },
        setUpgradeBarLastClosed: (state, data) => {
            state.upgradeBarLastClosed = data.payload;
        },
        setScheduleCoachFilter: (state, data) => {
            state.scheduleCoachFilter = data.payload;
        },
        clearAll: (state) => {
            state.visitedDesktop = !isMobile
            state.scheduleLocation = null
            state.hasClient = false
            state.viewProductUpdates = null
            state.scheduleAllLocationSelected = false
            state.scheduleCoachFilter = null
        },
        clearOnBoxSwitch: (state) => {
            state.scheduleAllLocationSelected = false
            state.scheduleCoachFilter = null
        }
    },

});

export const { setLang, setDir, setVisitedDesktop, setScheduleLocation, setHasClient, clearAll, setScheduleViews, setShowScheduleUpgarde, setViewProductUpdates, setSidebarCollapsed, setScheduleAllLocationSelected, clearOnBoxSwitch, setReportsPreferences, setUpgradeBarLastClosed, setScheduleCoachFilter } = userSettingsSlice.actions;

export default userSettingsSlice.reducer;