import React, { memo, useEffect, useState } from 'react';
import { HStack, VStack } from "native-base";
import AppText from "../../../../../../common/AppText";
import { t } from "../../../../../../../services/i18n";
import { Colors } from "../../../../../../../styles/Colors";
import SimpleInput from "../../../../../../common/SimpleInput";
import { useDispatch, useSelector } from "react-redux";
import { updateScheduleSetting } from "../../../../../../../redux/ScheduleSlice";
import { GlobalStyleAttributes } from "../../../../../../../styles/GlobalStyles";
import { isMobile } from "../../../../../../../services/Helpers";
import CustomSwitch from "../../../../../../common/CustomSwitch";
import { Flex, Radio } from 'antd';
import InfoTooltip from '../../../../../../common/InfoTooltip';

const ClassSwapping = (props) => {
    const { constantPadding, values, setFieldValue, errors, handleChange } = props;
    const shouldSave = useSelector((state) => state.schedule.settingsSaveFlag);
    const [classSwapChoice, setClassSwapChoice] = useState(values.swapMinutes > 0 ? (values.allowWaitlist ? 'swap-waitlist' : 'swap-classes') : 'no-swap');
    const dispatch = useDispatch();

    useEffect(() => {
        if (shouldSave) {
            save()
        }
    }, [shouldSave]);


    const save = () => {
        if (isMobile) {
            dispatch(updateScheduleSetting({
                schedule_swapping_time: values.swapMinutes,
                schedule_swapping_standby_time: values.allowWaitlist ? values.swapMinutes : 0
            }))
        }
    }

    const onSelectSwap = ({ target }) => {
        setClassSwapChoice(target.value);
        switch (target.value) {
            case 'no-swap':
                setFieldValue('allowWaitlist', false);
                setFieldValue('swapMinutes', 0);
                break;
            case 'swap-waitlist':
                setFieldValue('allowWaitlist', true);
                break;
            case 'swap-classes':
                setFieldValue('allowWaitlist', false);
                break;
            default:
                break;
        }
    }

    return (
        <>
            <Flex vertical gap={'0.3rem'} >
                <Radio.Group onChange={onSelectSwap} value={classSwapChoice}>
                    <Flex vertical gap={'0.5rem'} >
                        <Radio value={'no-swap'}>
                            <Flex align={'center'} gap={'0.5rem'}  style={isMobile ? {flexWrap: 'wrap'} : {height: '30px'}}>
                                <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('no-class-swap')}</AppText>
                            </Flex>
                        </Radio>
                        <Radio value={'swap-classes'}>
                            <Flex align={'center'} gap={'0.5rem'} style={{flexWrap: 'wrap'}}>
                                <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('swap-classes')}</AppText>
                                {classSwapChoice == 'swap-classes' && <>
                                    <SimpleInput value={values.swapMinutes}
                                        onChangeCallback={(val) => setFieldValue('swapMinutes', val)}
                                        width={'4rem'}
                                        numericOnly={true}
                                        styleOverride={isMobile ? { height: '38px' }: {}}
                                        status={errors.swapMinutes ? 'error' : null} />
                                    <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('hoursAfterCancellation')}</AppText>
                                </>}
                            </Flex>
                        </Radio>
                        <Radio value={'swap-waitlist'}>
                            <Flex align={'center'} gap={'0.5rem'}  style={{flexWrap: 'wrap'}}>
                                <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('swap-classes-or-waitlist')}</AppText>
                                {classSwapChoice == 'swap-waitlist' && <>
                                    <SimpleInput value={values.swapMinutes}
                                        onChangeCallback={(val) => setFieldValue('swapMinutes', val)}
                                        width={'4rem'}
                                        numericOnly={true}
                                        styleOverride={isMobile ? { height: '38px' }: {}}
                                        status={errors.swapMinutes ? 'error' : null} />
                                    <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('hoursAfterCancellation')}</AppText>
                                </>}
                            </Flex>
                        </Radio>
                    </Flex>
                </Radio.Group>
            </Flex>
            {/* {
                isMobile ?
                    <VStack space={'1rem'} bg={Colors.newDesign.white} p={constantPadding}>
                        <HStack justifyContent={'space-between'} alignItems={'center'}>
                        </HStack>
                        {isChecked &&
                            <>
                                <HStack space={'1rem'} alignItems={'center'}>
                                    <SimpleInput value={values.swapMinutes}
                                                 onChangeCallback={(val) => setFieldValue('swapMinutes', val)}
                                                 width={'4rem'}
                                                 numericOnly={true}
                                                 styleOverride={{height: '38px'}}
                                                 status={errors.swapMinutes ? 'error' : null}
                                    />
                                    <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('hoursAfterCancellation')}</AppText>
                                </HStack>
                                {errors.swapMinutes && <AppText fontSize={GlobalStyleAttributes.fonts.standard16} color={Colors.error}>{errors.swapMinutes}</AppText>}
                            </>
                        }
                    </VStack>
                    :
                    <VStack space={'1rem'}>
                        <HStack justifyContent={'space-between'} alignItems={'center'}>
                            <AppText fontSize={GlobalStyleAttributes.fonts.standard16} boldFont>{t('sessionSwappingLbl')}</AppText>
                            <CustomSwitch isChecked={isChecked} onToggle={setIsChecked}/>
                        </HStack>
                        <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('sessionSwappingTxt')}</AppText>
                        {isChecked &&
                            <>
                                <HStack space={'0.5rem'} alignItems={'center'}>
                                    <SimpleInput numericOnly={true}
                                                 width={'4rem'}
                                                 value={values.swapMinutes}
                                                 onChangeCallback={(val) => setFieldValue('swapMinutes', val)}
                                                 status={errors.swapMinutes ? 'error' : null}
                                    />
                                    <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('hoursAfterCancellation')}</AppText>
                                </HStack>
                                {errors.swapMinutes && <AppText fontSize={GlobalStyleAttributes.fonts.standard16} color={Colors.error}>{errors.swapMinutes}</AppText>}
                            </>
                        }
                    </VStack>
            } */}
        </>
    );
};

export default memo(ClassSwapping);
