import {Roles} from "../../../../shared/Configs/DatabaseConsts";
import {t} from "i18next";

export const getUpdatedDataWithTaskType = ({data, option, record, valueKey, labelKey, colorKey}) => {
    const recordIndex = data.findIndex(tuple => tuple.id === record.id);
    let temp = [...data];
    temp[recordIndex].task_type = {[valueKey]: option.id, [labelKey]: option.label, [colorKey]: option.task_type_color}
    return temp;
}

export const getAttribution = (task) => {
    if(task.is_personal) return 'personalTask'
    else if(task.target_user) {
        switch(task.target_user.role.role) {
            case Roles.AGGREGATOR:
                return 'aggregatorTask'
            case Roles.REGULAR:
                return 'clientTask'
            case Roles.LEAD:
                return 'leadTask'
            default:
                return 'staffTask'
        }
    } else {
        return 'generalTask'
    }
}

export const getTaskType = (type) => {
    switch (type) {
        case "Charge Customer":
            return t('Charge Customer')
        case  "Membership Renew":
            return t('Membership Renew')
        case "Sales Call":
            return t('Sales Call')
        case "Business Management":
            return t("Business Management")
        case "Monitor Absence":
            return t('Monitor Absence')
        case "exisitingClientWasAdded":
            return t('exisitingClientWasAdded')
        case "exisitingPhoneWasAdded":
            return t('exisitingPhoneWasAdded')
        default:
            return type
    }
}