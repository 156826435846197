import React, {useState} from 'react';
import {HStack, VStack} from "native-base";
import AppText from "../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../styles/GlobalStyles";
import {t} from "../../../../../services/i18n";
import InfoTooltip from "../../../../common/InfoTooltip";
import CustomSwitch from "../../../../common/CustomSwitch";
import SimpleInput from "../../../../common/SimpleInput";

const SitePurchaseLimitations = (props) => {
    const { values, setFieldValue, errors, touched } = props;

    return (
        <VStack space={'0.5rem'}>
            <HStack justifyContent={'space-between'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('limit-site-sessions-per-day')}</AppText>
                <CustomSwitch isChecked={values.active} onToggle={(active) => setFieldValue('active', active)}/>
            </HStack>
            {values.active &&
            <HStack space={'1rem'} alignItems={'center'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('max-site-sessions-input-explain')}</AppText>
                <SimpleInput value={values.sessionsPerDay}
                             max={9999}
                             min={0}
                             onChangeCallback={(val) => setFieldValue('sessionsPerDay', val)}
                             width={'4rem'}
                             numericOnly={true}
                             status={errors.sessionsPerDay && touched.sessionsPerDay ? 'error' : null}
                />
            </HStack>}
        </VStack>
    );
};

export default SitePurchaseLimitations;