import React, {useEffect} from 'react';
import {HStack, Switch, VStack} from "native-base";
import SimpleInput from "../../../../../../common/SimpleInput";
import AppText from "../../../../../../common/AppText";
import {t} from "../../../../../../../services/i18n";
import {useDispatch, useSelector} from "react-redux";
import {updateScheduleSetting} from "../../../../../../../redux/ScheduleSlice";
import {Colors} from "../../../../../../../styles/Colors";
import {GlobalStyleAttributes} from "../../../../../../../styles/GlobalStyles";
import {isMobile} from "../../../../../../../services/Helpers";
import EStyleSheet from "react-native-extended-stylesheet";

const BookingNotifications = (props) => {
    const { values, setFieldValue, errors } = props;
    const shouldSave = useSelector((state) => state.schedule.settingsSaveFlag);
    const dispatch = useDispatch();
    const mobile = isMobile
    const fontSize = GlobalStyleAttributes.fonts.standard16

    useEffect(() => {
        if(shouldSave) {
            save()
        }
    }, [shouldSave]);

    const save = () => {
        if(mobile) dispatch(updateScheduleSetting({notification_scheduling: values.notifyHour}))
    }

    return (
        <VStack space={'0.5rem'}>
            <AppText fontSize={fontSize}>{t('bookingReminderTxt')}</AppText>
            <HStack space={'1rem'} alignItems={'center'}>
                <SimpleInput value={values.notifyHour}
                             onChangeCallback={(val) => setFieldValue('notifyHour', val)}
                             width={'4rem'}
                             numericOnly={true}
                             status={errors.notifyHour ? 'error' : null}
                />
                <AppText fontSize={fontSize}>{t('beforeClassStarts')}</AppText>
            </HStack>
            {errors.notifyHour && <AppText fontSize={fontSize} color={Colors.error}>{errors.notifyHour}</AppText>}
        </VStack>
    );
};

export default BookingNotifications;
