import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import {
    adjustAvailabilityToMobiscrollObj, adjustMixAvailabilitiesToMobiscrollObj,
    adjustSchedulesToMobiscrollObj
} from "../services/Helpers";
import {fetchApi} from "../services/HTTP";
import {clearAllFilters, updateSpaceInSelectedLocation} from "../services/ScheduleService";
import produce from "immer";
import {updateBox, updateAllLocations} from "./boxSlice";
import {CREATE_EVENT_MODAL, CREATE_EVENT_TYPE_MODAL, updateModalRes} from "./modalManagerSlice";
import {
    AVAILABILITY_TYPE,
    PAGE_TYPE,
    SCHEDULE_TYPE,
    TOAST_TYPE,
    SPACES_TYPE,
    SINGLE_SESSION_EDIT_TYPE, USER_TYPE,
    MIX_TYPE
} from "../services/ConstHelper";
import {flowOptions} from "../components/Structure/Schedule/Popups/CreateEvent/FlowOptions";
import {hideLoader, showLoader} from "./rootManagerSlice";
import { store } from "./store";
import { setError, toggleStepperSuccess } from "./stepperSlice";
import { showToast } from "../services/stepperService";
import {t} from "../services/i18n";
import {MembershipTypes} from "../Configs/DatabaseConsts";

const scheduleSlice = createSlice({
    name: "schedule",
    initialState: {
        loading: false,
        loadingScheduleData: false,
        error: '',
        data: [],
        from: null,
        to: null,
        viewConfig: null,
        viewType: null,
        filteredData: [],
        categoryTypes: null,
        calendarType: SCHEDULE_TYPE,
        filterConfig: {
            "locations": {
                header: "locations",
                options: [],
                selected: [],
                show: true,
                iconName: 'fa-light fa-id-badge',
                filterFunc: (obj, selected) => selected.some(item => item.id === obj.locations_box_fk),
            },
            "coach": {
                header: "coaches",
                options: [],
                selected: [],
                show: true,
                iconName: 'fa-light fa-id-badge',
                filterFunc: (obj, selected) => selected.some(item => {
                    if(item.id === 'noCoach' && obj.coach_fk === null && obj.second_coach_fk === null) return true
                    else if (item.id !== 'noCoach') {
                        return obj.coach_fk === item.user_fk || obj.second_coach_fk === item.user_fk || obj.coach_ub_id === item.id
                    } else return false
                })
            },
            "space": {
                header: 'spaces',
                options: [],
                selected: [],
                show: false,
                showAsCheckBox: false,
                iconName: 'fa-light fa-arrow-right-rotate',
                filterFunc: (obj, selected) => selected.some(item => item.id === obj.spaces_id),
            },
            "boxCategoriesGroups": {
                header: 'boxCategoriesGroups',
                options: [],
                selected: [],
                show: true,
                filterFunc: (obj, selected) => selected.some(item => obj.box_categories_groups.length > 0 && item.id === obj.box_categories_groups[0].id)

            },
            "category": {
                header: 'classTypes',
                options: [],
                selected: [],
                show: true,
                iconName: 'fa-light fa-person-running',
                filterFunc: (obj, selected) => selected.some(item => item.id === obj.box_category_fk)
            },
            "eventTypes": {
                header: 'eventType',
                options: [],
                selected: [],
                show: true,
                showAsCheckBox: true,
                iconName: 'fa-light fa-calendar-check',
                filterFunc: (obj, selected) => selected.some(item => item.id === (obj.box_categories?.type || null))
            },
            "eventStatus": {
                header: 'eventStatus',
                options: [],
                selected: [],
                show: true,
                showAsCheckBox: true,
                iconName: 'fa-light fa-gauge',
                filterFunc: (obj, selected) => selected.some(item => item.id === obj.status)
            },
            "frequency": {
                header: 'frequency',
                options: [],
                selected: [],
                show: true,
                showAsCheckBox: true,
                iconName: 'fa-light fa-arrow-right-rotate',
                filterFunc: (obj, selected) => selected.some(item => item.id === obj.repeat),
            },
            "bookingStatus": {
                header: 'bookingStatus',
                options: [],
                selected: [],
                show: true,
                showAsCheckBox: true,
                iconName: 'fa-light fa-arrow-right-rotate',
                filterFunc: (obj, selected) =>
                        selected.some(item => {
                            if(item.id === 'emptySessions')
                                return obj.registered === 0 && (obj.box_categories !== null);
                            else if (item.id === 'spotsLeft')
                                return obj.registered < obj.max_users && (obj.box_categories !== null);
                            else if(item.id === 'fullyBookedSessions')
                                return (obj.registered >= obj.max_users) && (obj.box_categories !== null) ;
                            else if(item.id === 'sessionsWithWaitingList')
                                return obj.in_waitlist > 0
                            else if(item.id === 'sessionsWithTrial')
                               return obj.series?.membership_types?.find(item => item.type ===  MembershipTypes.TRIAL_CLASS)
                            else return false;
                        })
                ,
            }
        },
        settingsSaveFlag: null,
        successFlag: false,
        reFetchFlag: false,
        coaches: null,
        eventTypes: {
            data: null,
            loading: false
        },
        confirmationRes: {
            sendNotifications: true
        },
        sendNotifications: true,
        scheduleCustomFields: null,
        availabilityColors: null,
    },
    reducers: {
        updateScheduleView: (state, data) => {
            state.viewConfig = data.payload.viewConfig
            state.viewType = data.payload.viewType
        },
        updateScheduleDates: (state, data) => {
            state.from = data.payload.from
            state.to = data.payload.to
            // state.booked_users = data.payload.booked_users
        },
        clearFilters: (state) => {
            state.filteredData = state.data
            state.filterConfig = clearAllFilters(state.filterConfig)
        },
        initiateFilterConfig: (state, data) => {
            Object.keys(state.filterConfig).forEach(key => {
                if (data.payload[key]) state.filterConfig[key].options = data.payload[key]
            })
            // state.filteredData = state.data;
        },
        initShownFilters: (state, data) => {
            Object.keys(state.filterConfig).forEach(key => state.filterConfig[key].show = !!data.payload[key])
        },
        filterScheduleData: (state, data) => {
            state.filterConfig = data.payload
            state.filteredData = state.data.filter(schedule => {
                return Object.values(data.payload).reduce((acc, filter) => {
                    return filter.selected.length > 0 ? (acc && filter.filterFunc(schedule, filter.selected)) : acc
                }, true)
            })
        },
        toggleSettingsFlag: (state, data) => {
            state.settingsSaveFlag = data.payload
        },
        toggleSuccessFlag: (state, data) => {
            state.successFlag = data.payload
        },
        toggleReFetchFlag: (state, data) => {
            state.reFetchFlag = data.payload
        },
        clearError: (state) => {
            state.error = null
        },
        updateCalendarType: (state, data) => {
            state.calendarType = data.payload
        },
        clearFiltersAndClearOptions: (state) => {
            state.filteredData = state.data
            state.filterConfig = clearAllFilters(state.filterConfig)
            Object.keys(state.filterConfig).forEach(key => state.filterConfig[key].options = [])
        },
        clearData: state => {
            state.data = []
        },
        updateEventData: (state, data) => {
            state.data = data.payload.data
            state.filteredData = data.payload.filteredData
        },
        initiateAll: state => {
            state.filteredData = []
            state.data = []
            state.viewType = null
        },
        clearDate: (state) => {
            state.to = null
            state.from = null
        },
        toggleScheduleLoader: (state, data) => {
            state.loading = data.payload
        },
        toggleSendNotifications: (state, data) => {
            if(!state.confirmationRes) { //this is only relevant for first time load (because i moved sendNotifications inside confirmationRes), we can remove this after some time in prod
                state.confirmationRes = {sendNotifications: data.payload}
            } else {
                state.confirmationRes.sendNotifications = data.payload
            }
        },
        updateConfirmationRes: (state, data) => {
            state.confirmationRes = {...state.confirmationRes, ...data.payload}
            state.sendNotifications = data.payload
        },
        addExtraFilters: (state, data) => {
            // console.log("addExtraFilters data", data)
            state.filterConfig = {...state.filterConfig, ...data.payload};
        }
    },
    extraReducers: builder => {
        builder.addCase(getScheduleData.pending, (state,action) => {
            state.loadingScheduleData = true
        })
        builder.addCase(getScheduleData.fulfilled, (state, action) => {
            state.loadingScheduleData = false
            state.error = ''
            state.data = action.payload.data
            state.filteredData = action.payload.data.filter(schedule => {
                return Object.values(state.filterConfig).reduce((acc, filter) => {
                    return filter.selected.length > 0 ? (acc && filter.filterFunc(schedule, filter.selected)) : acc
                }, true)
            })
            state.availabilityColors = action.payload.colors
        })
        builder.addCase(getScheduleData.rejected, (state, action) => {
            state.loadingScheduleData = false
            state.data = null
            state.error = action.payload?.error
        })

        builder.addCase(getCategoryTypes.pending, (state,action) => {
            state.loading = true
        })
        builder.addCase(getCategoryTypes.fulfilled, (state, action) => {
            state.loading = false
            state.categoryTypes = action.payload
            state.error = ''
        })
        builder.addCase(getCategoryTypes.rejected, (state, action) => {
            state.loading = false
            state.categoryTypes = null
            state.error = action.payload?.error
        })

        builder.addCase(updateScheduleSetting.pending, (state,action) => {
            state.loading = true
        })
        builder.addCase(updateScheduleSetting.fulfilled, (state, action) => {
            state.loading = false
            state.error = ''
            state.settingsSaveFlag = null
            state.successFlag = true
        })
        builder.addCase(updateScheduleSetting.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload?.error
            state.settingsSaveFlag = null
        })

        builder.addCase(updateSchedulesBetweenDates.pending, (state,action) => {
            state.loading = true
        })
        builder.addCase(updateSchedulesBetweenDates.fulfilled, (state, action) => {
            state.loading = false
            state.error = ''
            state.successFlag = true;
            state.reFetchFlag = true
        })
        builder.addCase(updateSchedulesBetweenDates.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload?.error
        })

        builder.addCase(createMultiAvailability.pending, (state,action) => {
            state.loading = true
        })
        builder.addCase(createMultiAvailability.fulfilled, (state, action) => {
            state.loading = false
            state.error = ''
            state.successFlag = true;
            state.reFetchFlag = true
        })
        builder.addCase(createMultiAvailability.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload?.error
        })

        builder.addCase(getCategoriesByTypes.pending, (state) => {
            state.eventTypes = {...state.eventTypes, loading: true}
        })
        builder.addCase(getCategoriesByTypes.fulfilled, (state, action) => {
            state.eventTypes = {data: action.payload, loading: false}
            state.error = ''
        })
        builder.addCase(getCategoriesByTypes.rejected, (state, action) => {
            state.eventTypes = {...state.eventTypes, loading: false}
            state.error = action.payload?.error
        })

        builder.addCase(duplicateEventType.pending, (state) => {
            state.eventTypes = {...state.eventTypes, loading: true}
        })
        builder.addCase(duplicateEventType.fulfilled, (state, action) => {
            const newEventTypes = produce(current(state.eventTypes.data), draft => {
                draft[action.payload.type] = [...draft[action.payload.type], action.payload.newEvent]
            });
            state.eventTypes = {data: newEventTypes, loading: false}
            state.error = ''
        })
        builder.addCase(duplicateEventType.rejected, (state, action) => {
            state.error = action.payload?.error,
            state.eventTypes = {...state.eventTypes, loading: false}

        })

        builder.addCase(updateEventTypeStatus.pending, (state) => {
            state.loading = true
        })
        builder.addCase(updateEventTypeStatus.fulfilled, (state, action) => {
            let newEventTypes = produce(current(state.eventTypes.data), draft => {
                draft[action.payload.type] = draft[action.payload.type].filter(eventType => eventType.id !== action.payload.id)
            });

            if(!action.payload.deleteFromSliceData) {
                const service = state.eventTypes.data[action.payload.type].find(item => item.id === action.payload.id)
                const newService = produce(current(service), draft => {
                    draft.active = action.payload.isActivation ? 1 : 0
                });
                newEventTypes = produce(newEventTypes, draft => {
                    draft[action.payload.type] = [...draft[action.payload.type], newService]
                });
            }

            state.eventTypes = {data: newEventTypes, loading: false}
            state.error = ''
            state.loading = false
        })
        builder.addCase(updateEventTypeStatus.rejected, (state, action) => {
            state.error = action.payload?.error,
            state.loading = false
        })

        builder.addCase(createOrUpdateEventType.pending, (state) => {
            state.loading = true
        })
        builder.addCase(createOrUpdateEventType.fulfilled, (state, action) => {
            let newEventTypes = produce(current(state.eventTypes.data), draft => draft);
            let newEvent = action.payload.newEvent
            if(action.payload.id) { //if update, remove the old one
                newEventTypes = produce(current(state.eventTypes.data), draft => {
                    draft[action.payload.type] = draft[action.payload.type].filter(eventType => eventType.id !== action.payload.id)
                });
            }
            newEventTypes = produce(newEventTypes, draft => {
                draft[action.payload.type] = [...draft[action.payload.type], newEvent]
            });
            state.eventTypes = {data: newEventTypes, loading: false}
            state.error = ''
            state.loading = false
            state.successFlag = true;
        })
        builder.addCase(createOrUpdateEventType.rejected, (state, action) => {
            state.error = action.payload?.error,
            state.loading = false
        })

        builder.addCase(createEvent.pending, (state) => {
            state.loading = true
        })
        builder.addCase(createEvent.fulfilled, (state, action) => {
            state.loading = false
            state.reFetchFlag = true
            state.error = ''
        })
        builder.addCase(createEvent.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload?.error
        })

        builder.addCase(createAvailabilities.pending, (state) => {
            state.loading = true
        })
        builder.addCase(createAvailabilities.fulfilled, (state, action) => {
            state.loading = false
            state.reFetchFlag = (state.calendarType === AVAILABILITY_TYPE || state.calendarType === MIX_TYPE)
            state.error = ''
        })
        builder.addCase(createAvailabilities.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload?.error
        })

        builder.addCase(updateEvent.pending, (state) => {
            state.loading = true
        })
        builder.addCase(updateEvent.fulfilled, (state, action) => {
            state.loading = false
            state.reFetchFlag = true;
            state.error = ''
        })
        builder.addCase(updateEvent.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload?.error
        })

        builder.addCase(rescheduleAppointment.pending, (state) => {
            state.loading = true
        })
        builder.addCase(rescheduleAppointment.fulfilled, (state, action) => {
            state.loading = false
            state.reFetchFlag = true;
            state.error = ''
        })
        builder.addCase(rescheduleAppointment.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload?.error
        })

        builder.addCase(updateAvailability.pending, (state) => {
            state.loading = true
        })
        builder.addCase(updateAvailability.fulfilled, (state, action) => {
            state.loading = false
            state.reFetchFlag = true;
            state.error = ''
        })
        builder.addCase(updateAvailability.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload?.error
        })

        builder.addCase(createSpace.pending, (state) => {
            state.loading = true
        })
        builder.addCase(createSpace.fulfilled, (state, action) => {
            state.loading = false
            state.reFetchFlag = true;
            state.error = ''
        })
        builder.addCase(createSpace.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload?.error
        })
        builder.addCase(updateEventSpaceOrTime.pending, (state) => {
            state.loading = true
        })
        builder.addCase(updateEventSpaceOrTime.fulfilled, (state, action) => {
            state.loading = false
            state.error = ''
        })
        builder.addCase(updateEventSpaceOrTime.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload?.error
        })
        builder.addCase(getCoachesOfBox.fulfilled, (state, action) => {
            state.coaches = action.payload
            state.error = ''
        })
        builder.addCase(getCoachesOfBox.rejected, (state, action) => {
            state.error = action.payload?.error;
        })
        builder.addCase(getScheduleCustomFields.fulfilled, (state, action) => {
            state.scheduleCustomFields = action.payload.scheduleCustomFields;
            state.userCustomFields = action.payload.userCustomFields;
            state.error = ''
        })
        builder.addCase(getScheduleCustomFields.rejected, (state, action) => {
            state.error = action.payload?.error;
        })
    }
});

export const {updateScheduleDates, filterScheduleData, updateScheduleView, initiateAll,
    clearFilters, initiateFilterConfig, updateCalendarType, initShownFilters,updateEventData,
    toggleSettingsFlag, toggleSuccessFlag, toggleReFetchFlag, toggleSuccessCreateEventFlag,
    clearError, clearFiltersAndClearOptions, clearData, clearDate, toggleScheduleLoader, toggleSendNotifications, addExtraFilters, updateConfirmationRes } = scheduleSlice.actions
export default scheduleSlice.reducer;

export const getScheduleData = createAsyncThunk('getScheduleData', async (params, { getState, rejectWithValue, dispatch }) => {
    try {
        let returnVal = [];
        let events = {}
        let colors = null
        const calendarType = getState().schedule.calendarType;
        if(calendarType) {
            switch(calendarType) {
                case SCHEDULE_TYPE:
                case SPACES_TYPE:
                case MIX_TYPE:
                    events = await fetchApi('schedule/getScheduleBetweenDates', 'post', params, false, true);
                    returnVal = adjustSchedulesToMobiscrollObj(events.data);
                    break;
                case AVAILABILITY_TYPE:
                    events = await fetchApi(`availability/get`, 'post', params, false, true);
                    returnVal = adjustAvailabilityToMobiscrollObj(events);
                    break;
            }

            if(calendarType === MIX_TYPE) {
                events = await fetchApi(`availability/get`, 'post', params, false, true);
                colors = adjustMixAvailabilitiesToMobiscrollObj(events, params);
            }
            dispatch(updateScheduleDates(params))
        }
        return {data: returnVal, colors};
    } catch (e) {
        return rejectWithValue(e);
    }
})

export const getCategoryTypes = createAsyncThunk('getCategoryTypes', async (params, {rejectWithValue}) => {
    try {
        return await fetchApi('getCategoryTypes', 'get', null, false, true);
    } catch (e) {
        return rejectWithValue(e);
    }
})


export const updateScheduleSetting = createAsyncThunk('updateScheduleSetting', async (params, {rejectWithValue, dispatch}) => {
    try {
        let res;
        const boxKeys = Object.keys(params).filter(key => key !== 'locationParams')
        if(params.locationParams) {
            res = await fetchApi('updateLocationsSettings', 'post', params.locationParams, false, true);
            dispatch(updateAllLocations(params.locationParams))
        }
        if(boxKeys.length > 0) {
            res = await fetchApi('updateBoxSettings', 'post', params, false, true);
            dispatch(updateBox(params))
        }
        return res;
    } catch (e) {
        return rejectWithValue(e);
    }
})

export const updateSchedulesBetweenDates = createAsyncThunk('updateSchedulesBetweenDates', async (params, {rejectWithValue, dispatch}) => {
    try {
        await fetchApi('schedule/updateSchedulesBetweenDates', 'post', params, false, true);
    } catch (e) {
        return rejectWithValue(e);
    }
})

export const createMultiAvailability = createAsyncThunk('createMultiAvailability', async (params, {rejectWithValue, dispatch}) => {
    try {
        await fetchApi('availability/createMulti', 'post', params, false, true);
    } catch (e) {
        return rejectWithValue(e);
    }
})


export const getCategoriesByTypes = createAsyncThunk('getCategoriesByTypes', async (params, {rejectWithValue, dispatch}) => {
    try {
        return await fetchApi(`getCategoriesByTypes/${params || false}`, 'get', null, false, true);
    } catch (e) {
        return rejectWithValue(e);
    }
})

export const duplicateEventType = createAsyncThunk('duplicateEventType', async (params, {rejectWithValue, dispatch}) => {
    try {
        const res = await fetchApi('schedule/duplicateEventType', 'post', params, false, true);
        return {type: params.type, newEvent: res}
    } catch (e) {
        return rejectWithValue(e);
    }
})

export const updateEventTypeStatus = createAsyncThunk('updateEventTypeStatus', async (params, {rejectWithValue, dispatch}) => {
    try {
        await fetchApi('schedule/updateEventTypeStatus', 'post', params, false, true);
        return params
    } catch (e) {
        return rejectWithValue(e);
    }
})

export const createOrUpdateEventType = createAsyncThunk('createOrUpdateEventType', async (params, {getState, rejectWithValue, dispatch}) => {
    try {
        const res = await fetchApi('schedule/createOrUpdateEventType', 'post', params, false, true);
        if(getState().modalManager.createEventModal.isOpen) {
            dispatch(updateModalRes({modalName: CREATE_EVENT_TYPE_MODAL, res: res}))
        }
        return {type: params.eventTypeName, id: params.id, newEvent: res}
    } catch (e) {
        return rejectWithValue(e);
    }
})


export const createEvent = createAsyncThunk('createEvent', async (params, {rejectWithValue, dispatch}) => {
    try {
        const res = await fetchApi('schedule/createEvent', 'post', params, false, true);
        const { editType, name } = store.getState().stepper
        const forceShowToast = name === flowOptions.BLOCKED_TIME || name === flowOptions.HUGIM_FLOW
        dispatch(toggleStepperSuccess({
            type: editType || showToast(name !== flowOptions.WORKSHOP_FLOW) || forceShowToast ? TOAST_TYPE : PAGE_TYPE,
            message: editType ? 'changed-successfully' : 'added-successfully'
        }))
        dispatch(setError(''));
        if (store.getState().box?.box?.view_external_shcedule) {
            dispatch(updateBox({view_external_shcedule: false}));
        }
        return res;
    } catch (e) {
        dispatch(setError(e.error));
        return rejectWithValue(e);
    }
})

export const createAvailabilities = createAsyncThunk('createAvailabilities', async (params, {rejectWithValue, dispatch}) => {
    try {
        const res = await fetchApi('availability/createAvailabilities', 'post', params, false, true);
        const { editType } = store.getState().stepper
        dispatch(toggleStepperSuccess({
            type: editType || showToast(false) ? TOAST_TYPE : PAGE_TYPE,
            message: editType ? 'changed-successfully' : 'added-successfully'
        }))
        dispatch(setError(''));
        if (store.getState().box?.box?.view_external_shcedule) {
            dispatch(updateBox({view_external_shcedule: false}));
        }
        return res;
    } catch (e) {
        dispatch(setError(e.error));
        return rejectWithValue(e);
    }
})

export const updateEvent = createAsyncThunk('updateEvent', async (params, {rejectWithValue, dispatch}) => {
    try {
        let res;
        if(params?.flowName === flowOptions.WORKSHOP_FLOW) {
            res = await fetchApi('workshop/update', 'post', params, false, true);
        } else if(params?.flowName === flowOptions.HUGIM_FLOW) {
            res = await fetchApi('hugim/update', 'post', params, false, true);
        } else {
            res = await fetchApi('schedule/updateEvent', 'post', params, false, true);
        }
        const { editType } = store.getState().stepper
        dispatch(toggleStepperSuccess({type: editType ? TOAST_TYPE : PAGE_TYPE, message: params?.flowName === flowOptions.HUGIM_FLOW ? `hugim-edit-toast${editType === SINGLE_SESSION_EDIT_TYPE ? '-single' : ''}` : 'changed-successfully'}))
        dispatch(setError(''));
        return res;
    } catch (e) {
        dispatch(setError(e.error));
        return rejectWithValue(e);
    }
})

export const rescheduleAppointment = createAsyncThunk('rescheduleAppointment', async (params, {rejectWithValue, dispatch}) => {
    try {
        const res = await fetchApi('schedule/rescheduleAppointment', 'post', params, false, true);
        const { editType } = store.getState().stepper
        dispatch(toggleStepperSuccess({type: editType ? TOAST_TYPE : PAGE_TYPE, message: 'changed-successfully'}))
        dispatch(setError(''));
        return res;
    } catch (e) {
        dispatch(setError(e.error));
        return rejectWithValue(e);
    }
})


export const updateAvailability = createAsyncThunk('updateAvailability', async (params, {rejectWithValue, dispatch}) => {
    try {
        const res = await fetchApi('availability/updateAvailability', 'post', params, false, true);
        const { editType } = store.getState().stepper
        dispatch(toggleStepperSuccess({type: editType ? TOAST_TYPE : PAGE_TYPE, message: 'changed-successfully'}))
        dispatch(setError(''));
        return res;
    } catch (e) {
        dispatch(setError(e.error));
        return rejectWithValue(e);
    }
})

export const createSpace = createAsyncThunk('createSpace', async (params, {getState, rejectWithValue, dispatch}) => {
    try {
        const previousState = getState().stepper.previousState;
        const isCreateEventOpen = getState().modalManager.createEventModal.isOpen;
        const space = await fetchApi('spaces/create', 'post', params, false, true);
        updateSpaceInSelectedLocation(space, dispatch)
        if(isCreateEventOpen) dispatch(updateModalRes({modalName: CREATE_EVENT_MODAL, res: {...space, flowName: flowOptions.SPACE_FLOW}}))
        dispatch(toggleStepperSuccess({type: previousState ? TOAST_TYPE : PAGE_TYPE}))
        dispatch(setError(''));
        return space
    } catch (e) {
        dispatch(setError(e.error));
        return rejectWithValue(e);
    }
})
export const updateEventSpaceOrTime = createAsyncThunk('updateEventSpaceOrTime', async (params, {rejectWithValue, dispatch}) => {
    try {
        const res = await fetchApi('schedule/updateEventSpaceOrTime', 'post', params, false, true);
        dispatch(toggleStepperSuccess({type: TOAST_TYPE, message: t('edited-successfully-drag&drop')}))
        dispatch(setError(''));
        return res
    } catch (e) {
        dispatch(setError(e.error));
        return rejectWithValue(e);
    }
})
export const getCoachesOfBox = createAsyncThunk('getCoachesOfBox', async (params, {rejectWithValue}) => {
    try {
        return await fetchApi(`getCoachesOfBox`, 'get', params, false, true);
    } catch (e) {
        return rejectWithValue(e);
    }
})
export const getScheduleCustomFields = createAsyncThunk('getScheduleCustomFields', async (params, {rejectWithValue}) => {
    try {
        const res = await fetchApi(`customField/getCustomFieldByFlowNameOrType`, 'POST', {getAllLocationsBox: true}, false, true)
        return {
            scheduleCustomFields: [...res].filter(customField => customField.flow_type === SCHEDULE_TYPE),
            userCustomFields: [...res].filter(customField => customField.flow_type === USER_TYPE)
        }
    } catch (e) {
        return rejectWithValue(e);
    }
})
