import {createSlice} from '@reduxjs/toolkit';
const aiSlice = createSlice({
    name: "ai",
    initialState: {
        threadId: null
    },
    reducers: {
        setThreadId: (state, action) => {
            state.threadId = action.payload;
        }
    },
    extraReducers: builder => {
    }

});

export const { setThreadId } = aiSlice.actions;
export default aiSlice.reducer;
