import React, {useEffect, useMemo, useState} from 'react';
import {HStack, VStack, Text as NBText} from "native-base";
import AppText from "../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../styles/GlobalStyles";
import {t} from "../../../../../services/i18n";
import AdvanceSelect from "../../../../common/AdvanceSelect";
import {Colors} from "../../../../../styles/Colors";
import {
    autoMessageTypesObj,
    EMAIL,
    PUSH,
    SMS,
    WHATSAPP
} from "../../../../../services/ConstHelper";
import {fetchApi} from "../../../../../services/HTTP";
import usePermissions from "../../../../../services/Permissions";
import MessageTextFormInput from "../../../../common/MessageTextFormInput";
import {useSelector} from "react-redux";
import SimpleInput from "../../../../common/SimpleInput";
import CustomSwitch from "../../../../common/CustomSwitch";
import {Divider} from "antd";
import {getSessionAutoMessageTags, isMobile} from "../../../../../services/Helpers";
import DropDownMenu from "../../../../common/DropDownMenu";
import {autoMessageTimeUnits} from "../../../Schedule/Popups/ScheduleSettings/SettingsConfig";

const SessionAutoMessage = (props) => {
    const { values, errors, setFieldValue, touched, validationSchema, allowTimeSelection } = props;
    const box = useSelector(state => state.box?.box)
    const [smsBalance, setSmsBalance] = useState(null);
    const [formOptions, setFormOptions] = useState([]);
    const { has, hasLock } = usePermissions()

    const isIL = useMemo(() => box?.locations_box[0]?.country_code === 'IL', [box]);
    const maxCharCount = ({ [SMS]: isIL ? 133 : 70, [PUSH]: 175, [EMAIL]: 1500, [WHATSAPP]: 5000 })[values.type] || 175;
    const charCount = useMemo(() => values?.message_content?.length ?? 0,[values?.message_content]);
    const tags = useMemo(() => getSessionAutoMessageTags(values.actionName), [values.actionName]);
    const messageTypes = useMemo(() => {
        let options = [autoMessageTypesObj({})[PUSH]]
        if(has(`${EMAIL}Actions`) && !hasLock(`${EMAIL}Actions`)) options.push(autoMessageTypesObj({})[EMAIL])
        if(has(`${SMS}Actions`) && !hasLock(`${SMS}Actions`)) options.push(autoMessageTypesObj({smsBalance})[SMS])
        return options
    }, [smsBalance]);
    const timeUnitOptions = useMemo(() => Object.keys(autoMessageTimeUnits).map((item) => ({id: item, name: t(item.toLowerCase())})),[])

    useEffect(() => {
        console.log('values', values)
    }, [values]);

    useEffect(() => {
        getSmsBalance()
        getDigitalForms()
    }, []);

    const getSmsBalance = async () => {
        const res = await fetchApi('boxes/getSmsBalance', 'POST', null, false, true);
        if(values?.type?.some(item => item.id === SMS)) {
            const newTypes = values.type.map(item => item.id === SMS ? autoMessageTypesObj({smsBalance: res})[SMS] : item);
            setFieldValue('type', newTypes)
        }
        setSmsBalance(res || 0);
    }

    const getDigitalForms = async () => {
        const res = await fetchApi('digitalForm/getAllForms', 'GET', null, false, true);
        if(res && res.length) {
            setFormOptions(res.map(df => ({...df, value: df.id, label: df.name})))
        }
    }

    const getInputLabel = (name, label) => {
        if(validationSchema && validationSchema.fields[name] && validationSchema.fields[name].spec?.presence === 'required') {
            return `${label}*`
        }
        return label
    }

    const onTagClick = (label) => {
        if (`${values.message_content || ''} [${label}]`.length <= maxCharCount) {
            setFieldValue('message_content', `${values.message_content || ''}[${label}]`)
        }
        document?.getElementById('text-area-message')?.focus()
    }


    return (
        <VStack space={'0.8rem'}>
            <HStack justifyContent={'space-between'} alignItems={'center'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.standard16} mediumFont={!isMobile} boldFont={isMobile}>{t('active-reminder')}</AppText>
                <CustomSwitch isChecked={values.active} onToggle={(val) => setFieldValue('active', val)}/>
            </HStack>
            {allowTimeSelection && values.active &&
                <HStack space={'0.5rem'} alignItems={'center'} style={{flexFlow: 'wrap', rowGap: '0.2rem'}}>
                    <AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('period-amount-text-before')}</AppText>
                    <HStack space={'0.5rem'} alignItems={'center'}>
                        <SimpleInput value={values.period_amount}
                                     onChangeCallback={(val) => setFieldValue('period_amount', val)}
                                     numericOnly={true}
                                     width={'4rem'}
                                     status={errors.period_amount ? 'error' : null}
                        />
                        <DropDownMenu title={t(values.period_time_unit?.toLowerCase())}
                                      items={timeUnitOptions}
                                      onPress={(val) => setFieldValue('period_time_unit', val)}
                                      showBorder={true}
                                      styleOverride={{height: '30px', width: '4.5rem'}}
                                      fontSize={GlobalStyleAttributes.fonts.standard16}
                        />
                        <AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('period-amount-text-after')}</AppText>
                    </HStack>
                </HStack>
            }
            <Divider orientation="horizontal" h={'1px'} w={'100%'} bg={Colors.borderColor} style={{marginBlock: '0.3rem'}}/>
            {isMobile && <AppText fontSize={GlobalStyleAttributes.fonts.standard16} boldFont>{t('edit-template')}</AppText>}
            <VStack space={'0.2rem'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{getInputLabel('type', t('send-via'))}</AppText>
                <AdvanceSelect options={messageTypes}
                               isMulti={true}
                               onChange={(val) => setFieldValue('type', val)}
                               value={values?.type}
                               closeMenuOnSelect={false}
                />
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.type}</AppText>
            </VStack>
            {(has(`${EMAIL}Actions`) && !hasLock(`${EMAIL}Actions`)) &&
            <VStack space={'0.2rem'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('subject')}</AppText>
                <SimpleInput value={values.subject}
                             onChangeCallback={(val) => setFieldValue('subject', val)}
                             error={(touched.subject && errors.subject) || null}
                             width={'100%'}
                             maxLength={40}
                             showCount
                             placeholder={t('subject-placeholder')}
                />
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.subject}</AppText>
            </VStack>}
            <VStack space={'0.2rem'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{getInputLabel('digital_form_id', t('form-name'))}</AppText>
                <AdvanceSelect options={formOptions}
                               initValue={values.digital_form_id}
                               onChange={(val) => setFieldValue('digital_form_id', val?.id ?? null)}
                               isClearable={true}/>
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.digital_form_id}</AppText>
            </VStack>
            <MessageTextFormInput {...{tags, onTagClick, setFieldValue, values, maxLength: values.type?.some(item => item.id === SMS) ? 320 : maxCharCount,
                touched, errors, name: 'message_content', placeholder: t('add-message-here'), id: 'text-area-message',
                isSms: values.type?.some(item => item.id === SMS), showDigitalFormText: values.digital_form_id, charCount, maxCharCount}} containerProps={{mb: '1rem'}}/>
        </VStack>
    );
};

export default SessionAutoMessage;