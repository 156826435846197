import moment from 'moment';
import {Stack, Box, HStack, VStack} from 'native-base';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dbDateFormat, isActive, isMobile, sendDataEvent } from '../../../../services/Helpers';
import { t } from '../../../../services/i18n';
import { GlobalStyleAttributes } from '../../../../styles/GlobalStyles';
import SharedTimepicker from '../../../common/SharedTimepicker';
import uuid from "react-native-uuid";
import AdvanceSelect from '../../../common/AdvanceSelect';
import FormInput from '../../../common/FormInput';
import { flowOptions } from '../../Schedule/Popups/CreateEvent/FlowOptions';
import AppText from '../../../common/AppText';
import { Colors } from '../../../../styles/Colors';
import {
    LEAD_SOURCE_SELECT,
    LEAD_STATUS_SELECT,
    STAFF_SELECT,
    USER_FIELDS,
} from '../../../../services/ConstHelper';
import { updateTempSelection } from '../../../../redux/stepperSlice';
import ConfirmModal from '../../../common/ConfirmModal';
import { fetchApi } from '../../../../services/HTTP';
import { closeModal, CREATE_EVENT_MODAL } from '../../../../redux/modalManagerSlice';
import { clearUserFlow } from '../../../../redux/UserFlowSlice';
import { hideLoader, showLoader } from '../../../../redux/rootManagerSlice';
import usePermissions from "../../../../services/Permissions";
import CustomCheckbox from "../../../common/CustomCheckbox";
import {Spin} from "antd";
import CustomButtonV2 from "../../../common/CustomButtonV2";
import { useToast } from 'react-native-toast-notifications';
import CustomFieldSwitcherCreateModal from "../../../common/CustomFieldSwitcherCreateModal";
import uniqBy from 'lodash-es/uniqBy';
import { Roles } from '../../../../Configs/DatabaseConsts';

const MemberDetails = props => {
    const { setFieldValue, handleChange, values, touched, errors, navigate, handleSubmit, validationSchema } = props;
    const box = useSelector((state) => state.box.box);
    const currentUser = useSelector((state) => state.user.user);
    const stepper = useSelector((state) => state.stepper);
    const { user: userFlowUser, error: userFlowError, loading } = useSelector((state) => state.userFlow);
    const isEventManagerFlow = useSelector((state) => state.modalManager?.eventManagementModal?.isOpen);
    const dispatch = useDispatch();
    const toast = useToast();
    const [confirmOptions, setConfirmOptions] = useState(null);
    const [userPhoneExist, setUserPhoneExist] = useState(null);
    const [staffMembers, setStaffMembers] = useState(null);
    const [customFieldLoader, setCustomFieldLoader] = useState(false);
    const [allCustomFields, setAllCustomFields] = useState(false);
    const locationOptions = box?.locations_box?.map(location => ({ value: location.id, label: location.location })) || [];
    const genderOptions = [
        { value: '', label: t('not-specified') },
        { value: 'male', label: t('male') },
        { value: 'female', label: t('female') },
        { value: 'other', label: t('other') }
    ];
    const sourceOptions = useMemo(() => box?.box_sources?.filter(isActive).map(source => ({ value: source.id, label: source.name })), [box])
    const statusOptions = useMemo(() => box?.box_statuses?.filter(isActive).map(status => ({ value: status.id, label: status.name })), [box])
    const { has } = usePermissions()


    useEffect(() => {
        if(allCustomFields) {
            const filtered = allCustomFields?.filter(field => field.locations_box?.some(location => location.id === values.locations_box_fk));
            setFieldValue('customFields', filtered);
        }
    }, [values.locations_box_fk, allCustomFields]);

    useEffect(() => {
        sendDataEvent(`add new ${stepper.name?.toLowerCase()?.replace('_', ' ')} pop up`);
        if(has('addLeadOwner')) fetchStaffMembers();
    }, []);

    useEffect(() => {
        // if(values.updatedUser)
            fetchCustomField();
    },[values.updatedUser])

    useEffect(() => {
        stepper.name === flowOptions.STAFF_MEMBER && dispatch(updateTempSelection({email: values?.email, personal_id: values?.personal_id, phone: values?.phone}));
    }, [values?.email, values?.personal_id, values?.phone]);

    useEffect(() => {
        if (userFlowError && userFlowUser?.id) {
            const isPersonalId = userFlowError?.includes('PERSONAL_ID')
            const isPhone = userFlowError?.includes('PHONE')
            const type = isPersonalId ? 'personal-id' : isPhone ? 'phone' : 'email-address';
            let confirmHeader = t(`existing-${type}`);
            let confirmSubheader = t(`existing-${type}-info`);
            let confirmText = t('yes');
            if (userFlowUser.user_fk) {
                const isDeleted = userFlowUser.deleted_at;
                if (isDeleted) {
                    confirmHeader = t(`recover-deleted-user${isPersonalId || isPhone ? `-${type}` : ''}`)
                    confirmSubheader = t(`recover-deleted-user-info${isPersonalId || isPhone ? `-${type}` : ''}`)
                }
                if (isEventManagerFlow) {
                    confirmHeader = t('existing-email-manage-events')
                    confirmSubheader = t('existing-email-manage-events-info')
                    confirmText = t('back-to-bookings')
                }
                setConfirmOptions({
                    header: confirmHeader,
                    subheader: confirmSubheader,
                    confirmText,
                    cancelText: t('cancel'),
                    onConfirm: () => goToProfile(isDeleted) ,
                    onCancel: resetAfterConfirm,
                });
            }
            if (userFlowUser.token) {
                setConfirmOptions({
                    header: t('users-split-merge-header'),
                    subheader: t('users-split-merge-subheader'),
                    confirmText,
                    cancelText: t('cancel'),
                    onConfirm: confirmSplitAndContinue,
                    onCancel: resetAfterConfirm,
                });
            }
        } else if (userFlowError && userFlowUser) {
            toast.show(t(userFlowUser), { type: 'danger', duration: 2000 });
        }
    }, [userFlowError])

    useEffect(() => {
        dispatch(loading ? showLoader() : hideLoader())
    }, [loading]);

    const goToProfile = async (shouldRestore) => {
        const usersId = userFlowUser.user_fk;
        resetAfterConfirm()
        try {
            if (shouldRestore) {
                dispatch(showLoader())
                await fetchApi('usersBoxes/restoreUser', 'POST', { usersId }, false, true)
            }
            dispatch(closeModal({modalName: CREATE_EVENT_MODAL}))
            navigate('/user-profile/' + usersId)
        } catch (error) {
            dispatch(hideLoader())
        }
    }

    const resetAfterConfirm = () => {
        setConfirmOptions(null)
        dispatch(clearUserFlow())
    }

    const confirmSplitAndContinue = () => {
        setFieldValue('token', userFlowUser.token)
        setConfirmOptions(null)
        dispatch(clearUserFlow())
        handleSubmit()
    }

    const onDateChange = (name, value) => {
        if (value && moment(value).isValid()) {
            const date = moment(value).format(dbDateFormat);
            setFieldValue(name, date);
        }
    }

    const onPhoneChange = (e) => {
        handleChange('phone')(e)
        setUserPhoneExist(null)
    }

    const handlePhoneBlur = async () => {
        if (values.phone) {
            try {
                const res = await fetchApi('usersBoxes/checkIfPhoneExists', 'POST', { phone: values.phone }, false, true)
                if (res?.user_fk) {
                    setUserPhoneExist(res)
                }
            } catch (e) {
                console.log('error in handlePhoneBlur', e)
            }
        }
    }

    const fetchStaffMembers = async () => {
        let staffMembersRes = await fetchApi('getStaffMembersOfBox', 'get', {exclude: [Object.keys(Roles).findIndex(x => x === Roles.COACH) + 1]}, false, true);
        staffMembersRes.forEach(staffMember => {
            staffMember.value = staffMember.id
            staffMember.label = `${staffMember.first_name} ${staffMember.last_name}`
            return staffMember
        })
        setStaffMembers(staffMembersRes);
        if(staffMembersRes.length === 1) {
            setFieldValue('users_boxes_owner_id', staffMembersRes[0].id)
        } else if (has('addLeadOwner')) {
            setFieldValue('users_boxes_owner_id', currentUser.id)
        }
    }

    const fetchCustomField = async () => {
        try {
            setCustomFieldLoader(true);
            const customFieldsRes = await fetchApi('customField/getCustomFieldByFlowNameOrType', 'POST', { flowName: stepper.name, getAllLocationsBox: true }, false, true);
            const customFieldValueRes = values.updatedUser ? await fetchApi('customField/getUserCustomFieldValueManage', 'POST', { locationsBoxFk: values.users_boxes_owner_id, userFk: values.updatedUser?.id }, false, true) : [];

            const temp = [...customFieldValueRes ?? [], ...customFieldsRes ?? []];
            const merged = uniqBy(temp, 'custom_field_id');

            setAllCustomFields(merged)
        } catch(e) {
            console.log('error fetching custom fields: ', e);
        } finally {
            setCustomFieldLoader(false);
        }

    }

    return (
        <>
            <div style={{ display: 'grid', gap: '0.5rem', gridTemplateColumns: !isMobile && 'repeat(2, 1fr)' }}>
                {/* FULL NAME */}
                <FormInput
                    label={t('firstName')}
                    validationSchema={validationSchema}
                    value={values.first_name}
                    name={'first_name'}
                    onChangeCallback={handleChange('first_name')}
                    error={(touched.first_name && errors.first_name) ? errors.first_name : null}
                    width={'100%'}
                    containerProps={{ mb: '1rem' }}
                />
                <FormInput
                    label={t('lastName')}
                    value={values.last_name}
                    validationSchema={validationSchema}
                    name={'last_name'}
                    onChangeCallback={handleChange('last_name')}
                    error={(touched.last_name && errors.last_name) ? errors.last_name : null}
                    width={'100%'}
                    containerProps={{ mb: '1rem' }}
                />
                {/* EMAIL */}
                <FormInput
                    label={t('email')}
                    value={values.email}
                    validationSchema={validationSchema}
                    name={'email'}
                    onChangeCallback={handleChange('email')}
                    error={(touched.email && errors.email) ? errors.email : null}
                    width={'100%'}
                    helper={!isMobile && stepper.name === flowOptions.STAFF_MEMBER && t('email-helper')}
                    containerProps={{mb: isMobile || stepper.name !== flowOptions.STAFF_MEMBER ? '1rem' : '2.7rem'}}
                    type='email'
                />
                {/* PHONE */}
                <FormInput
                    label={t('phone')}
                    value={values.phone}
                    onChangeCallback={onPhoneChange}
                    validationSchema={validationSchema}
                    name={'phone'}
                    width={'100%'}
                    containerProps={{ mb: '1rem' }}
                    type='tel'
                    error={values.phone && values.phone.match(/[^0-9\+\-]/g) ? t('non-numeric-chars') : values.phone && (values.phone.length > 15 || values.phone.length < 5) ? t('phone-length-limit') : ((touched.phone && errors.phone) ? errors.phone : null)}
                    helper={userPhoneExist &&
                                <HStack width={'max-content'} space={'0.3rem'}>
                                    <AppText color={Colors.newDesign.darkGrey} variant={GlobalStyleAttributes.textVariants.heading4}>{t(`user-phone-already-exist-${isMobile ? 'mobile' : 'desktop'}`)}</AppText>
                                    <CustomButtonV2 onClick={() => window.open(`/user-profile/${userPhoneExist.user_fk}`, '_blank')}
                                                    iconProps={{iconColor: Colors.newDesign.mainBlue, iconHoverColor: Colors.newDesign.mainBlueHover}}
                                                    override={({color}) => (<AppText variant={GlobalStyleAttributes.textVariants.heading4} color={color}>{t(`click-to-access-${isMobile ? 'mobile' : 'desktop'}`)}</AppText>)}
                                    />
                                </HStack>
                            }
                    onBlur={handlePhoneBlur}
                />
                {/* BIRTHDAY */}
                <FormInput label={t('birthday')} width={'100%'} containerProps={{ mb: '1rem' }}
                    validationSchema={validationSchema} name={'birthday'}
                    error={(touched.birthday && errors.birthday) ? errors.birthday : null}>
                    <SharedTimepicker type={'date'} isError={(touched.birthday && errors.birthday) ? errors.birthday : null} value={values.birthday ? moment(values.birthday, dbDateFormat) : null} onChange={(birthday) => onDateChange('birthday', birthday)} textStyle={{ fontSize: GlobalStyleAttributes.fonts.standard16 }} />
                </FormInput>
                {/* GENDER */}
                <FormInput label={t('gender')} width={'100%'} containerProps={{ mb: '1rem' }} validationSchema={validationSchema} name={'gender'}>
                        <AdvanceSelect dontSort options={genderOptions} initValue={values.gender} onChange={(option) => setFieldValue('gender', option.value ?? null)} isError={(touched.gender && errors.gender) ? errors.gender : null}/>
                </FormInput>
                {/* PERSONAL ID */}
                {!!validationSchema?.fields?.personal_id &&
                <FormInput
                    label={t('personal-id')}
                    value={values.personal_id}
                    validationSchema={validationSchema} 
                    name={'personal_id'}
                    onChangeCallback={handleChange('personal_id')}
                    containerProps={{ mb: '1rem' }}
                    type='tel'
                    width={'100%'}
                    error={values.personal_id && values.personal_id.match(/[^0-9]/g) ? t('non-numeric-chars') : null}
                />}

                {/*STATUS*/}
                {!!validationSchema?.fields?.status_fk && <FormInput containerProps={{ mb: '1rem' }} width={'100%'} label={t('status')} validationSchema={validationSchema} name={'status_fk'} error={(touched.status_fk && errors.status_fk) ? errors.status_fk : null}>
                    <AdvanceSelect menuShouldScrollIntoView={false} options={statusOptions} type={LEAD_STATUS_SELECT} initValue={values.status_fk} onChange={(option) => setFieldValue('status_fk', option.value ?? null)} isError={touched.status_fk && errors.status_fk}/>
                </FormInput>}

                {/*SOURCE*/}
                {!!validationSchema?.fields?.source_fk && <FormInput containerProps={{ mb: '1rem' }} label={t('source')}  validationSchema={validationSchema} name={'source_fk'} width={'100%'} error={(touched.source_fk && errors.source_fk) ? errors.source_fk : null}>
                    <AdvanceSelect menuShouldScrollIntoView={false} options={sourceOptions} type={LEAD_SOURCE_SELECT} initValue={values.source_fk} onChange={(option) => setFieldValue('source_fk', option.value ?? null)} isError={touched.source_fk && errors.source_fk} />
                </FormInput>}

                {/*LEAD OWNER*/}
                {has('addLeadOwner') && !!validationSchema?.fields?.users_boxes_owner_id && 
                    <FormInput containerProps={{ mb: '1rem' }} label={t('leadOwner')} validationSchema={validationSchema}  name={'users_boxes_owner_id'} width={'100%'} error={(touched.users_boxes_owner_id && errors.users_boxes_owner_id) ? errors.users_boxes_owner_id : null}>
                        <AdvanceSelect options={staffMembers} type={STAFF_SELECT} initValue={values.users_boxes_owner_id} onChange={(option) => setFieldValue('users_boxes_owner_id', option?.value ?? null)} isClearable={true} isError={touched.users_boxes_owner_id && errors.users_boxes_owner_id}/>
                    </FormInput>}
                {/* LOCATION */}
                {
                    locationOptions.length > 1 && !!validationSchema?.fields?.locations_box_fk && 
                    <FormInput containerProps={{ mb: '1rem' }} label={t('location')} required width={'100%'} error={(touched.locations_box_fk && errors.locations_box_fk) ? errors.locations_box_fk : null}>
                        <AdvanceSelect dontSort options={locationOptions} initValue={values.locations_box_fk} onChange={(option) => setFieldValue('locations_box_fk', option.value ?? null)} isError={touched.locations_box_fk && errors.locations_box_fk}/>
                    </FormInput>
                }
                {!!validationSchema?.fields?.city && <FormInput
                    label={t('city')}
                    validationSchema={validationSchema}
                    name={'city'}
                    value={values.city}
                    onChangeCallback={handleChange('city')}
                    width={'100%'}
                    containerProps={{ mb: '1rem' }}
                    error={(touched.city && errors.city) ? errors.city : null}
                />}
                {/* ADDRESS */}
                {!!validationSchema?.fields?.address && <FormInput
                    label={t('address')}
                    validationSchema={validationSchema}
                    name={'address'}
                    value={values.address}
                    onChangeCallback={handleChange('address')}
                    width={'100%'}
                    error={(touched.address && errors.address) ? errors.address : null}
                    containerProps={{ mb: '1rem' }}
                />}
                {/* CUSTOM FIELDS */}
                {
                    values.customFields?.length > 0 && !!validationSchema?.fields?.customFields && !customFieldLoader &&
                    <CustomFieldSwitcherCreateModal
                        customFields={values.customFields}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        containerStyle={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            gap: '0.5rem',
                            boxSizing: 'border-box',
                            gridColumn: '1 / -1'
                        }}
                    />
                }
                { customFieldLoader && !!validationSchema?.fields?.customFields && <Spin style={{ gridColumn: '1 / -1' }} /> }
                {/* COMMENT  */}
                {
                    !!validationSchema?.fields?.comment &&
                        <FormInput label={t('comment')}
                                   width={'100%'}
                                   containerProps={{ mb: '1rem', style: {gridColumn: '1 / -1'} }}
                                   styleOverride={{padding: '0.5rem'}}
                                   rows={4}
                                   inputType={'multiline'}
                                   placeholder={t('lead-comment-placeholder')}
                                   value={values.comment}
                                   onChangeCallback={handleChange('comment')}
                        />
                }
                {/* SUBSCRIPTIONS */}
                {
                    (!!validationSchema?.fields?.allow_mailing_list || !!validationSchema?.fields?.allow_sms) &&
                    <FormInput containerProps={{ mb: '0', style: {gridColumn: '1 / -1'} }} label={t('subscriptions')} width={'100%'} labelProps={{ mb: '0.5rem'}}>
                        {(!!validationSchema?.fields?.allow_mailing_list) && <CustomCheckbox text={t('subscribe-mailing-list')} isChecked={values.allow_mailing_list === 'yes'} onChange={val => setFieldValue('allow_mailing_list', val ? 'yes' : 'no')}/>}
                        {(!!validationSchema?.fields?.allow_sms) && <CustomCheckbox text={t('subscribe-sms')} isChecked={values.allow_sms === 'yes'} onChange={val => setFieldValue('allow_sms', val ? 'yes' : 'no')}/>}
                    </FormInput>
                }
            </div>
            {confirmOptions && <ConfirmModal
                isOpen={confirmOptions}
                header={confirmOptions?.header}
                subheader={confirmOptions?.subheader}
                confirmText={confirmOptions?.confirmText}
                cancelText={confirmOptions?.cancelText}
                onConfirm={confirmOptions?.onConfirm}
                onCancel={confirmOptions?.onCancel}
                onClose={confirmOptions?.onCancel} />}
        </>
    );
};

export default MemberDetails;
