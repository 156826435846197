import React, {useEffect, useMemo, useState} from 'react';
import {Divider, HStack, VStack, Text as NBText} from "native-base";
import {Colors} from "../../../../../../../styles/Colors";
import AppText from "../../../../../../common/AppText";
import {t} from "../../../../../../../services/i18n";
import SimpleInput from "../../../../../../common/SimpleInput";
import {GlobalStyleAttributes, WebGlobalStyles} from "../../../../../../../styles/GlobalStyles";
import {useDispatch, useSelector} from "react-redux";
import DropDownMenu from "../../../../../../common/DropDownMenu";
import {updateScheduleSetting} from "../../../../../../../redux/ScheduleSlice";
import {isMobile} from "../../../../../../../services/Helpers";
import CustomSwitch from "../../../../../../common/CustomSwitch";
import EStyleSheet from "react-native-extended-stylesheet";
import InfoTooltip from "../../../../../../common/InfoTooltip";

const AppointmentPolicy = (props) => {
    const { values, setFieldValue, errors } = props;
    const shouldSave = useSelector((state) => state.schedule.settingsSaveFlag);
    const [isLimitCancelOpen, setIsLimitCancelOpen] = useState(!!values.apptCancelTime);
    const [isPolicyOpen, setIsPolicyOpen] = useState(!!values.apptCancelPolicy);
    const [timeUnit, setTimeUnit] = useState(values.apptCancelTime % 60 === 0 ? 'hour' : 'minute');
    const [timeInput, setTimeInput] = useState(values.apptCancelTime % 60 === 0 ? (values.apptCancelTime / 60) : values.apptCancelTime);
    const [policyText, setPolicyText] = useState('');
    const dispatch = useDispatch();
    const mobile = isMobile
    const fontSize = GlobalStyleAttributes.fonts.standard16
    const timeUnitOptions = useMemo(() => (
        {
            hour: {id: 'hour', name: t('hours')},
            minute:{id: 'minute', name: t('limitMaxUsersSettingsText3')}
        }),[])

    useEffect(() => {
        setPolicyText(values.apptCancelPolicy ?? '')
    }, []);

    useEffect(() => {
        console.log('values:',values)
        console.log('errors:',errors)
    }, [values, errors]);


    useEffect(() => {
        if(shouldSave) {
            save()
        }
    }, [shouldSave]);

    const save = () => {
        if(mobile) {
            const params = {
                locationParams: {
                    availability_time_cancellation: values.apptCancelTime,
                    availability_enable_late_cancellation: values.apptCancelEnabled,
                    cancellation_policy: values.apptCancelPolicy
                }
            }
            dispatch(updateScheduleSetting(params))
        }
    }

    const setTime = (val) => {
        setTimeInput(val)
        setFieldValue('apptCancelTime', timeUnit === 'hour' ? val * 60 : parseInt(val))
    }

    const setUnit = (unit) => {
        setTimeUnit(unit)
        setFieldValue('apptCancelTime', unit === 'minute' ? timeInput : timeInput * 60)
    }

    const setPolicy = (text) => {
        setPolicyText(text)
        setFieldValue('apptCancelPolicy', text)
    }

    const togglePolicy = () => {
        setPolicy(isPolicyOpen ? null : '')
        setIsPolicyOpen(prev => !prev)
    }

    const toggleCancellation = () => {
        // if isLimitCancelOpen is true then now it will change to false
        setTime(isLimitCancelOpen ? 0 : 1)
        if(isLimitCancelOpen) setFieldValue('apptCancelEnabled', 0)
        setIsLimitCancelOpen(prev => !prev)
    }

    return (
        <VStack space={'1.5rem'}>
            <VStack space={'1rem'}>
                <HStack justifyContent={'space-between'} alignItems={'center'}>
                    <HStack alignItems={'center'}>
                        <AppText fontSize={fontSize} boldFont>{t('limitAppointmentCancellation')}</AppText>
                        <InfoTooltip title={t('limitAppointmentCancellation-into-text')}/>
                    </HStack>
                    <CustomSwitch isChecked={isLimitCancelOpen} onToggle={toggleCancellation }/>
                </HStack>
                {
                    isLimitCancelOpen &&
                    <VStack space={'0.5rem'}>
                        <AppText fontSize={fontSize}>{t('allowAppointmentCancellation')} {t('upTo')}</AppText>
                        <HStack space={'0.5rem'} alignItems={'center'}>
                            <SimpleInput value={timeInput}
                                         onChangeCallback={(e) => setTime(e)}
                                         width={'4rem'}
                                         numericOnly={true}
                                         styleOverride={{height: '30px'}}
                                         status={errors.apptCancelTime ? 'error' : null}
                            />
                            <DropDownMenu title={timeUnitOptions[timeUnit].name}
                                          items={Object.values(timeUnitOptions)}
                                          onPress={(val) => setUnit(val)}
                                          fontSize={fontSize}
                                          width={'7rem'}
                                          showBorder={true}
                                          styleOverride={{height: '28px'}}
                            />
                            <AppText fontSize={fontSize}>{t('beforeAppointment')}</AppText>
                        </HStack>
                        {errors.apptCancelTime && <AppText fontSize={fontSize} color={Colors.error}>{errors.apptCancelTime}</AppText>}
                    </VStack>
                }
            </VStack>
            {isLimitCancelOpen &&
            <HStack justifyContent={'space-between'} alignItems={'center'}>
                <HStack alignItems={'center'}>
                    <AppText fontSize={fontSize} boldFont>{t('allowLateCancellation')}</AppText>
                    <InfoTooltip title={t('appointment-cancellation-into-text')}/>
                </HStack>
                <CustomSwitch isChecked={values.apptCancelEnabled > 0} onToggle={(val) => setFieldValue('apptCancelEnabled', val ? 1 : 0)}/>
            </HStack>}
            {!mobile && <Divider w={'100%'} color={Colors.borderColor}/>}
            <VStack space={'1rem'}>
                <HStack justifyContent={'space-between'} alignItems={'center'}>
                    <AppText fontSize={fontSize} boldFont>{t('showCancellationPolicy')}</AppText>
                    <CustomSwitch isChecked={isPolicyOpen} onToggle={togglePolicy}/>
                </HStack>
                {
                    isPolicyOpen &&
                    <SimpleInput inputType={'multiline'}
                                 value={policyText}
                                 onChangeCallback={setPolicy}
                                 rows={6}
                    />
                }
            </VStack>
        </VStack>
    );
};

export default AppointmentPolicy;
