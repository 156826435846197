import React, {useEffect, useMemo} from 'react';
import {HStack, VStack, Center} from "native-base";
import SharedTimepicker from "../../../../../common/SharedTimepicker";
import moment from "moment";
import {
    dbDateFormat,
    dbTimeFormat,
    isBoxIL,
    isMobile,
    sendDataEvent,
    sortDays
} from "../../../../../../services/Helpers";
import AppText from "../../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../../styles/GlobalStyles";
import {t} from "../../../../../../services/i18n";
import {Colors} from "../../../../../../styles/Colors";
import {useDispatch, useSelector} from "react-redux";
import {updateSummary} from "../../../../../../redux/stepperSlice";
import {SERIES_FUTURE_SESSIONS_EDIT_TYPE} from "../../../../../../services/ConstHelper";
import {optionalSteps} from "../StepOptions";
import {Radio} from "antd";
import CustomButtonV2 from "../../../../../common/CustomButtonV2";
import {checkRequired} from "../../../../../../services/stepperService";

const RepeatConfiguration = (props) => {
    const { values, setFieldValue, errors, allowSeries, hideSummary, validationObject } = props;
    const flowName = useSelector((state) => state.stepper.name);
    const editType = useSelector((state) => state.stepper.editType);
    const box = useSelector((state) => state.box.box);
    const selectionsTree = useSelector((state) => state.stepper.selectionsTree);
    const services = useSelector(state => state.schedule.eventTypes.data)
    const lang = useSelector((state) => state.userSettings.lang);
    const mobile = isMobile
    const dispatch = useDispatch()
    const days = useMemo(() => ({
        0: {name: t(mobile ? 'sundayShort' : 'sundayShortened'), id: 0},
        1: {name: t(mobile ? 'mondayShort' : 'mondayShortened'), id: 1},
        2: {name: t(mobile ? 'tuesdayShort' : 'tuesdayShortened'), id: 2},
        3: {name: t(mobile ? 'wednesdayShort' : 'wednesdayShortened'), id: 3},
        4: {name: t(mobile ? 'thursdayShort' : 'thursdayShortened'), id: 4},
        5: {name: t(mobile ? 'fridayShort' : 'fridayShortened'), id: 5},
        6: {name: t(mobile ? 'saturdayShort' : 'saturdayShortened'), id: 6}
    }), [])
    const daysWithOrder = useMemo(() => {
        if(days) {
            if (isBoxIL()) return Object.values(days);
            else return [days['1'], days['2'], days['3'], days['4'], days['5'], days['6'], days['0']]
        }
    }, [days]);
    const timeFormat = useMemo(() => box ? box.locations_box[0].time_format : null,[box])

    useEffect(() => {
        if(selectionsTree[optionalSteps.EVENT_TYPE]) {
            sendDataEvent(`add ${selectionsTree[optionalSteps.EVENT_TYPE]} pop up - Schedule`);
        }
    }, [selectionsTree[optionalSteps.EVENT_TYPE]]);

    useEffect(() => {
        if(values.date && values.repeatConfig && !values.repeatConfig.until) {
            setFieldValue('repeatConfig.until', moment(values.date, dbDateFormat).add(1, 'd').format(dbDateFormat))
        }
    }, [values.date]);

    useEffect(() => {
        if(!allowSeries && values.repeatConfig && !values.repeatConfig.until) { // in this case there is only "until date" option, so we want to hide the radio button
            onRepeatTypeSelected( 'until')
        }
    }, [allowSeries]);

    const toggleDays = (dayIndex) => {
        const existIndex = values.repeatConfig.days.indexOf(dayIndex)
        let newDays = [...values.repeatConfig.days]
        if(existIndex !== -1) {
            newDays.splice(existIndex, 1)
            setFieldValue('repeatConfig.days', newDays)
        } else {
            setFieldValue('repeatConfig.days', [...newDays, dayIndex])
        }
    }

    const onTimeChange = (name, value) => {
        if(value.m) {
            const time = value.m.format(dbTimeFormat)
            setFieldValue(name, time)
        }
        if(name === 'time') {
            if(services && selectionsTree && selectionsTree[optionalSteps.EVENT_TYPE] && services[selectionsTree[optionalSteps.EVENT_TYPE]]) {
                const event = services[selectionsTree[optionalSteps.EVENT_TYPE]].find(t => t.id === values.box_category_fk)
                setFieldValue('end_time', moment(value.m, dbTimeFormat).add(event.length, 'minutes').format(dbTimeFormat))
            }
        }
    }

    const onDateChange = (name, value) => {
        const date = moment(value).format(dbDateFormat)
        setFieldValue(name, date)
    }

    const setValue = (name, value, shouldParseInt = false) => {
        setFieldValue(name, shouldParseInt ? (isNaN(parseInt(value)) ? '' : parseInt(value)) : value)
    }

    const onRepeatTypeSelected = (type) => {
        if(type === 'until') {
            setFieldValue('repeatConfig.until', moment(values.date, dbDateFormat).add(1, 'month').format(dbDateFormat))
        }
        setFieldValue('repeatConfig.type', type)
    }

    const generateSummary = () => {
        const date = moment(values.date, dbDateFormat).format(lang?.language == 'Hebrew' ? 'DD MMM, YYYY' : 'MMM DD, YYYY')
        const time = moment(values.time, dbTimeFormat).format(timeFormat);
        const endTime = moment(values.end_time, dbTimeFormat).format(timeFormat);
        let summary;

        if(values.repeatConfig?.repeat) {
            let dayLabels = values.repeatConfig.days
                .sort(sortDays)
                .reduce((acc, day) => [...acc, t(`day-${day+1}`)],[])
            let params = {
                startDate: date,
                daysAndTimes: `${dayLabels.join(` ${t('and')} `)}, ${t('fromTime')} ${time} ${t('toTime')} ${endTime}`
            }
            switch (values.repeatConfig.type) {
                case 'forever':
                    break;
                case 'until':
                    params.endDate = moment(values.repeatConfig.until, dbDateFormat).format('MMM DD, YYYY')
                    break;
                case 'times':
                    params.numOfSessions = values.repeatConfig.times
                    break;
                default:
                    break;
            }
            summary = t(`${flowName}-summary-${values.repeatConfig.type}`, params)
        } else {
            const date = moment(values.date, dbDateFormat).format('dddd, MMM DD, YYYY')
            const daysAndTimes = `${date}, ${t('fromTime')} ${time} ${t('toTime')} ${endTime}`
            summary = t(`${flowName}-summary-one-time`, {daysAndTimes: daysAndTimes})
        }
        dispatch(updateSummary(summary))
        return `${t('summary-label')} ${summary}`
    }

    return (
        <VStack space={'1.5rem'}>
            <VStack space={'0.2rem'} width={mobile ? '100%' : '50%'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('date')}{checkRequired('date', validationObject)}</AppText>
                <SharedTimepicker isDisabled={editType === SERIES_FUTURE_SESSIONS_EDIT_TYPE} type={'date'} value={moment(values.date, dbDateFormat)} onChange={(date) => onDateChange('date', date)} textStyle={{fontSize: GlobalStyleAttributes.fonts.standard16}}/>
            </VStack>
            <VStack space={'0.2rem'} width={mobile ? '100%' : '50%'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('time')}{checkRequired('time', validationObject)}</AppText>
                <HStack space={'0.5rem'} alignItems={'center'}>
                    <SharedTimepicker type={'time'} value={moment(values.time, dbTimeFormat)} onChange={(time) => onTimeChange('time', time)} wrapperStyle={{flex: 1}} textStyle={{fontSize: GlobalStyleAttributes.fonts.standard16}}/>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText}>-</AppText>
                    <SharedTimepicker type={'time'} value={moment(values.end_time, dbTimeFormat)} onChange={(time) => onTimeChange('end_time', time)} wrapperStyle={{flex: 1}} textStyle={{fontSize: GlobalStyleAttributes.fonts.standard16}}/>
                </HStack>
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.end_time}</AppText>
            </VStack>
            {!editType &&
            <VStack space={'0.2rem'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('repeat')}</AppText>
                <Radio.Group name={'event-type-radio'} onChange={e => setFieldValue('repeatConfig.repeat', e.target.value === 'weekly')} value={values.repeatConfig?.repeat ? 'weekly' : 'single'}>
                    <HStack space={'1.5rem'}>
                        <Radio value="weekly" size="sm"><AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('weekly')}</AppText></Radio>
                        <Radio value="single" size="sm"><AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('single-session')}</AppText></Radio>
                    </HStack>
                </Radio.Group>
            </VStack>
            }
            {values.repeatConfig?.repeat &&
            <VStack space={'1.5rem'}>
                <VStack space={'0.2rem'}>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('schedule-on')}</AppText>
                    <HStack space={'0.5rem'} justifyContent={mobile ? 'space-evenly' : 'unset'}>
                        {daysWithOrder && daysWithOrder.map(dayObj => (
                            <CustomButtonV2 onClick={() => toggleDays(dayObj.id)} key={`repeat-days-small-${dayObj.id}`}
                                            iconProps={{iconColor: Colors.borderColor, iconHoverColor: Colors.newDesign.mainBlue}}
                                            override={({ color }) => (
                                                <Center py={'0.4rem'}
                                                    width={mobile ? '2.5rem' : '3.5rem'}
                                                    borderColor={values.repeatConfig.days.includes(dayObj.id) ? Colors.newDesign.mainBlue : color}
                                                    borderWidth={1}
                                                    borderRadius={4}>
                                                    <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{dayObj.name}</AppText>
                                                </Center>)}
                            />
                        ))}
                    </HStack>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.repeatConfig?.days}</AppText>
                </VStack>
                <VStack>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('ends')}</AppText>
                    <Radio.Group name={'repeat-type-radio'} onChange={(e) => onRepeatTypeSelected(e.target.value)} value={values.repeatConfig.type}>
                        <VStack>
                            {allowSeries &&
                            <HStack h={'40px'} alignItems={'center'}>
                                <Radio value="forever" size="sm"><AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('never')}</AppText></Radio>
                            </HStack>}
                            <HStack space={'1rem'} alignItems={'center'} h={'40px'}>
                                {allowSeries ?
                                    <Radio value="until" size="sm"><AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('set-date')}</AppText></Radio>
                                    :
                                    <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('set-date')}</AppText>
                                }
                                {values.repeatConfig.type === 'until' &&
                                <SharedTimepicker type={'date'}
                                                  value={moment(values.repeatConfig.until, dbDateFormat)}
                                                  onChange={(date) => onDateChange('repeatConfig.until', date)}
                                                  textStyle={{fontSize: GlobalStyleAttributes.fonts.standard16}}
                                                  min={values.date ?? moment().format(dbDateFormat)}
                                                  max={values.date ? moment(values.date, dbDateFormat).add(2,'y').format(dbDateFormat) : moment().add(2, 'y').format(dbDateFormat)}
                                />}
                            </HStack>
                        </VStack>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors?.repeatConfig?.times}</AppText>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors?.repeatConfig?.until}</AppText>
                    </Radio.Group>
                </VStack>
            </VStack>
            }
            {!hideSummary && <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.newDesign.darkGrey}>{generateSummary()}</AppText>}
        </VStack>
    );
};

export default RepeatConfiguration;
