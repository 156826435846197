import { Flex } from "antd"
import FormInput from "./FormInput"
import MessageTextArea from "./MessageTextArea"
import AppText from "./AppText"
import { Colors } from "../../styles/Colors"
import { GlobalStyleAttributes } from "../../styles/GlobalStyles"
import { t } from "../../services/i18n"
import {checkRequired} from "../../services/stepperService";

const MessageTextFormInput = ({tags, onTagClick, setFieldValue, values, maxLength, touched, errors, name, placeholder, id, isSms, showDigitalFormText, charCount, maxCharCount, smsUnitCount, validationObject, containerProps = {}}) => {
    return (
    <FormInput label={t('messageContent')+checkRequired(name, validationObject)} width={'100%'} containerProps={{ mb: '3rem', ...containerProps }}
        helper={<Flex vertical>
            {isSms &&<AppText color={Colors.newDesign.darkGrey} fontSize={GlobalStyleAttributes.fonts.infoText}>{t('sms-message-content-helper')}</AppText>}
            {showDigitalFormText &&<AppText color={Colors.newDesign.darkGrey} fontSize={GlobalStyleAttributes.fonts.infoText}>{t('digital-form-message-content-helper')}</AppText>}
        </Flex>}
        error={(touched[name] && errors[name]) ? errors[name] : null}
        topEndElement={<AppText variant={GlobalStyleAttributes.textVariants.heading4} color={Colors.newDesign.darkGrey}>{`${charCount % maxCharCount}/${maxCharCount}${isSms ? ` | ${parseInt(charCount / (smsUnitCount || maxCharCount)) + 1} ${t('messages')}` : ''}`}</AppText>}>
            <MessageTextArea {...{tags, onTagClick, setFieldValue, values, maxLength, touched, errors, name, placeholder, id}}/>
    </FormInput>)
}

export default MessageTextFormInput;

