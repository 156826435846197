import React, {useEffect, useState} from 'react';
import {fetchApi} from "../services/HTTP";
import {setBox} from "../redux/boxSlice";
import {generateSignatureCloudinary} from "../services/Cloudinary";
import {useDispatch, useSelector} from "react-redux";
import {sendDataEvent} from '../services/Helpers';

export const mediaUpdateFunctions = {
    updateBoxLogo: 'updateBoxLogo',
    updateSpaceImage: 'updateSpaceImage'
}
const useMediaUpload = () => {
    const { box } = useSelector((state) => state.box);
    const { data } = useSelector((state) => state.auth);
    const [res, setRes] = useState(null);
    const dispatch = useDispatch();

    const updateBoxLogo = async (data) => {
        const response = await fetchApi('logo/update', 'POST', data , false, true);
        if(response) {
            dispatch(setBox({...box, cloudinary_image: data.secure_url}));
            sendDataEvent(`Add new logo`);
            return true;
        }
    }

    const updateSpaceImage = async (data) => {
        setRes(data)
        sendDataEvent(`Add new space image`);
    }


    const deleteLogo = async (data) => {
        const response = fetchApi('logo', 'DELETE',null , false, true);
        if(response) {
            dispatch(setBox({...box, cloudinary_image: null}));
            return false;
        }
    }

    const mediaUploadWidgetOpen = (functionName, callback) => {
        switch (functionName) {
            case mediaUpdateFunctions.updateBoxLogo:
                generateSignatureCloudinary(null, data.token, functionName, updateBoxLogo);
                break;
            case mediaUpdateFunctions.updateSpaceImage:
                generateSignatureCloudinary(null, data.token, functionName, updateSpaceImage);
                break;
            default:
                break;
        }
    }

    return [deleteLogo, mediaUploadWidgetOpen, res]
}

export default useMediaUpload;
