import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {updateScheduleSetting} from "../../../../../../../redux/ScheduleSlice";
import {HStack, VStack} from "native-base";
import {Colors} from "../../../../../../../styles/Colors";
import AppText from "../../../../../../common/AppText";
import {t} from "../../../../../../../services/i18n";
import SimpleInput from "../../../../../../common/SimpleInput";
import {GlobalStyleAttributes} from "../../../../../../../styles/GlobalStyles";
import {isMobile} from "../../../../../../../services/Helpers";
import CustomSwitch from "../../../../../../common/CustomSwitch";

const CancellationTime = (props) => {
    const { values, setFieldValue, errors, handleChange } = props;
    const shouldSave = useSelector((state) => state.schedule.settingsSaveFlag);
    const [isChecked, setIsChecked] = useState(values.cancellationTime > 0);
    const dispatch = useDispatch();
    const mobile = isMobile
    const fontSize = GlobalStyleAttributes.fonts.standard16

    useEffect(() => {
        if(!mobile) {
            setFieldValue('cancellationTime', isChecked ? values.cancellationTime ?? 1 : 0)
        }
    }, [isChecked]);

    useEffect(() => {
        if(shouldSave) {
            save()
        }
    }, [shouldSave]);

    const save = () => {
        if(mobile) {
            const val = isChecked ? values.cancellationTime : 0
            setFieldValue('cancellationTime', val)
            dispatch(updateScheduleSetting({standby_cancellation_time: val}))
        }
    }

    return (
        <VStack space={'0.5rem'}>
            {isMobile ?
                <HStack justifyContent={'space-between'} alignItems={'center'}>
                    <AppText fontSize={fontSize}>{t(mobile ? 'cancelationTimeLbl' : 'cancelationTimeTxt')}</AppText>
                    <CustomSwitch isChecked={isChecked} onToggle={setIsChecked}/>
                </HStack>
                :
                <>
                    <HStack justifyContent={'space-between'} alignItems={'center'}>
                        <AppText fontSize={GlobalStyleAttributes.fonts.standard16} boldFont>{t('cancelationTimeLbl')}</AppText>
                        <CustomSwitch isChecked={isChecked} onToggle={setIsChecked}/>
                    </HStack>
                    <AppText fontSize={fontSize}>{t(mobile ? 'cancelationTimeLbl' : 'cancelationTimeTxt')}</AppText>
                </>
            }

            {isChecked &&
                <HStack space={'1rem'} alignItems={'center'}>
                    <SimpleInput value={values.cancellationTime}
                                 onChangeCallback={val => setFieldValue('cancellationTime', val)}
                                 width={'4rem'}
                                 numericOnly={true}
                                 status={errors.cancellationTime ? 'error' : null}
                    />
                    <AppText fontSize={fontSize}>{t('afterJoining')}</AppText>
                </HStack>
            }
            {errors.cancellationTime && <AppText fontSize={fontSize} color={Colors.error}>{errors.cancellationTime}</AppText>}
        </VStack>
    );
};

export default CancellationTime;
