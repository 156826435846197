import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { Space } from 'antd';
import { t } from 'i18next';
import moment from 'moment';
import React, { useMemo } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { dbDateFormat } from '../../../services/Helpers';
import { Colors } from '../../../styles/Colors';
import { GlobalConsts } from '../../../styles/GlobalConsts';
import AppText from '../../common/AppText';

const PenaltySummary = props => {
    const { values } = props;
    const fields = useMemo(() => [
        {value: values.name, label: t('penalty-name')},
        {value: moment(values.start_date, dbDateFormat).format('MMMM DD, YYYY'), label: t('starts-from')},
        {value: `${t('punish-the-user-after')} ${values.trigger_occurrence_count} ${t('absences-in')} ${values.trigger_period_count} ${t('last-days')}`, label: t('trigger')},
        {value: [...(values.late_cancellation ? [t('late-cancellation')] : []), ...(values.check_in ? [t('check-in')] : [])].join(', '), label: t('absences')},
        {value: `${t(values.type?.replaceAll('_', '-') + '-punishment')} ${values[values.type]} ${t('days')}`, label: t('absence-type')},
        {value: `${t('block-the-client')} ${values.time_after_class} ${t('hours-after-the-session')}`, label: t('time-after-class')},
        {value: values.membership_types?.filter(mt => mt.type == 'plan').map(mt => mt.name).join(', ') || t('unset'), label: t('promoCodeSelectLabel-plan')},
        {value: values.membership_types?.filter(mt => mt.type == 'session').map(mt => mt.name).join(', ') || t('unset'), label: t('promoCodeSelectLabel-session')},
        {value: t('clients-get-message-types', {types: [values.send_push && t('push'), values.send_sms && t('sms'),values.send_email && t('email'), values.send_whatsapp && t('whatsapp')].filter(t => t).join(', ')}), label: t('absences-message')},
        {value: values.message_content, label: t('messageContent')},
        {value: values.allow_free_space && values.free_space > 0 ? `${t('the-user-can-book-on-block')} ${values.free_space} ${t('hours-before-session')}` : t('unset'), label: t('free-space')},
    ], [])

    return (
        <>
            {fields.map((field, index) => <div key={`summary-field-${index}`} style={styles.rowSpace}>
                <div style={styles.check}>
                    <FontAwesomeIcon icon={light('check')} color={Colors.newDesign.mainText} size={GlobalConsts.iconSize.XS}/>
                </div>
                <span>
                <AppText boldFont>{field.label}: </AppText>
                <AppText>{field.value}</AppText>
                </span>
            </div>)}
        </>
    );
};

export default PenaltySummary;

const styles = EStyleSheet.create({
    rowSpace: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        marginBottom: '8px'
    },
    check: {
        display: 'flex',
        alignSelf: 'baseline',
        top: '4px',
        position: 'relative'
    }
});
