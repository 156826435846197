import * as yup from "yup";
import AppText from "../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../styles/GlobalStyles";
import React from "react";
import SessionAutoMessage from "./FlowComponents/SessionAutoMessage";
import {t} from "../../../../services/i18n";
import {autoMessageTypesObj, EMAIL, PUSH, SMS} from "../../../../services/ConstHelper";
import {getSessionAutoMessageTags, isMobile} from "../../../../services/Helpers";
import {autoMessageTimeUnits, autoMessageTypes} from "../../Schedule/Popups/ScheduleSettings/SettingsConfig";
import {fetchApi} from "../../../../services/HTTP";
import {getAutoActions} from "../../../../redux/boxSlice";
import {toggleScheduleLoader} from "../../../../redux/ScheduleSlice";

export const modalFlowOptions = {
    SESSION_AUTO_MESSAGE: 'SESSION_AUTO_MESSAGE',
}

export const getValidationSchema = (flowName) => {
    switch (flowName) {
        case modalFlowOptions.SESSION_AUTO_MESSAGE:
            return yup.object().shape({
                actionName: yup.string().required(t('one-type-minimum')).oneOf(Object.values(autoMessageTypes)),
                active: yup.boolean().required(),
                type: yup.array(yup.object().shape({
                    id: yup.string().required(t('one-type-minimum')).oneOf([PUSH, SMS, EMAIL])
                })).min(1, t('one-type-minimum')).required(t('one-type-minimum')),
                subject: yup.string().when('type', {
                    is: value => value.some(item => item.id === EMAIL),
                    then: yup.string().required(t('thisFieldIsMandatory')),
                    otherwise: yup.string()
                }),
                message_content: yup.string().required(t('thisFieldIsMandatory')),
                digital_form_id: yup.number().nullable(),
                period_amount: yup.number().when('actionName', {
                    is: autoMessageTypes.REMINDER_SESSION,
                    then: yup.number().positive().integer().min(1).required(t('thisFieldIsMandatory')),
                    otherwise: yup.number().nullable()
                }),
                period_time_unit: yup.string().when('actionName', {
                    is: autoMessageTypes.REMINDER_SESSION,
                    then: yup.string().required(t('thisFieldIsMandatory')).oneOf(Object.values(autoMessageTimeUnits)),
                    otherwise: yup.string().nullable()
                })
            })
        default:
            return yup.object().shape({})
    }
}

export const getInitialValues = ({flowName, target, initProps}) => {
    switch (flowName) {
        case modalFlowOptions.SESSION_AUTO_MESSAGE:
            return getSessionAutoMessageInitialValues({target, initProps})
        default:
            return {}
    }
}

const getSessionAutoMessageInitialValues = ({target, initProps}) => {
    const messageTypeAutoActions = target ?? []
    const types = messageTypeAutoActions.filter(item => item.active).map(item => autoMessageTypesObj({})[item.auto_action_type.toLowerCase()])
    const messageTemplate = messageTypeAutoActions.length ? messageTypeAutoActions[0].message_template : {}
    let message_content = messageTemplate.text ?? null
    if(message_content) {
        const tags = getSessionAutoMessageTags(initProps?.messageType)
        tags.forEach(tag => {
            message_content = message_content.replaceAll(`{{#${tag.id}#}}`, `[${tag.label}]`)
        });
    }
    const isReminder = initProps?.messageType === autoMessageTypes.REMINDER_SESSION
    return {
        actionName: initProps?.messageType,
        type: types ?? [],
        subject: messageTemplate.subject ?? '',
        message_content: message_content ?? t(`default-message-content-${initProps?.messageType}`),
        digital_form_id: messageTemplate.digital_form_id ?? null,
        active: types.length > 0,
        period_amount: messageTemplate.period_amount ?? (isReminder ? 1 : null),
        period_time_unit: messageTemplate.period_time_unit ?? (isReminder ? autoMessageTimeUnits.DAY : null),
    }
}

export const getHeader = ({flowName, initProps}) => {
    switch (flowName) {
        case modalFlowOptions.SESSION_AUTO_MESSAGE:
            return <AppText fontSize={GlobalStyleAttributes.fonts.mainHeader} boldFont>{t(`edit-template-${initProps?.messageType}${isMobile ? '-short' : ''}`)}</AppText>
        default:
            return null
    }
}

export const renderBody = ({flowName, initProps, ...rest}) => {
    switch (flowName) {
        case modalFlowOptions.SESSION_AUTO_MESSAGE:
            const isReminder = initProps?.messageType === autoMessageTypes.REMINDER_SESSION
            return <SessionAutoMessage allowTimeSelection={isReminder} {...rest}/>
        default:
            return null
    }
}

export const onSaveCallback = ({flowName}) => {
    switch (flowName) {
        case modalFlowOptions.SESSION_AUTO_MESSAGE:
            return (props) => updateSessionAutoMessage(props)
        default:
            return null
    }
}

const updateSessionAutoMessage = async ({values, dispatch, toast}) => {
    try {
        dispatch(toggleScheduleLoader(true));
        const tags = getSessionAutoMessageTags(values.actionName)
        let message_content = values.message_content
        tags.forEach(tag => {
            message_content = message_content.replaceAll(`[${tag.label}]`, `{{#${tag.id}#}}`)
        });
        const params = {...values, message_content, type: values.type.map(item => item.id)}
        await fetchApi('autoActionsSet/updateSessionAutoMessage', 'post', params, false, true)
        dispatch(getAutoActions())
        dispatch(toggleScheduleLoader(false));
        toast.show(t('savedSuccessfully'),{type: "success", duration: 2000})
    } catch(error) {
        dispatch(toggleScheduleLoader(false));
        toast.show(t('error-updating-session-auto-message'),{type: "danger", duration: 4000})
        console.log('error in updateSessionAutoMessage', error)
    }
}