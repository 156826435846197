import useMediaUpload from "../../Hooks/useMediaUpload";
import CustomButtonV2 from "./CustomButtonV2";
import {t} from "../../services/i18n";
import {Colors} from "../../styles/Colors";
import {Upload} from "antd";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {closeModal, openModal, STEPPER_CONFIRM_MODAL} from "../../redux/modalManagerSlice";

const MediaUpload = (props) => {
    const { fileUrl, onUpload, onDelete, mediaUpdateFunctionName, listType, uploadText} = props;
    const [deleteLogo, mediaUploadWidgetOpen, res] = useMediaUpload();
    const dispatch = useDispatch();
    const [localFileUrl, setLocalFileUrl] = useState(fileUrl);
    const [mediaFile, setMediaFile] = useState([{
        uid: -1,
        status: 'done',
        url: localFileUrl
    }]);

    useEffect(() => {
        if (res && res.secure_url) {
            setLocalFileUrl(res.secure_url);
            setMediaFile([{
                uid: -1,
                status: 'done',
                url: res.secure_url,
                name: res.original_file_name
            }]);
        }

        if(onUpload && res) {
            onUpload(res);
        }
    }, [res]);

    const onRemove = () => {
        dispatch(openModal({modalName: STEPPER_CONFIRM_MODAL, props: {header: t('areYouSure'), subheader: t('areYouSureDeleteSpaceImage'), onConfirm: () => {
            setLocalFileUrl(null);
            setMediaFile([{
                uid: -1,
                status: 'done',
                url: null
            }]);
            dispatch(closeModal({ modalName: STEPPER_CONFIRM_MODAL}));
            if(onDelete) {
                onDelete();
            }
        }}}))
    }

    return (
        <Upload
            fileList={localFileUrl && mediaFile}
            listType={listType ?? 'picture'}
            openFileDialogOnClick={false}
            onRemove={onRemove}
            onPreview={() => mediaUploadWidgetOpen(mediaUpdateFunctionName)}
            style={{ width: '100%'}}
            rootClassName={'media-upload-image'}
        >
            {
                !localFileUrl &&
                <CustomButtonV2 onClick={() => mediaUploadWidgetOpen(mediaUpdateFunctionName)}
                                iconName={'fa-arrow-up-from-bracket'}
                                text={uploadText}
                                style={{justifyContent: 'start', borderColor: '#E0E0E0'}}
                                textProps={{color: Colors.newDesign.mainText}}
                                iconProps={{ iconHoverColor: Colors.newDesign.mainText }}
                                applyHoverOnText={false}
                />
            }
        </Upload>
    );
};

export default MediaUpload;
