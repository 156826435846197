import React, {useEffect, useMemo, useState} from 'react';
import {Divider, HStack, Pressable, VStack} from "native-base";
import {t} from "../../../../../../services/i18n";
import AppText from "../../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../../styles/GlobalStyles";
import {Colors} from "../../../../../../styles/Colors";
import {useDispatch, useSelector} from "react-redux";
import {optionalSteps} from "../StepOptions";
import {updateTempSelection} from "../../../../../../redux/stepperSlice";
import {isBoxFitnessType, openLimitedPackageModal} from "../../../../../../services/Helpers";
import usePermissions from "../../../../../../services/Permissions";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {Radio} from "antd";
import CustomButtonV2 from "../../../../../common/CustomButtonV2";

const EventTypeStep = (props) => {
    const { initSelection } = props;
    const selectionsTree = useSelector(state => state.stepper.selectionsTree)
    const { has, hasLock } = usePermissions();
    const types = useMemo(() => {
        const hugimOrWorkshop = has('hugim') ? {hugim: {id: 'hugim', name: t('hugim')}} : {workshop: {id: 'workshop', name: t('course'), isLocked: hasLock('workshops'), hasPermission: has('workshops')}}
        return {
            class: {id: 'class', name: t('groupClass')},
            appointment: {id: 'appointment', name: t('appointment'), isLocked: hasLock('appointments'), hasPermission: has('appointments')},
            ...hugimOrWorkshop,
            blocked: {id: 'blocked', name: t('blocked-time'), isLocked: hasLock('blockedEvents')},
        }
    },[]);

    const orderedTypes = useMemo(() => {
        if(isBoxFitnessType()) {
            return Object.values(types)
        }
        else {
            return [types.class, types.appointment, has('hugim') ? types.hugim : types.workshop, types.blocked]
        }
    },[types])

    const [selection, setSelection] = useState(selectionsTree[optionalSteps.EVENT_TYPE] ?? initSelection ?? orderedTypes[0].id);
    const dispatch = useDispatch()

    useEffect(() => {
        if(!selectionsTree[optionalSteps.EVENT_TYPE] || selectionsTree[optionalSteps.EVENT_TYPE] !== selection)
            dispatch(updateTempSelection({[optionalSteps.EVENT_TYPE]: selection}))
    }, [selection]);

    const openLockPopup = () => {
        openLimitedPackageModal(dispatch)
    }

    return (
        <Radio.Group name={'event-type-radio'} value={selection}>
            <VStack space={'1rem'} w={'100%'}>
                {
                    orderedTypes.map(type => (
                        type.hasPermission !== false && <CustomButtonV2 onClick={() => type.isLocked ? openLockPopup() : setSelection(type.id)}
                                   key={`event-type-radio-option-${type.id}`}
                                   iconProps={{iconColor: Colors.borderColor, iconHoverColor: Colors.newDesign.mainBlue}}
                                   override={({color}) => (
                                        <VStack w={'100%'}>
                                            {type.id === 'blocked' && <Divider mb={'1rem'} bgColor={Colors.borderColor}/>}
                                            <HStack space={'0.5rem'} alignItems={'start'} p={'1rem'} borderWidth={1} borderColor={selection === type.id ? Colors.newDesign.mainBlue : color} borderRadius={4} opacity={type.isLocked ? '50%' : '100%'}>
                                                <Radio value={type.id} size="sm" disabled={type.isLocked} onClick={(e) => {
                                                    e.stopPropagation()
                                                    type.isLocked ? openLockPopup() : setSelection(type.id)
                                                }}/>
                                                <VStack flex={1}>
                                                    <AppText fontSize={GlobalStyleAttributes.fonts.heading3} boldFont>{type.name}</AppText>
                                                    <AppText fontSize={GlobalStyleAttributes.fonts.heading3}>{t(`${type.id}-explain`)}</AppText>
                                                    <AppText fontSize={GlobalStyleAttributes.fonts.heading4} color={Colors.newDesign.darkGrey}>{t(`${type.id}-example`)}</AppText>
                                                </VStack>
                                                {type.isLocked && <VStack><FontAwesomeIcon icon={'fa-light fa-lock-keyhole'}/></VStack>}
                                            </HStack>
                                        </VStack>)}
                        />
                        )
                    )
                }
            </VStack>
        </Radio.Group>
    );
};

export default EventTypeStep;
