import { Space } from 'antd';
import * as yup from 'yup';
import CustomCheckbox from '../components/common/CustomCheckbox';
import InfoTooltip from '../components/common/InfoTooltip';
import {
    getBoxCancelReasons, getBoxSettings,
    getBoxSuspendReasons,
    saveLeadSource,
    saveLeadStatus,
    saveLostLeadReason,
    saveTaskType, updateBox
} from '../redux/boxSlice';
import {closeModal, openModal, SETTINGS_VALUE_MODAL} from '../redux/modalManagerSlice';
import { store } from '../redux/store';
import { MESSAGE_TYPE, TOAST_TYPE } from '../services/ConstHelper';
import { fetchApi } from '../services/HTTP';
import { t } from '../services/i18n';
import {updateSpaceInSelectedLocation} from "../services/ScheduleService";
import {hideLoader, setAngularPath, showLoader} from "../redux/rootManagerSlice";
import ConfirmModal from "../components/common/ConfirmModal";
import React from "react";
import AppText from "../components/common/AppText";
import MediaUpload from "../components/common/MediaUpload";
import {mediaUpdateFunctions} from "../Hooks/useMediaUpload";
import SitePurchaseLimitations
    from "../components/Structure/Schedule/Popups/SitePurchaseLimitations/SitePurchaseLimitations";
import {getBoxSettingProperties} from "../services/Helpers";


export const LEAD_SOURCE_TYPE = 'leadSource'
export const LEAD_STATUS_TYPE = 'leadStatus'
export const SMS_DISPLAY_VERIFY = 'smsDisplayVerify'
export const EDIT_SPACE_NAME = 'editSpaceName'
export const EDIT_SPACE_IMAGE = 'editSpaceImage'
export const TASK_TYPE = 'taskType'
export const LOST_REASONS_TYPE = 'lostReasons'
export const CANCEL_REASONS = 'cancelReasons'
export const SUSPEND_REASONS = 'suspendReasons'
export const SITE_PURCHASE_LIMITATIONS = 'sitePurchaseLimitations'
export const SettingsValueModalOptions = () => ({
    [LEAD_SOURCE_TYPE]: {
        header: 'newLeadSource',
        onSave: async ({dispatch, val, onChange}) => {
            await dispatch(saveLeadSource({name: val}))
            const sources = store.getState().box.box.box_sources;
            if (!sources.find(s => s.name === val))
                throw 'save-failed';
            onChange({value: sources.find(s => s.name === val)?.id})
            dispatch(closeModal({ modalName: SETTINGS_VALUE_MODAL}))
        },
        validationSchema: yup.object().shape({
            val: yup.string().required()
        }),
        maxInputSize: 30,
        errorType: TOAST_TYPE,
        successText: 'added-successfully',
    },
    [LEAD_STATUS_TYPE]: {
        header: 'newLeadStatus',
        onSave: async ({dispatch, val, onChange}) => {
            await dispatch(saveLeadStatus({name: val}))
            const statuses = store.getState().box.box.box_statuses;
            if (!statuses.find(s => s.name === val))
                throw 'save-failed';
            onChange({value: statuses.find(s => s.name === val)?.id})
            dispatch(closeModal({ modalName: SETTINGS_VALUE_MODAL}))
        },
        validationSchema: yup.object().shape({
            val: yup.string().required()
        }),
        maxInputSize: 30,
        errorType: TOAST_TYPE,
        successText: 'added-successfully',
    },
    [SMS_DISPLAY_VERIFY]: {
        header: 'insert-verification-code',
        onSave: async ({params, val, onChange, dispatch}) => {
            try {
                const res = await fetchApi('boxes/authenticateOTP', 'POST', {otp_code: val, ...params}, false, true)
                switch (res) {
                    case 1:
                        onChange('verifiedSenderID', params.sender_id)
                        dispatch(closeModal({ modalName: SETTINGS_VALUE_MODAL}))
                        return
                    case -220:
                        throw t('wrong-otp')
                    case -370:
                        throw t('expired-otp')
                    default:
                        throw t('something-went-wrong')
                }
            } catch (error) {
                throw error
            }
        },
        validationSchema: yup.object().shape({
            val: yup.string().required()
        }),
        saveText: 'verify',
        errorType: MESSAGE_TYPE,
        successText: 'verified-success',
        footerStartIcon: {
            text: 'resend-code',
            icon: 'fa-paper-plane',
            onPress: async ({params}) => {
                const res = await fetchApi('boxes/sendOTP', 'POST', { sender_id: params.sender_id }, false, true)
                if (res === 1) return
                throw 'send-otp-failed'
            },
            onPressSuccess: 'resend-otp-success',
            onPressFail: 'resend-otp-fail'
        }
    },
    [EDIT_SPACE_NAME]: {
        header: 'editSpaceName',
        onSave: async ({dispatch, val, onChange, params}) => {
            const space = await fetchApi('spaces/updateName', 'post', {name: val, id: params.id}, false, true);
            updateSpaceInSelectedLocation(space, dispatch)
            if(onChange) onChange({name: val})
            dispatch(closeModal({ modalName: SETTINGS_VALUE_MODAL}))
        },
        validationSchema: yup.object().shape({
            val: yup.string().required()
        }),
        maxInputSize: 20,
        errorType: TOAST_TYPE,
        successText: 'success-space-name-update',
    },
    [EDIT_SPACE_IMAGE]: {
        header: 'spaceImage',
        onSave: async ({dispatch, val, onChange, params}) => {
            const space = await fetchApi('spaces/updateImage', 'post', {image: val, id: params.id}, false, true);
            updateSpaceInSelectedLocation(space, dispatch)
            if(onChange) onChange({image: val})
            dispatch(closeModal({ modalName: SETTINGS_VALUE_MODAL}))
        },
        additionalComponentRender: ({params, setFieldValue}) => <MediaUpload onUpload={(newImage) => setFieldValue('val', newImage?.public_id?.replace('spaces/',''))} onDelete={() => setFieldValue('val', null)} fileUrl={params?.image} mediaUpdateFunctionName={mediaUpdateFunctions.updateSpaceImage} uploadText={t('clickToUploadSpaceImage')}/>,
        hideTextInput: true,
        successText: 'savedSuccessfully',
    },
    [SITE_PURCHASE_LIMITATIONS]: {
        header: 'sitePurchaseLimitations',
        onSave: async ({dispatch, val}) => {
            const res = await fetchApi(`boxes/settings`, 'POST', {properties: val, prop_name: SITE_PURCHASE_LIMITATIONS}, false, true);
            dispatch(updateBox({settings: {...store.getState().box.box.settings, [SITE_PURCHASE_LIMITATIONS]: res?.properties}}))
            dispatch(closeModal({modalName: SETTINGS_VALUE_MODAL}))
        },
        validationSchema: yup.object().shape({
            active: yup.boolean().required(),
            sessionsPerDay: yup.number().when("active", {
                is: true,
                then: yup.number().min(0).required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
                otherwise: yup.number().nullable()
            })
        }),
        initialValue: (getBoxSettingProperties(SITE_PURCHASE_LIMITATIONS) ?? {active: false, sessionsPerDay: null}),
        overrideVal: true,
        additionalComponentRender: ({...rest}) => <SitePurchaseLimitations {...rest}/>,
        hideTextInput: true,
        successText: 'savedSuccessfully',
        errorType: TOAST_TYPE
    },
    [TASK_TYPE]: {
        header: 'addNewTaskType',
        onSave: async ({dispatch, val, onChange, values}) => {
            await dispatch(saveTaskType({type: val, ...values}))
            const taskTypes = store.getState().box.box.task_types;
            if (!taskTypes.find(s => s.type === val))
                throw 'save-failed';
            onChange({value: taskTypes.find(s => s.type === val)?.id})
            dispatch(closeModal({ modalName: SETTINGS_VALUE_MODAL}))
        },
        validationSchema: yup.object().shape({
            val: yup.string().required(),
            check_in: yup.boolean(),
            access_control: yup.boolean()
        }),
        maxInputSize: 30,
        errorType: TOAST_TYPE,
        successText: 'added-successfully',
    },
    [LOST_REASONS_TYPE]: {
        header: 'newLostReason',
        onSave: async ({dispatch, val, onChange}) => {
            await dispatch(saveLostLeadReason({name: val}))
            const lostReasons = store.getState().box.box.lead_lost_reasons;
            if (!lostReasons.find(s => s.name === val))
                throw 'save-failed';
            onChange({value: lostReasons.find(s => s.name === val)?.id})
            dispatch(closeModal({ modalName: SETTINGS_VALUE_MODAL}))
        },
        validationSchema: yup.object().shape({
            val: yup.string().required()
        }),
        maxInputSize: 20,
        errorType: TOAST_TYPE,
        successText: 'added-successfully',
    },
    [CANCEL_REASONS]: {
        header: 'cancelReasonHeader',
        onSave: async ({params, val, dispatch}) => {
            const isEditMode = params?.name;
            const boxCancelReasons = store.getState().box.box.boxCancelReasons;
            const hasDuplication = boxCancelReasons?.find(
                reason => reason.name.toLowerCase() === val.toLowerCase() && (!isEditMode || reason.id !== params?.id)
            );

            if (hasDuplication) throw t('cancelReasonDuplicateError');

            await fetchApi(`${isEditMode ? 'edit' : 'create'}BoxCancelReason`, 'POST', {reason: {cancel_reason_id: params.id, name: val}}, false, true)
            dispatch(setAngularPath(null));
            setTimeout(() => dispatch(setAngularPath('settings')), 100);
            dispatch(getBoxCancelReasons());
            dispatch(closeModal({ modalName: SETTINGS_VALUE_MODAL}))
        },
        maxInputSize: 30,
        errorType: MESSAGE_TYPE,
        successText: 'savedSuccessfully',
    },
    [SUSPEND_REASONS]: {
        header: 'suspendReasonHeader',
        onSave: async ({params, val, dispatch}) => {
            const isEditMode = params?.name;
            const boxSuspendReasons = store.getState().box.box.boxSuspendReasons;
            const hasDuplication = boxSuspendReasons?.find(
                reason => reason.name.toLowerCase() === val.toLowerCase() && (!isEditMode || reason.id !== params?.id)
            );

            if (hasDuplication) throw t('suspendReasonDuplicateError');

            await fetchApi(`${isEditMode ? 'edit' : 'create'}BoxSuspendReason`, 'POST', {reason: {suspend_reason_id: params.id, name: val}}, false, true)
            dispatch(setAngularPath(null));
            setTimeout(() => dispatch(setAngularPath('settings')), 100);
            dispatch(getBoxSuspendReasons());
            dispatch(closeModal({ modalName: SETTINGS_VALUE_MODAL}))
        },
        maxInputSize: 30,
        errorType: MESSAGE_TYPE,
        successText: 'savedSuccessfully',
    }
})