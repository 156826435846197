import React, { useEffect, useMemo, useState } from 'react';
import { t } from '../../../../services/i18n';
import { Flex, Radio } from "antd";
import CustomButtonV2 from '../../../common/CustomButtonV2';
import { Colors } from '../../../../styles/Colors';
import { GlobalStyleAttributes } from '../../../../styles/GlobalStyles';
import AppText from '../../../common/AppText';


const GatesApplySettings = ({ setFieldValue, values }) => {
    const types = useMemo(() => [
        { id: 'all', name: t('activity-hours-for-all-gates') },
        { id: 'each', name: t('activity-hours-for-each-gate') }
    ], []);

    return (
        <Radio.Group name={'ufc-options-radio'} value={values.apply}>
            <Flex vertical gap={'1rem'}>
                {
                    types.map(type => (
                        <CustomButtonV2 onClick={() => setFieldValue('apply', type.id)}
                            key={`gc-option-${type.id}`}
                            iconProps={{ iconColor: Colors.borderColor, iconHoverColor: Colors.hoverBlueLight }}
                            override={({ color }) =>
                            (
                                <Flex gap={'0.5rem'} style={{ width: '100%', padding: '1rem', borderStyle: 'solid', borderWidth: 1, borderColor: values.apply === type.id ? Colors.newDesign.mainBlue : color, borderRadius: 4 }}>
                                    <Radio value={type.id} size="sm" />
                                    <Flex direction={'column'} flex={1}>
                                        <AppText fontSize={GlobalStyleAttributes.fonts.heading3} boldFont>{type.name}</AppText>
                                    </Flex>
                                </Flex>
                            )}
                        />
                    )
                    )
                }
            </Flex>
        </Radio.Group>
    );
}

export default GatesApplySettings;