import React, {useEffect, useState} from 'react';
import EStyleSheet from "react-native-extended-stylesheet";
import {HStack, Switch, VStack} from "native-base";
import {useDispatch, useSelector} from "react-redux";
import {updateScheduleSetting} from "../../../../../../../redux/ScheduleSlice";
import {isMobile} from "../../../../../../../services/Helpers";
import CustomSwitch from "../../../../../../common/CustomSwitch";
import AppText from "../../../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../../../styles/GlobalStyles";
import {t} from "../../../../../../../services/i18n";
import { NOTIFICATION_SETTINGS } from '../../../../../../../services/ConstHelper';

const WhatsappNotification = (props) => {
    const { values, setFieldValue } = props;
    const box = useSelector((state) => state.box.box);
    const [isChecked, setIsChecked] = useState(!!values?.allowWhatsappWaitList);
    const dispatch = useDispatch()

    console.log('box', box)

    useEffect(() => {
        if(box) setIsChecked(!!(box.allow_whatsapp_to_waiting_list ?? box.settings?.[NOTIFICATION_SETTINGS]?.allow_whatsapp_to_waiting_list))
    }, [box.settings?.[NOTIFICATION_SETTINGS]]);

    const toggle = (val) => {
        const value = val ? 1 : 0
        if(isMobile) dispatch(updateScheduleSetting({allow_whatsapp_to_waiting_list: value}))
        else {
            setFieldValue('allowWhatsappWaitList', value)
        }
        setIsChecked(val)
    }

    return (
        <>
            {isMobile ? <CustomSwitch isChecked={isChecked} onToggle={toggle} style={{ width: isMobile && 'inherit' }} />
            :
            <VStack space={'1rem'}>
                <HStack justifyContent={'space-between'} alignItems={'center'}>
                    <AppText fontSize={GlobalStyleAttributes.fonts.standard16} boldFont>{t('whatsappNotificationLbl')}</AppText>
                    <CustomSwitch isChecked={isChecked} onToggle={toggle} style={{ width: isMobile && 'inherit' }} />
                </HStack>
                <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('whatsappNotificationTxt')}</AppText>
            </VStack>}
        </>
    );
};

export default WhatsappNotification;
