import React, {useEffect, useMemo, useState} from 'react';
import {t, isRTL} from "../../../../../../services/i18n";
import {customFieldTypes, SCHEDULE_TYPE, USER_TYPE} from "../../../../../../services/ConstHelper";
import SimpleInput from "../../../../../common/SimpleInput";
import {HStack, VStack} from "native-base";
import AppText from "../../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../../styles/GlobalStyles";
import {Colors} from "../../../../../../styles/Colors";
import AdvanceSelect from "../../../../../common/AdvanceSelect";
import {useSelector} from "react-redux";
import CustomCheckbox from "../../../../../common/CustomCheckbox";
import {fetchApi} from "../../../../../../services/HTTP";
import CustomButtonV2 from "../../../../../common/CustomButtonV2";
import {
    DndContext,
    closestCenter,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import DraggableCustomFieldOption from "../ReusableComponents/DraggableCustomFieldOption";
import {Spin} from "antd";
import usePermissions from "../../../../../../services/Permissions";
import {flowOptions} from "../FlowOptions";

const CustomFieldSettings = (props) => {
    const { values, errors, setFieldValue, setTouched, touched, isTypeDisabled, currentFlows } = props;
    const [flows, setFlows] = useState([]);
    const isScheduleType = values.customFieldType === SCHEDULE_TYPE;
    const isUserType = values.customFieldType === USER_TYPE;
    const box = useSelector(state => state.box.box)
    const boxLocations = box?.locations_box?.map(location => ({ id: location.id, value: location.id, label: location.location }));
    const typesArray = useMemo(() => Object.keys(customFieldTypes).map(key => ({label: t(customFieldTypes[key] + 'CustomFieldInputType'), value: customFieldTypes[key]})),[]);
    const sensors = useSensors(useSensor(PointerSensor));
    const isEditType = useSelector(state => state.stepper?.editType);
    const { has } = usePermissions();
    const flowPermissions = {
        [flowOptions.GROUP_CLASS_FLOW]: { hide: !has('scheduleAddNewClass')},
        [flowOptions.APPOINTMENT_BASIC]: { hide: !has('appointments')},
        [flowOptions.HUGIM_FLOW]: { hide: !has('hugim')},
        [flowOptions.BOOK_TO_HUG_FLOW]: { hide: !has('hugim')},
    }

    useEffect(() => {
        props.errors = {};
        setTouched({})
        if(isUserType && !isEditType) {
            setFieldValue('inputType', customFieldTypes.TEXT);
        }
    }, []);

    useEffect(() => {
        if(values.customFieldType && !flows.length) {
            fetchFlows();
        }
    },[values.customFieldType]);

    useEffect(() => {
        if(isScheduleType) {
            setFieldValue('inputType', customFieldTypes.SELECT);
        }
    },[isScheduleType])


    useEffect(() => {
        if(isScheduleType && !values.props?.options) {
            setFieldValue('props.options', [{label: '', value: '', id: 1}]);
        }
    },[values.props, isScheduleType])

    useEffect(() => {
        if(values.inputType !== customFieldTypes.SELECT) {
            setFieldValue('props.options', null);
        }
        else if(values.inputType === customFieldTypes.SELECT && !values.props?.options) {
            setFieldValue('props.options', [{label: '', value: '', id: 1}]);
        }
    },[values.inputType])

    const fetchFlows = async () => {
        if(values.customFieldType){
            const res = await fetchApi(`customField/getFlowByFieldType/${values.customFieldType}`, 'GET', [], false, true);
            const currentFlowsSelectedId = currentFlows?.map(currentFlow => currentFlow.flow_id);
            const flowFilteredByPermission = res.filter(flow => !flowPermissions[flow.name]?.hide);
            const flowsFormatted = flowFilteredByPermission.map(flow => ({...flow, checked: currentFlowsSelectedId?.includes(flow.id) ?? null}));
            setFlows(flowsFormatted);
            setFieldValue('flows', flowsFormatted)
        }
    }

    const handleAddOptionClick = () => {
        let length = values.props?.options?.length;
        setFieldValue('props.options', [...values.props?.options || [], {label: '', value: '', id: length ? length + 2 : 1}]);
    }

    const handleCheckboxClick = (val, flow) => {
        const updatedFlows = flows.map(item => item.id === flow.id ? { ...item, checked: val } : item);
        setFlows(updatedFlows);
        setFieldValue('flows', updatedFlows.filter(flow => flow.checked));
    }

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            setFieldValue(`props.options`, reorder(values.props.options, active, over));
        }
    };

    const reorder = (data, active, over) => {
        const oldIndex = data.findIndex((item) => item.id === active.id);
        const newIndex = data.findIndex((item) => item.id === over.id);
        return arrayMove(data, oldIndex, newIndex);
    }

    return (
        flows.length ?
        <>
            <VStack space={'0.2rem'}>
                <HStack space={'0.2rem'}>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t(`custom-field-name-header`)}</AppText>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText}
                             color={values.customFieldName?.length > 30 ? Colors.error : Colors.newDesign.mediumGrey}
                             style={{ marginLeft: isRTL() ? 0 : 'auto', marginRight: isRTL() ? 'auto' : 0 }}>
                                {values.customFieldName?.length}/30
                    </AppText>
                </HStack>
                <HStack space={'1rem'} alignItems={'center'}>
                    <SimpleInput value={values.customFieldName}
                                 onChangeCallback={val => setFieldValue('customFieldName', val)}
                                 status={touched.customFieldName && errors.customFieldName ? 'error' : null}
                    />
                </HStack>
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.customFieldName && touched.customFieldName ? errors.customFieldName : ''}</AppText>
            </VStack>
            <VStack space={'0.2rem'} marginTop={'25px'}>
                <HStack space={'0.2rem'}>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t(`custom-field-type-header`)}</AppText>
                </HStack>
                <AdvanceSelect type={USER_TYPE} options={isScheduleType ? null : typesArray} initValue={values.inputType} isDisabled={isTypeDisabled || isScheduleType || isEditType} onChange={(val) => setFieldValue('inputType', val?.value ?? null)} />
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{touched.inputType && errors.inputType}</AppText>
            </VStack>
            {
                values.props?.options &&
                <>
                    <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCenter}>
                        <SortableContext items={values.props?.options} strategy={verticalListSortingStrategy}>
                            {
                                values.props.options?.map((option, index) => (
                                    <>
                                        <DraggableCustomFieldOption option={option} key={option.id} index={index}
                                                                    setFieldValue={setFieldValue} values={values}
                                                                    isScheduleType={isScheduleType}/>
                                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{touched.props?.options[index]?.value && errors.props?.options[index]?.value}</AppText>
                                    </>
                                ))
                            }
                        </SortableContext>
                    </DndContext>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{touched.props?.options && errors.props?.options === t('mustSetAtLeastTwoOptionsError') && errors.props.options}</AppText>
                </>
            }
            { values.inputType === customFieldTypes.SELECT && <CustomButtonV2 type={'link'} onClick={handleAddOptionClick} wrapperStyle={{ width: 'max-content' }} text={`+ ${t('addOption')}`} color={Colors.newDesign.mainBlue} textProps={{ color: Colors.newDesign.mainBlue, fontSize: '14px' }} style={{ marginTop: '10px' }} /> }
            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont style={{ marginTop: '20px' }}>{t(`custom-field-additional-props-header`)}</AppText>
            {
                flows?.map((flow) => {
                    return  <HStack marginTop={'10px'} key={flow.name}>
                                <CustomCheckbox isChecked={flow.checked} onChange={(val) => handleCheckboxClick(val, flow)} text={t(`custom-field-checkbox-${flow.name.toLowerCase()}`)} />
                            </HStack>
                })
            }
            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{touched.flows && errors.flows}</AppText>
            {
                <HStack marginTop={'10px'}>
                    <CustomCheckbox isChecked={values.props.isRequired} onChange={(val) => setFieldValue('props.isRequired', val)} text={t(`custom-field-checkbox-is-required`)} />
                </HStack>
            }
            {
                boxLocations.length > 1 &&
                <div style={{ marginTop: '25px' }}>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('location')}</AppText>
                    <AdvanceSelect
                        isClearable
                        isMulti
                        onChange={(option) => setFieldValue('locations', option)}
                        value={values?.locations}
                        options={boxLocations}
                    />
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{touched.locations && errors.locations}</AppText>
                </div>
            }
        </> :
        <Spin />
    );
};

export default CustomFieldSettings;
