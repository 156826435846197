import { Flex } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { dbTimeFormat, getHourAndMinuteDiff, isBoxIL, isMobile, days as weekDays } from '../../services/Helpers';
import { Colors } from '../../styles/Colors';
import { GlobalStyleAttributes } from '../../styles/GlobalStyles';
import { t } from '../../services/i18n';
import moment from 'moment';
import SharedTimepicker from './SharedTimepicker';
import CustomButtonV2 from './CustomButtonV2';
import CustomCheckbox from './CustomCheckbox';
import { isString } from 'formik';
import AppText from './AppText';



const DaysHoursConfiguration = ({ values, setFieldValue, errors, generateSummary }) => {
    const mobile = isMobile
    const days = useMemo(() => weekDays(), [])
    const daysWithOrder = useMemo(() => {
        if (days) {
            if (isBoxIL()) return Object.values(days);
            else return [days['1'], days['2'], days['3'], days['4'], days['5'], days['6'], days['0']]
        }
    }, [days]);

    const onTimeChange = (name, value) => {
        const time = value.m.format(dbTimeFormat)
        setFieldValue(name, time)
    }

    const toggleDayFromArray = (isOpen, dayId) => {
        let newDays = [...values.multiDayConfig.filter(d => d.day !== dayId)]
        if(isOpen) {
            newDays = [...newDays, {day: dayId, setting: [{start_time: '07:00:00', end_time: '17:00:00', limitations: []}]}]
        }
        setFieldValue('multiDayConfig', newDays)
    }

    const addSettingToDay = (index) => {
        let newSetting = [...values.multiDayConfig[index].setting]
        newSetting = [...newSetting, {start_time: '18:00:00', end_time: '20:00:00', limitations: []}]
        setFieldValue(`multiDayConfig[${index}].setting`, newSetting)
    }

    const removeSettingFromDay = (indexInArray, indexInSetting) => {
        let newSetting = [...values.multiDayConfig[indexInArray].setting]
        newSetting.splice(indexInSetting, 1)
        setFieldValue(`multiDayConfig[${indexInArray}].setting`, newSetting)
    }

    return (
        <Flex vertical gap={'0.2rem'}>
            {daysWithOrder.map(day => {
                const selectedDayIndex = values.multiDayConfig.findIndex(d => d.day === day.id)
                const isSelected = selectedDayIndex !== -1
                return (
                    <Flex gap={'1rem'} key={`repeat-days-${day.id}`} style={{padding: '0.7rem', backgroundColor: isSelected ? Colors.newDesign.menuBar : Colors.newDesign.white, minHeight: '3rem' }} align={'center'}>
                        <Flex gap={'1rem'} flex={1} alignSelf={isSelected && values.multiDayConfig[selectedDayIndex].setting.length > 1 ? 'start' : 'center'}>
                            <CustomCheckbox text={day.name} isChecked={isSelected} onChange={() => toggleDayFromArray(!isSelected, day.id)} />
                        </Flex>
                        <Flex vertical gap={'0.5rem'}>
                            {isSelected && values.multiDayConfig[selectedDayIndex].setting.map((item, index) => {
                                const diffObj = getHourAndMinuteDiff(item.start_time, item.end_time)
                                return (
                                    <Flex gap={'1rem'} align={'center'} key={`repeat-days-setting-${day.id}-${index}`}>
                                        <Flex vertical={mobile ? true : false} gap={'0.5rem'} align={'center'}>
                                            <Flex gap={'0.5rem'} align={'center'} style={{ width: mobile ? '100%' : 'auto'}}>
                                                <SharedTimepicker type={'time'}
                                                    value={moment(item.start_time, dbTimeFormat)}
                                                    onChange={(time) => onTimeChange(`multiDayConfig[${selectedDayIndex}].setting[${index}].start_time`, time)}
                                                    wrapperStyle={[mobile && { flex: 1 }]}
                                                    textStyle={{ fontSize: GlobalStyleAttributes.fonts.standard16 }}
                                                    inputStyle={[mobile ? { padding: '5px', width: '5rem' } : { width: '5.5rem' }]} />
                                                <AppText fontSize={GlobalStyleAttributes.fonts.infoText}>-</AppText>
                                                <SharedTimepicker type={'time'}
                                                    value={moment(item.end_time, dbTimeFormat)}
                                                    onChange={(time) => onTimeChange(`multiDayConfig[${selectedDayIndex}].setting[${index}].end_time`, time)}
                                                    wrapperStyle={[mobile && { flex: 1 }]}
                                                    textStyle={{ fontSize: GlobalStyleAttributes.fonts.standard16 }}
                                                    inputStyle={[mobile ? { padding: '5px', width: '5rem' } : { width: '5.5rem' }, (diffObj.hours + diffObj.minutes) <= 0 && { borderColor: Colors.error }]} />
                                            </Flex>
                                        </Flex>
                                        <CustomButtonV2 onClick={() => index === 0 ? addSettingToDay(selectedDayIndex) : removeSettingFromDay(selectedDayIndex, index)}
                                            type={'link'}
                                            iconName={index === 0 ? 'fa-plus' : 'fa-trash-can'}
                                            iconProps={index === 0 ? { iconColor: Colors.newDesign.mainBlue } : {}}
                                        />
                                    </Flex>
                                )
                            })}
                        </Flex>
                    </Flex>
                )
            })}
            {isString(errors?.multiDayConfig) && <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors?.multiDayConfig}</AppText>}
            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.newDesign.darkGrey}>{generateSummary?.()}</AppText>
        </Flex>
    );
}

export default DaysHoursConfiguration;