import React, {useEffect, useMemo, useState} from 'react';
import {HStack, VStack} from "native-base";
import AppText from "../../../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../../../styles/GlobalStyles";
import {t} from "../../../../../../../services/i18n";
import {GlobalConsts} from "../../../../../../../styles/GlobalConsts";
import {Colors} from "../../../../../../../styles/Colors";
import {generateSiteLink, generateSiteV2Link} from "../../../../../../../services/Helpers";
import {useSelector} from "react-redux";
import {useToast} from "react-native-toast-notifications";
import CustomButtonV2 from "../../../../../../common/CustomButtonV2";
import usePermissions from "../../../../../../../services/Permissions";
import FormInput from "../../../../../../common/FormInput";
import AdvanceSelect from "../../../../../../common/AdvanceSelect";
import {Skeleton, Tooltip} from "antd";

const EmbedSchedule = (props) => {
    const { } = props;
    const box = useSelector((state) => state.box.box);
    const selectedLocation = useSelector((state) => state.userSettings.scheduleLocation);
    const [options, setOptions] = useState([])
    const [location, setLocation] = useState(selectedLocation.id)
    const [loader, setLoader] = useState(false)
    const locationOptions = useMemo(() => box ? box.locations_box?.map(location => ({ value: location.id, label: location.location })) : [], [box]);
    const locationOptionsAllLocations = useMemo(() => locationOptions.length > 1 && box.isBoxHasNewSite ? [{ value: 'allLocations', label: t('allLocations') }, ...locationOptions] : locationOptions, [locationOptions]);
    const schedulePathsNewSite = useMemo(() => location === 'allLocations' ? [{name: 'group', route: 'group'}, {name: 'bookForTrialClass', permission: 'bookForTrialClassSite', route: 'group', addons: '&filters=%7B%22trial%22%3A%22trial%22%2C%22pageName%22%3A%22group%22%7D'}, {name: 'course', permission: 'hugim', route: "course"}] : [{name: 'group', route: "group"}, {name: 'bookForTrialClass', permission: 'bookForTrialClassSite', route: "group", addons: '&filters=%7B%22trial%22%3A%22trial%22%2C%22pageName%22%3A%22group%22%7D'}, {name: 'course', permission: 'hugim', route: "course"}, {name: 'staff-appointment', route: 'staff-appointment'}, {name: 'space-appointment', permission: 'spaces', route: 'space-appointment'}], [location])
    const schedulePathsOldSite = useMemo(() => [{name: 'schedule'}], [])
    const toast = useToast()
    const {has} = usePermissions()

    useEffect(() => {
        if(box) {
            let paths = box.isBoxHasNewSite ?  schedulePathsNewSite : schedulePathsOldSite
            paths = paths.map(path => {
                let params = {referer: 'PLUGIN', location}
                let newSiteQueryAddons = ''
                if(location === 'allLocations') {
                    params.location = null
                    newSiteQueryAddons = `&allLocations=true`
                }
                if(path.addons) {
                    newSiteQueryAddons += path.addons
                }
                const link = box.isBoxHasNewSite ? generateSiteV2Link(path.route, params, newSiteQueryAddons) : generateSiteLink(path.name, params)
                const html = `<iframe width="100%" height="600" src=${link}></iframe>`
                return {...path, link, html}
            })
            setOptions(paths)
        }
    }, [box, location]);

    const copyHtml = (html) => {
        navigator.clipboard.writeText(html)
        toast.show(t('copiedEmbedToClipboard'),{
            type: "success",
            duration: 2000,
        })
    }

    const copyLink = (link) => {
        navigator.clipboard.writeText(link)
        toast.show(t('copiedToClipboard'),{
            type: "success",
            duration: 2000,
        })
    }

    const changeLocation = (option) => {
        setLoader(true)
        setLocation(option.value ?? null)
        setTimeout(() => {
            setLoader(false)
        }, 500);
    }

    return (
        <>
        {box.isBoxHasNewSite ?
            <VStack space={'0.5rem'} width={'100%'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('embedScheduleBody')}</AppText>
                {locationOptions.length > 1 &&
                    <FormInput containerProps={{ mb: '1rem' }} label={t('location')} width={'300px'}>
                        <AdvanceSelect dontSort options={locationOptionsAllLocations} initValue={location} onChange={changeLocation} />
                    </FormInput>
                }
                <VStack space={'1.25rem'} style={{ maxHeight: '290px'}}>
                {options.filter(option => (option.permission && has(option.permission)) || !option.permission).map(option => (
                    <VStack space={'0.5rem'}>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t(`${option.name}Embed`)}</AppText>
                        <HStack borderRadius={4} borderColor={Colors.borderColor} borderWidth={1} p={'0.5rem'} width={'700px'} height={'75px'} alignItems={'center'} justifyContent={'space-between'} padding={'12px'}>
                            {loader ?
                                <VStack space={'10px'} width={'100%'}>
                                    <Skeleton.Input size={'small'} style={{ width: '85%' }} active />
                                    <Skeleton.Input size={'small'} style={{ width: '100%' }} active />
                                </VStack>
                                :
                                <>
                                    <CustomButtonV2
                                        onClick={(e) => e.preventDefault() | e.stopPropagation() | window.open(option.link)}
                                        wrapperStyle={{width: '80%'}}
                                        override={({isHovered}) => (
                                            <a href={option.link} style={{textOverflow: 'ellipsis', width: '80%'}}>
                                                <AppText fontSize={GlobalStyleAttributes.fonts.heading4} color={isHovered ? Colors.newDesign.mainBlue : Colors.newDesign.darkGrey}>{option.link}</AppText>
                                            </a>
                                        )}/>
                                    <HStack space={'21px'}>
                                        <Tooltip title={t('copyLinkTooltip')}>
                                            <CustomButtonV2 onClick={() => copyLink(option.link)} type="link" iconName={'fa-copy'}
                                                iconProps={{iconSize: GlobalConsts.iconSize.XS}}/>
                                        </Tooltip>
                                        <Tooltip title={t('copyEmbedCodeTooltip')}>
                                            <CustomButtonV2 onClick={() => copyHtml(option.html)} type="link" iconName={'fa-code'}
                                                iconProps={{iconSize: GlobalConsts.iconSize.XS}}/>
                                        </Tooltip>
                                    </HStack>
                                </>
                            }
                        </HStack>
                    </VStack>
                ))}
                </VStack>
            </VStack>
                :
            options.map(option => (
                <VStack space={'0.5rem'}>
                    <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('embedScheduleBody')}</AppText>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoTstandard16ext}>{t(`${option.name}Html`)}</AppText>
                    <HStack borderRadius={4} borderColor={Colors.borderColor} borderWidth={1} p={'0.5rem'}>
                        <AppText fontSize={GlobalStyleAttributes.fonts.standard16} color={Colors.newDesign.darkGrey}>{option.html}</AppText>
                        <CustomButtonV2 onClick={() => copyLink(option.link)} type="link" iconName={'fa-copy'} iconProps={{iconSize: GlobalConsts.iconSize.XSS}}/>
                    </HStack>
                </VStack>
                ))

        }
        </>

    );
};

export default EmbedSchedule;
