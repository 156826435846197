import CustomSwitch from "../../../../../../common/CustomSwitch";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {HStack, VStack} from "native-base";
import AppText from "../../../../../../common/AppText";
import {t} from "../../../../../../../services/i18n";
import {GlobalStyleAttributes} from "../../../../../../../styles/GlobalStyles";


const QuickViewDisplay = (props) => {
    const {values, setFieldValue} = props
    const box = useSelector((state) => state.box.box);
    const [isChecked, setIsChecked] = useState(!!values?.show_schedule_preview);

    useEffect(() => {
        if(box) {
            setIsChecked(!!box.show_schedule_preview)
        }
    }, [box.show_schedule_preview]);

    const toggle = (val) => {
        const value = val ? 1 : 0
        setFieldValue('showSchedulePreview', value)
        setIsChecked(val)
    }

    return (
        <VStack space={'0.5rem'}>
            <HStack justifyContent={'space-between'} alignItems={'center'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.standard16} boldFont>{t('showSchedulePreview')}</AppText>
                <CustomSwitch isChecked={isChecked} onToggle={toggle}/>
            </HStack>
            <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('showSchedulePreviewTxt')}</AppText>
        </VStack>


    )
}

export default QuickViewDisplay