import {optionalSteps} from "./StepOptions";
import {store} from "../../../../../redux/store";
import {
    createAvailabilities,
    createEvent, createSpace, getScheduleCustomFields,
    rescheduleAppointment, toggleScheduleLoader, updateAvailability,
    updateEvent
} from "../../../../../redux/ScheduleSlice";
import {t} from "../../../../../services/i18n";
import AppText from "../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../styles/GlobalStyles";
import {Pressable, VStack} from "native-base";
import React from "react";
import {addToSelectionsTree, cleanStepper, popStepperStack, setError, toggleStepperSuccess, updateStepper} from "../../../../../redux/stepperSlice";
import {
    dbTimeFormat,
    generateSiteLink, generateSiteV2Link,
    getBoxDateFormat, getBoxTimeFormat, getLocale, getMessageParams,
    getMessageTags,
    getPenaltiesTags,
    sendDataEvent, targetAudienceTasks
} from "../../../../../services/Helpers";
import {
    BOOKING_SERVICES_DISPLAY_MODAL,
    closeModal, CREATE_EVENT_MODAL, CREATE_EVENT_TYPE_MODAL,
    CREATE_USER_MODAL,
    EVENT_MANAGEMENT_MODAL,
    hideModal,
    openModal, SCHEDULE_SETTINGS_MODAL, SETTINGS_VALUE_MODAL, STEPPER_CONFIRM_MODAL, TASKS_SETTINGS_MODAL
} from "../../../../../redux/modalManagerSlice";
import {
    APPOINTMENT,
    EMAIL,
    AVAILABILITY_TYPE, CLASS,
    EXISTING_MEMBERSHIP,
    ONE_TIME_PURCHASE,
    SCHEDULE_TYPE,
    SMS,
    WORKSHOP,
    TOAST_TYPE, NO_MEMBERSHIP, PAGE_TYPE, HUGIM, SERIES_FUTURE_SESSIONS_EDIT_TYPE, SINGLE_SESSION_EDIT_TYPE,
    USER_FIELDS, SITE_TRIAL_SETTINGS,
} from "../../../../../services/ConstHelper";
import {Linking} from "react-native";
import { fetchApi } from "../../../../../services/HTTP";
import { Colors } from "../../../../../styles/Colors";
import { hideLoader, setAngularPath, setAngularState, showLoader } from "../../../../../redux/rootManagerSlice";
import { openAngularPopup, sendMessageToAngular } from "../../../../../services/IframeIntegrationServices";
import {saveLead, saveNewUser, saveStaffMember, setUserFlow} from "../../../../../redux/UserFlowSlice";
import { shareOrCopySiteLink } from "../../../../../services/ScheduleService";
import {saveTaskType, setCounters, updateBox} from "../../../../../redux/boxSlice";
import CustomButtonV2 from "../../../../common/CustomButtonV2";
import moment from "moment";
import { openStepperModal } from "../../../../../services/stepperService";
import ConfirmModal from "../../../../common/ConfirmModal";
import {useSelector} from "react-redux";
import {setFilters} from "../../../../../redux/tasksSlice";
import {MembershipTypes} from "../../../../../Configs/DatabaseConsts";

export const flowOptions = {
    GROUP_CLASS_FLOW: 'GROUP_CLASS_FLOW',
    WORKSHOP_FLOW: 'WORKSHOP_FLOW',
    APPOINTMENT_OR_AVAILABILITY: 'APPOINTMENT_OR_AVAILABILITY',
    AVAILABILITY: 'AVAILABILITY',
    SPACE_AVAILABILITY: 'SPACE_AVAILABILITY',
    APPOINTMENT_BASIC: 'APPOINTMENT_BASIC_FLOW',
    APPOINTMENT_WITH_AVAILABILITY: 'APPOINTMENT_WITH_AVAILABILITY',
    STAFF_MEMBER: 'STAFF_MEMBER',
    LEAD: 'LEAD',
    BLOCKED_TIME: 'BLOCKED_TIME',
    MESSAGE: 'MESSAGE',
    EMAIL_AFTER_PURCHASE: 'EMAIL_AFTER_PURCHASE',
    APPOINTMENT_WITH_SPACE_AVAILABILITY: 'APPOINTMENT_WITH_SPACE_AVAILABILITY',
    SPACE_FLOW: 'SPACE_FLOW',
    PENALTY_FLOW: 'PENALTY_FLOW',
    TASK_FLOW: 'TASK_FLOW',
    TASK_TYPE_FLOW: 'TASK_TYPE_FLOW',
    CONVERT_LINK_FLOW: 'CONVERT_LINK_FLOW',
    CUSTOM_FIELD: 'CUSTOM_FIELD',
    HUGIM_FLOW: 'HUGIM_FLOW',
    BOOK_TO_HUG_FLOW: 'BOOK_TO_HUG_FLOW',
    USER_FIELDS: 'USER_FIELDS',
    SEND_LINK_TO_MEMBERSHIP_FLOW: 'SEND_LINK_TO_MEMBERSHIP_FLOW',
    CONNECT_USER_TO_GROUP: 'CONNECT_USER_TO_GROUP',
    SITE_TRIAL_SETTINGS: 'SITE_TRIAL_SETTINGS',
    BOOK_TO_HUG_TRIAL: 'BOOK_TO_HUG_TRIAL',
    GATES_ACTIVITY_HOURS: 'GATES_ACTIVITY_HOURS',
    BOOK_TO_HUG_CONVERT_LEAD: 'BOOK_TO_HUG_CONVERT_LEAD'
}

export const userFlowsOnly = [flowOptions.BOOK_TO_HUG_FLOW]

export const initFlowConfig = (props) => {
    const { selection, editType, target } = props;
    switch (selection) {
        case CLASS:
            return initGroupClassFlow(editType)
        case APPOINTMENT:
        case flowOptions.APPOINTMENT_BASIC:
            return initAppointmentOrAvailabilityFlow(selection, editType, target)
        case flowOptions.APPOINTMENT_WITH_AVAILABILITY:
            return initAppointmentWithAvailabilityFlow()
        case flowOptions.APPOINTMENT_WITH_SPACE_AVAILABILITY:
            return initAppointmentWithAvailabilityFlow(null, true)
        case AVAILABILITY_TYPE:
            return initAvailabilityFlow(editType)
        case WORKSHOP:
            return initWorkshopFlow(editType);
        case HUGIM:
            return initHugimFlow(editType);
        case flowOptions.STAFF_MEMBER:
            return initStaffMember(props);
        case flowOptions.LEAD:
            return initLead()
        case 'blocked':
            return initBlockedTime(editType)
        case flowOptions.MESSAGE:
            return initMessage({target})
        case flowOptions.EMAIL_AFTER_PURCHASE:
            return initEmailAfterPurchase()
        case flowOptions.SPACE_FLOW:
            return initSpaceFlow();
        case flowOptions.SPACE_AVAILABILITY:
            return initSpaceAvailabilityFlow(editType)
        case flowOptions.PENALTY_FLOW:
            return initPenaltyFlow(props)
        case flowOptions.TASK_FLOW:
            return initTaskFlow(props)
        case flowOptions.CONVERT_LINK_FLOW:
            return initConvertLinkFlow(props)
        case flowOptions.CUSTOM_FIELD:
            return initCustomFieldFlow(props)
        case flowOptions.BOOK_TO_HUG_FLOW:
            return initBookToHugFlow(props)
        case flowOptions.TASK_TYPE_FLOW:
            return initTaskTypeFlow(props)
        case flowOptions.USER_FIELDS:
            return initUserFieldsSettingsFlow(props)
        case flowOptions.SEND_LINK_TO_MEMBERSHIP_FLOW:
            return initSendLinkToMembershipFlow(props)
        case flowOptions.CONNECT_USER_TO_GROUP:
            return initConnectUserToGroupFlow(props)
        case flowOptions.SITE_TRIAL_SETTINGS:
            return initSiteTrialSettingsFlow(props)
        case flowOptions.BOOK_TO_HUG_TRIAL:
            return initBookToHugTrial(props)
        case flowOptions.GATES_ACTIVITY_HOURS:
            return initGatesActivityHoursFlow(props)
        case flowOptions.BOOK_TO_HUG_CONVERT_LEAD:
            return initBookToHugConvertLead(props)
        default:
            return {steps: [optionalSteps.EVENT_TYPE], name: '', currStep: optionalSteps.EVENT_TYPE}
    }
}

const initGroupClassFlow = (editType) => {
    let steps;
    if(editType) {
        steps = [optionalSteps.BASIC_INFO, optionalSteps.REPEAT_CONFIG, optionalSteps.SETTINGS, optionalSteps.SUCCESS_PAGE]
    } else {
        steps = [optionalSteps.EVENT_TYPE, optionalSteps.BASIC_INFO, optionalSteps.REPEAT_CONFIG, optionalSteps.SETTINGS, optionalSteps.SUCCESS_PAGE]
    }
    return {
        steps: steps,
        name: flowOptions.GROUP_CLASS_FLOW,
        currStep: optionalSteps.BASIC_INFO,
        selectionsTree: {[optionalSteps.EVENT_TYPE]: 'class'},
        editType: editType,
    }
}


const initAppointmentOrAvailabilityFlow = (selection, editType, target) => {
    const selectionsTree = store.getState().stepper.selectionsTree
    if(selectionsTree[optionalSteps.APPOINTMENT_OR_AVAILABILITY_STEP] || selection === flowOptions.APPOINTMENT_BASIC) {
        // real appointment flow
        const steps = [optionalSteps.EVENT_TYPE, optionalSteps.APPOINTMENT_OR_AVAILABILITY_STEP, optionalSteps.BASIC_INFO, optionalSteps.REPEAT_CONFIG, optionalSteps.USER_AND_MEMBERSHIP_SELECTION, optionalSteps.SUCCESS_PAGE]
        return {
            steps: steps,
            name: flowOptions.APPOINTMENT_BASIC,
            currStep: optionalSteps.BASIC_INFO,
        }
    } else if (editType)  {
        if(target.availability_id) {
            return initAppointmentWithAvailabilityFlow(editType, target.spaces_id)
        }
        return {
            steps: [optionalSteps.BASIC_INFO, optionalSteps.REPEAT_CONFIG, optionalSteps.SUCCESS_PAGE],
            name: flowOptions.APPOINTMENT_BASIC,
            currStep: optionalSteps.BASIC_INFO,
            selectionsTree: {[optionalSteps.EVENT_TYPE]: 'appointment'},
            editType: editType,
        }
    } else {
        let steps = [optionalSteps.EVENT_TYPE, optionalSteps.APPOINTMENT_OR_AVAILABILITY_STEP]
        return {
            steps: steps,
            name: flowOptions.APPOINTMENT_OR_AVAILABILITY,
            currStep: optionalSteps.APPOINTMENT_OR_AVAILABILITY_STEP,
            selectionsTree: {[optionalSteps.EVENT_TYPE]: 'appointment'},
        }
    }
}

const initAppointmentWithAvailabilityFlow = (editType = null, isSpaceAvailability = false) => {
    let steps = [optionalSteps.BASIC_INFO, optionalSteps.AVAILABILITY_CONFIG, optionalSteps.USER_AND_MEMBERSHIP_SELECTION, optionalSteps.SUCCESS_PAGE]
    if(editType) {
        steps = [optionalSteps.BASIC_INFO, optionalSteps.AVAILABILITY_CONFIG, optionalSteps.SUCCESS_PAGE]
    }
    return {
        steps: steps,
        name: isSpaceAvailability ? flowOptions.APPOINTMENT_WITH_SPACE_AVAILABILITY : flowOptions.APPOINTMENT_WITH_AVAILABILITY,
        currStep: optionalSteps.BASIC_INFO,
        editType: editType,
    }
}


const initStaffMember = (props) => {
    return {
        steps: [optionalSteps.MEMBER_DETAILS, optionalSteps.STAFF_MEMBER_ROLE_SELECT],
        name: flowOptions.STAFF_MEMBER,
        currStep: optionalSteps.MEMBER_DETAILS,
        props
    }
}

const initLead = (props) => {
    return {
        steps: [optionalSteps.MEMBER_DETAILS],
        name: flowOptions.LEAD,
        currStep: optionalSteps.MEMBER_DETAILS,
        props
    }
}

const initEmailAfterPurchase = (props) => {
    return {
        steps: [optionalSteps.EMAIL_AFTER_PURCHASE],
        name: flowOptions.EMAIL_AFTER_PURCHASE,
        currStep: optionalSteps.EMAIL_AFTER_PURCHASE,
        props
    }
}

const initWorkshopFlow = (editType) => {
    let steps;
    if(editType) {
        steps = [optionalSteps.BASIC_INFO, optionalSteps.EDIT_MULTI_DAY_CONFIG, optionalSteps.SETTINGS, optionalSteps.SUCCESS_PAGE]
    } else {
        steps = [optionalSteps.EVENT_TYPE, optionalSteps.BASIC_INFO, optionalSteps.MULTI_DAY_CONFIG, optionalSteps.SETTINGS, optionalSteps.SUCCESS_PAGE]
    }
    return {
        steps: steps,
        name: flowOptions.WORKSHOP_FLOW,
        currStep: optionalSteps.BASIC_INFO,
        selectionsTree: {[optionalSteps.EVENT_TYPE]: 'workshop'},
        editType: editType,
    }
}

const initHugimFlow = (editType) => {
    let steps;
    if(editType === SERIES_FUTURE_SESSIONS_EDIT_TYPE) {
        steps = [optionalSteps.BASIC_INFO, optionalSteps.EDIT_MULTI_DAY_CONFIG, optionalSteps.SERIES_PAYMENT_OPTIONS, optionalSteps.SETTINGS]
    } else if(editType === SINGLE_SESSION_EDIT_TYPE) {
        steps = [optionalSteps.BASIC_INFO, optionalSteps.EDIT_MULTI_DAY_CONFIG, optionalSteps.SETTINGS]
    } else {
        steps = [optionalSteps.EVENT_TYPE, optionalSteps.BASIC_INFO, optionalSteps.MULTI_DAY_CONFIG, optionalSteps.SERIES_PAYMENT_OPTIONS, optionalSteps.SETTINGS, optionalSteps.SUCCESS_PAGE]
    }
    return {
        steps: steps,
        name: flowOptions.HUGIM_FLOW,
        currStep: optionalSteps.BASIC_INFO,
        selectionsTree: {[optionalSteps.EVENT_TYPE]: 'hugim'},
        editType: editType,
    }
}

const initAvailabilityFlow = (editType) => {
    let steps;
    let firstStep;
    if(editType) {
        steps = [optionalSteps.EDIT_MULTI_DAY_CONFIG, optionalSteps.SUCCESS_PAGE]
        firstStep = optionalSteps.EDIT_MULTI_DAY_CONFIG;
    } else {
        steps = [optionalSteps.EVENT_TYPE, optionalSteps.APPOINTMENT_OR_AVAILABILITY_STEP, optionalSteps.MULTI_DAY_CONFIG, optionalSteps.SUCCESS_PAGE]
        firstStep = optionalSteps.MULTI_DAY_CONFIG;
    }
    return {
        steps: steps,
        name: flowOptions.AVAILABILITY,
        currStep: firstStep,
        selectionsTree: {[optionalSteps.EVENT_TYPE]: 'appointment', [optionalSteps.APPOINTMENT_OR_AVAILABILITY_STEP]: 'availability'},
        editType: editType,
    }
}

const initBlockedTime = (editType) => {
    return {
        steps: [optionalSteps.BLOCKED_TIME],
        name: flowOptions.BLOCKED_TIME,
        currStep: optionalSteps.BLOCKED_TIME,
        selectionsTree: {[optionalSteps.EVENT_TYPE]: 'blocked'},
        editType,
    }
}

const initMessage = ({target, ...rest}) => {
    return {
        steps: [optionalSteps.MESSAGE_TYPE, optionalSteps.MESSAGE_DETAILS],
        name: flowOptions.MESSAGE,
        currStep: target?.selectedType ? optionalSteps.MESSAGE_DETAILS : optionalSteps.MESSAGE_TYPE,
        props: {target, ...rest}
    }
}

const initSpaceFlow = () => {
    return {
        steps: [optionalSteps.SPACE_CONFIGURATION, optionalSteps.SUCCESS_PAGE],
        name: flowOptions.SPACE_FLOW,
        currStep: optionalSteps.SPACE_CONFIGURATION,
    }
}

const initSpaceAvailabilityFlow = (editType) => {
    let steps;
    let firstStep;
    if(editType) {
        steps = [optionalSteps.EDIT_MULTI_DAY_CONFIG, optionalSteps.SUCCESS_PAGE]
        firstStep = optionalSteps.EDIT_MULTI_DAY_CONFIG;
    } else {
        steps = [optionalSteps.MULTI_DAY_CONFIG, optionalSteps.SUCCESS_PAGE]
        firstStep = optionalSteps.MULTI_DAY_CONFIG;
    }
    return {
        steps: steps,
        name: flowOptions.SPACE_AVAILABILITY,
        currStep: firstStep,
        editType: editType
    }
}

const initPenaltyFlow = ({target, all}) => {
    let steps = [optionalSteps.PENALTY_SETTINGS, optionalSteps.PENALTY_EXTRA_PROPERTIES, optionalSteps.PENALTY_SUMMARY];
    if (target) {
        steps.splice(1, 1)
    }
    return {
        steps: steps,
        name: flowOptions.PENALTY_FLOW,
        currStep: optionalSteps.PENALTY_SETTINGS,
        props: {target, all}
    }
}

const initTaskFlow = (props) => {
    return {
        steps: [optionalSteps.TASK_DETAILS],
        name: flowOptions.TASK_FLOW,
        currStep: optionalSteps.TASK_DETAILS,
        props
    }
}

const initTaskTypeFlow = (props) => {
    return {
        steps: [optionalSteps.TASK_TYPE],
        name: flowOptions.TASK_TYPE_FLOW,
        currStep: optionalSteps.TASK_TYPE,
        props
    }
}

const initConvertLinkFlow = (props) => {
    return {
        steps: [optionalSteps.SEND_CONVERT_LINK],
        name: flowOptions.CONVERT_LINK_FLOW,
        currStep: optionalSteps.SEND_CONVERT_LINK,
    }
}

const initCustomFieldFlow = (props = {}) => {
    let steps = [optionalSteps.CUSTOM_FIELD_TYPE_SELECTION, optionalSteps.CUSTOM_FIELD_SETTINGS];
    let selectionsTree = {};
    let firstStep = steps[0];
    let editType = null;
    if(props.target) {
        steps = [optionalSteps.CUSTOM_FIELD_SETTINGS]
        firstStep = optionalSteps.CUSTOM_FIELD_SETTINGS
        editType = true;
    } else if(props.flow_type) {
        selectionsTree[optionalSteps.CUSTOM_FIELD_TYPE_SELECTION] = props.flow_type
        firstStep = optionalSteps.CUSTOM_FIELD_SETTINGS
    }
    return {
        steps: steps,
        name: flowOptions.CUSTOM_FIELD,
        currStep: firstStep,
        editType: editType,
        selectionsTree
    }
}

const initBookToHugFlow = (props = {}) => {
    const paymentOptions = props.target.eventData.series.membership_types_all.filter(mt => [MembershipTypes.REGULAR, MembershipTypes.RECURRING].includes(mt.type))
    const isOneMembership = paymentOptions.length === 1
    let steps = isOneMembership ? [optionalSteps.PAYMENT] : [optionalSteps.CHOOSE_HUG_PAYMENT_TYPE, optionalSteps.PAYMENT]
    steps = props.user ? steps : [optionalSteps.PERSONAL_DETAILS, ...steps]
    return {
        steps,
        name: flowOptions.BOOK_TO_HUG_FLOW,
        currStep: steps[0],
        selectionsTree: isOneMembership ? {[optionalSteps.CHOOSE_HUG_PAYMENT_TYPE]: {...paymentOptions[0], start: props.target.eventData.date}} : {},
        props
    }
}

const initUserFieldsSettingsFlow = (props) => {
    return {
        steps: [optionalSteps.REQUIRED_FIELDS_SETTINGS],
        name: flowOptions.USER_FIELDS,
        currStep: optionalSteps.REQUIRED_FIELDS_SETTINGS,
        props
    }
}

const initSendLinkToMembershipFlow = (props) => {
    return {
        steps: [optionalSteps.SEND_ONE_TIME_MEMBERSHIP_LINK],
        name: flowOptions.SEND_LINK_TO_MEMBERSHIP_FLOW,
        currStep: optionalSteps.SEND_ONE_TIME_MEMBERSHIP_LINK,
        props
    }
}

const initConnectUserToGroupFlow = (props) => {
    return {
        steps: [optionalSteps.CHOOSE_GROUP_CONNECTION_USER, optionalSteps.GROUP_CONNECTION_ADD_USER],
        name: flowOptions.CONNECT_USER_TO_GROUP,
        currStep: optionalSteps.CHOOSE_GROUP_CONNECTION_USER,
        props
    }
}

const initSiteTrialSettingsFlow = (props) => {
    return {
        steps: [optionalSteps.SITE_TRIAL_SETTINGS],
        name: flowOptions.SITE_TRIAL_SETTINGS,
        currStep: optionalSteps.SITE_TRIAL_SETTINGS,
    }
}

const initBookToHugTrial = (props) => {
    return {
        steps: [optionalSteps.PAYMENT],
        name: flowOptions.BOOK_TO_HUG_TRIAL,
        currStep: optionalSteps.PAYMENT,
        props
    }
}

const initBookToHugConvertLead = (props) => {
    const paymentOptions = props.target.eventData.series.membership_types_all.filter(mt => [MembershipTypes.REGULAR, MembershipTypes.RECURRING].includes(mt.type))
    const isOneMembership = paymentOptions.length === 1
    let steps = isOneMembership ? [optionalSteps.PAYMENT] : [optionalSteps.CHOOSE_HUG_PAYMENT_TYPE, optionalSteps.PAYMENT]
    steps = [optionalSteps.LEAD_TO_CONVERT_PERSONAL_DETAILS, ...steps]
    return {
        steps,
        name: flowOptions.BOOK_TO_HUG_CONVERT_LEAD,
        currStep: optionalSteps.LEAD_TO_CONVERT_PERSONAL_DETAILS,
        selectionsTree: isOneMembership ? {[optionalSteps.CHOOSE_HUG_PAYMENT_TYPE]: {...paymentOptions[0], start: props.target.date}} : {},
        props
    }
}

const initGatesActivityHoursFlow = (props) => {
    const locations = store.getState().box.box.locations_box;
    const gates = locations.map(location => location.gates).flat().filter(gate => !gate.is_exit);
    if (gates.length > 1) {
        return {
            steps: [optionalSteps.GATES_APPLY_SETTINGS ,optionalSteps.GATES_ACTIVITY_HOURS],
            name: flowOptions.GATES_ACTIVITY_HOURS,
            currStep: optionalSteps.GATES_APPLY_SETTINGS,
            props
        }
    }
    return {
        steps: [optionalSteps.GATES_ACTIVITY_HOURS],
        name: flowOptions.GATES_ACTIVITY_HOURS,
        currStep: optionalSteps.GATES_ACTIVITY_HOURS,
        props
    }
}

export const updateOneTimePurchaseInFlow = (selection) => {
    const flowConfig = store.getState().stepper
    switch (selection) {
        case ONE_TIME_PURCHASE:
            let newSteps = [...flowConfig.steps];
            newSteps.splice(newSteps.length - 1, 0, optionalSteps.PAYMENT_METHOD_SELECTION)
            return {steps: newSteps}
        case EXISTING_MEMBERSHIP:
        case NO_MEMBERSHIP:
            return {steps: [...flowConfig.steps.filter(step => step !== optionalSteps.PAYMENT_METHOD_SELECTION)]}
    }
}

export const getFlowConfirm = (params) => {
    const flowConfig = store.getState().stepper.name
    switch (flowConfig) {
        case flowOptions.MESSAGE:
            let subheader = t('about-to-send-this-message');
            if (params.specificTarget) {
                subheader = t('about-to-send-this-message-to-x-recipients', {number: params.users.filter(u => u.isSelected).length});
            }
            if (params.class_name === 'PrivateMessage') {
                subheader = t('about-to-send-this-message-to-user', {user: `${params.users[0].first_name || ''} ${params.users[0].last_name || ''}`});
            }
            return {subheader, header: t('confirm-number-of-recipients')}
        case flowOptions.APPOINTMENT_BASIC:
        case flowOptions.APPOINTMENT_WITH_SPACE_AVAILABILITY:
            if((params?.scheduleUser?.membershipUserIsActive === 0 && params?.scheduleUser?.membership_user_fk) && !params?.scheduleUser?.isUserActive && !params?.scheduleUser.force) {
                return {header: t('areYouSure'), subheader: t('bookToHugInactiveUserError')}
            }
            else return false;
        default:
            return null
    }


}

export const saveFlow = async (dispatch, values, target) => {
    const flowConfig = store.getState().stepper.name
    const isEdit = store.getState().stepper.editType
    let params = values
    console.log('saveFlow', target)
    if(isEdit) {
        params = {...values, id: target.id, series_id: target.series_fk, editType: isEdit, target}
    }

    switch (flowConfig) {
        case flowOptions.GROUP_CLASS_FLOW:
        case flowOptions.APPOINTMENT_BASIC:
        case flowOptions.WORKSHOP_FLOW:
        case flowOptions.HUGIM_FLOW:
        case flowOptions.BLOCKED_TIME:
            return dispatch(isEdit ? updateEvent(params) : createEvent(params))
        case flowOptions.APPOINTMENT_WITH_AVAILABILITY:
        case flowOptions.APPOINTMENT_WITH_SPACE_AVAILABILITY:
            if(!isEdit) return dispatch(createEvent(params))
            else if(isEdit && (values.date !== target.date || values.time !== target.time || values.end_time !== target.end_time)) {
                return dispatch(rescheduleAppointment(params))
            } else return
        case flowOptions.AVAILABILITY:
        case flowOptions.SPACE_AVAILABILITY:
            return dispatch(isEdit ? updateAvailability(params) : createAvailabilities(params))
        case flowOptions.STAFF_MEMBER:
        case flowOptions.LEAD:
            return saveUser(flowConfig, dispatch, params, target)
        case flowOptions.MESSAGE:
            return sendMessage(params, dispatch)
        case flowOptions.EMAIL_AFTER_PURCHASE:
            return saveEmailAfterPurchase(params, dispatch);
        case flowOptions.SPACE_FLOW:
            return dispatch(createSpace(params))
        case flowOptions.PENALTY_FLOW:
            return savePenalty(params, dispatch);
        case flowOptions.TASK_FLOW:
            return saveTask(params, dispatch)
        case flowOptions.CONVERT_LINK_FLOW:
            return sendConvertLink(params, dispatch)
        case flowOptions.CUSTOM_FIELD:
            return saveCustomField(params, dispatch)
        case flowOptions.BOOK_TO_HUG_FLOW:
            return bookToHug(params, dispatch)
        case flowOptions.TASK_TYPE_FLOW:
            return saveCreateTaskType(params, dispatch)
        case flowOptions.USER_FIELDS:
            return saveBoxSettingsFlow(USER_FIELDS, params, dispatch)
        case flowOptions.SEND_LINK_TO_MEMBERSHIP_FLOW:
            return sendItemLink(params, dispatch)
        case flowOptions.CONNECT_USER_TO_GROUP:
            return connectUserToGroup(params, dispatch)
        case flowOptions.SITE_TRIAL_SETTINGS:
            delete params.flowName
            return saveBoxSettingsFlow(SITE_TRIAL_SETTINGS, {properties: params}, dispatch)
        case flowOptions.BOOK_TO_HUG_TRIAL:
            return bookForTrialHug(params, dispatch)
        case flowOptions.BOOK_TO_HUG_CONVERT_LEAD:
            return convertLeadAndBookToHug(params, dispatch)
        case flowOptions.GATES_ACTIVITY_HOURS:
            return saveActivityHours(params, dispatch)
        default:
            return
    }
}

export const getSuccessProps = ({resetForm, dispatch, navigate, values}) => {
    const flowConfig = store.getState().stepper.name
    switch (flowConfig) {
        case flowOptions.GROUP_CLASS_FLOW:
            return getGroupClassSuccessProps(resetForm, dispatch, navigate)
        case flowOptions.APPOINTMENT_BASIC:
        case flowOptions.APPOINTMENT_WITH_AVAILABILITY:
        case flowOptions.APPOINTMENT_WITH_SPACE_AVAILABILITY:
            return getAppointmentSuccessProps(resetForm, dispatch, navigate)
        case flowOptions.AVAILABILITY:
            return getAvailabilitySuccessProps(resetForm, dispatch, navigate, flowOptions.AVAILABILITY)
        case flowOptions.SPACE_AVAILABILITY:
            return getAvailabilitySuccessProps(resetForm, dispatch, navigate, flowOptions.SPACE_AVAILABILITY)
        case flowOptions.WORKSHOP_FLOW:
            return getWorkshopSuccessProps(resetForm, dispatch, navigate)
        case flowOptions.STAFF_MEMBER:
            return getStaffMemberSuccessProps(resetForm, dispatch, navigate)
        case flowOptions.LEAD:
            return getLeadSuccessProps(resetForm, dispatch, navigate)
        case flowOptions.SPACE_FLOW:
            return getSpaceSuccessProps(resetForm, dispatch, navigate)
        case flowOptions.BOOK_TO_HUG_FLOW:
            return getBookToHugSuccessProps(values, resetForm, dispatch, navigate)
        case flowOptions.BOOK_TO_HUG_TRIAL:
            return getBookToHugTrialSuccessProps(values, resetForm, dispatch, navigate)
        case flowOptions.BOOK_TO_HUG_CONVERT_LEAD:
            return getBookToHugSuccessProps(values, resetForm, dispatch, navigate, true)
        default:
            return
    }
}

const getAppointmentSuccessProps = (resetForm, dispatch, navigate) => {
    const isEdit = store.getState().stepper.editType

    return {
        header: t(isEdit ? 'edit-success-header' : 'appointment-success-header'),
        whatsNextArray: [
            {
                id: 'add-availability',
                iconName: 'fa-light fa-calendar-circle-plus',
                header: t('add-a-availability'),
                headerAddOns: null,
                subheader: t('add-availability-explain'),
                onPress: () => {
                    sendDataEvent('click define availability What’s Next');
                    resetForm()
                    dispatch(cleanStepper())
                    dispatch(addToSelectionsTree({[optionalSteps.EVENT_TYPE]: 'appointment', [optionalSteps.APPOINTMENT_OR_AVAILABILITY_STEP]: 'availability'}))
                }
            },
            {
                id: 'schedule-settings',
                iconName: 'fa-light fa-gear',
                header: t('scheduleSettingsLbl'),
                headerAddOns: null,
                subheader: t('schedule-settings-explain'),
                onPress: () => {
                    sendDataEvent('click schedule Settings What’s Next');
                    resetAndClose(resetForm, dispatch)
                    dispatch(openModal({modalName: SCHEDULE_SETTINGS_MODAL, props: {initOptionSelection: 'cancellation'}}))
                }
            },
            {
                id: 'add-service',
                iconName: 'fa-light fa-grid-2',
                header: t('create-service-type'),
                headerAddOns: null,
                subheader: t('create-appointment-service-type-explain'),
                onPress: () => {
                    sendDataEvent('click create service type what next');
                    resetAndClose(resetForm, dispatch)
                    dispatch(openModal({modalName: BOOKING_SERVICES_DISPLAY_MODAL, props: {defaultTab: APPOINTMENT}}))
                }
            },
        ],
        children: getSuccessChildren(t(`appointment${isEdit ? '-edit' : ''}-cta-success-part1`))
    }
}

const getAvailabilitySuccessProps = (resetForm, dispatch, navigate, availabilityType) => {
    const isEdit = store.getState().stepper.editType
    const isTrial = store.getState().box.box.trial_mode?.active
    const box = store.getState().box.box

    const clientApp = {
        id: 'client-app',
        iconName: 'fa-light fa-mobile-notch',
        header: t('my-client-app'),
        headerAddOns: null,
        subheader: t('client-app-availability-explain'),
        onPress: () => openCreateUserPopup(resetForm, dispatch, navigate)
    }
    const serviceType = {
        id: 'add-service',
        iconName: 'fa-light fa-grid-2',
        header: t('create-service-type'),
        headerAddOns: null,
        subheader: t('create-service-type-explain'),
        onPress: () => {
            sendDataEvent('create service type what next');
            resetAndClose(resetForm, dispatch)
            dispatch(openModal({modalName: BOOKING_SERVICES_DISPLAY_MODAL, props: {defaultTab: APPOINTMENT}}))
        }
    }
    const scheduleLink = {
        id: 'schedule-link',
        iconName: 'fa-light fa-calendar',
        header: t('my-schedule-link'),
        headerAddOns: null,
        subheader: t('schedule-link-availability-explain'),
        onPress: () => {
            window.open(generateSiteLink('schedule', {referer: 'SITE', scheduleType: 'appointment'}, true), '_blank')
        }
    }
    const membership = {
        id: 'settings-memberships-tab',
        iconName: 'fa-light fa-id-card',
        header: t('create-membership-type'),
        headerAddOns: null,
        subheader: t('create-membership-type-explain'),
        onPress: () => {
            sendDataEvent('click create membership type what next');
            resetAndClose(resetForm, dispatch)
            navigate('/settings?tab=2')
        }
    }
    return {
        header: t(isEdit ? 'edit-success-header' : 'availability-success-header'),
        whatsNextArray: isTrial ? [scheduleLink, clientApp, serviceType] : [serviceType, clientApp, membership],
        children: getSuccessChildren(t('availability-cta-success-part1'), !!isEdit),
        link: {text: t('copy-link-availability'), onPress: (toast) => {
                const siteLink = generateSiteLink('schedule', {scheduleType: 'appointment', referer: 'SITE' ,availabilityType: availabilityType === flowOptions.SPACE_AVAILABILITY && 'SPACE_AVAILABILITY_TYPE'})
                return shareOrCopySiteLink(toast, siteLink)
            },icon: 'fa-link'}
    }
}

const getGroupClassSuccessProps = (resetForm, dispatch, navigate) => {
    const isEdit = store.getState().stepper.editType
    const isTrial = store.getState().box.box.trial_mode?.active
    const clientApp = {
        id: 'client-app',
        iconName: 'fa-light fa-mobile-notch',
        header: t('my-client-app'),
        headerAddOns: null,
        subheader: t('client-app-class-explain'),
        onPress: () => openCreateUserPopup(resetForm, dispatch, navigate)
    }
    const settingsMemberships = {
        id: 'settings-memberships-tab',
        iconName: 'fa-light fa-id-card',
        header: t('create-membership-type'),
        headerAddOns: null,
        subheader: t('create-membership-type-explain'),
        onPress: () => {
            sendDataEvent('click create membership type what next');
            resetAndClose(resetForm, dispatch)
            navigate('/settings?tab=2')
        }
    }
    const scheduleLink = {
        id: 'schedule-link',
        iconName: 'fa-light fa-calendar',
        header: t('my-schedule-link'),
        headerAddOns: null,
        subheader: t('schedule-link-class-explain'),
        onPress: () => openSiteLink(resetForm, dispatch, navigate)
    }
    const scheduleSettings = {
        id: 'schedule-settings',
        iconName: 'fa-light fa-gear',
        header: t('scheduleSettingsLbl'),
        headerAddOns: null,
        subheader: t('schedule-settings-explain'),
        onPress: () => {
            sendDataEvent('click schedule Settings What’s Next');
            resetAndClose(resetForm, dispatch)
            dispatch(openModal({modalName: SCHEDULE_SETTINGS_MODAL, props: {initOptionSelection: 'cancellation'}}))
        }
    }
    return {
        header: t(isEdit ? 'edit-success-header' : 'class-success-header'),
        whatsNextArray: isTrial ? [clientApp, scheduleLink, settingsMemberships,] : [settingsMemberships, scheduleSettings, clientApp],
        children: getSuccessChildren(isEdit ? t('class-edit-cta-success-part1') : null)
    }
}

const getWorkshopSuccessProps = (resetForm, dispatch, navigate) => {
    const isEdit = store.getState().stepper.editType
    const box = store.getState().box.box

    return {
        header: t(isEdit ? 'edit-success-header' : 'workshop-success-header'),
        whatsNextArray: [
            {
                id: 'schedule-link',
                iconName: 'fa-light fa-calendar',
                header: t('my-schedule-link'),
                headerAddOns: null,
                subheader: t('schedule-link-workshop-explain'),
                onPress: () => openSiteLink(resetForm, dispatch, navigate)
            },
            {
                id: 'workshop-report',
                iconName: 'fa-light fa-chart-simple',
                header: t('workshop-success-reports-analytics'),
                headerAddOns: null,
                subheader: t('workshop-success-reports-analytics-explain'),
                onPress: () => {
                    resetAndClose(resetForm, dispatch)
                    navigate('/reports-v5/workshops-bookings-report')
                }
            },
        ],
        children: getSuccessChildren(null, isEdit),
        link: {text: t('copy-link-workshop'), onPress: (toast) => {
                const siteLink = generateSiteLink('schedule', {scheduleType: 'workshops', referer: 'SITE'})
                return shareOrCopySiteLink(toast, siteLink)
            },icon: 'fa-link'}
    }
}

const getStaffMemberSuccessProps = (resetForm, dispatch, navigate) => {
    return {
        header: t('staffAddedSuccessfully'),
        whatsNextArray: [
            {
                id: 'create-task',
                iconName: 'fa-light fa-clipboard-list',
                header: t('create-a-task-staff-title'),
                headerAddOns: null,
                subheader: t('create-a-task-staff-text'),
                permission: 'addNewTask',
                onPress: () => {
                    resetAndClose(resetForm, dispatch)
                    openStepperModal({ dispatch, modalName: CREATE_EVENT_MODAL,
                        props: { initSelection: flowOptions.TASK_FLOW, addAnother: true, navigate: () => {}, target: null},
                        onClose: () => dispatch(cleanStepper())})
                }
            },
            {
                id: 'create-session',
                iconName: 'fa-light fa-calendar-circle-plus',
                header: t('create-a-session-title'),
                headerAddOns: null,
                subheader: t('create-a-session-staff-text'),
                onPress: () => {
                    resetAndClose(resetForm, dispatch)
                    navigate('/calendar-v5?type=schedule&funnelName=createEventModal')
                }
            },
            {
                id: 'track-staff-action',
                iconName: 'fa-light fa-chart-simple',
                header: t('track-staff-actions-title'),
                headerAddOns: null,
                subheader: t('track-staff-actions-text'),
                permission: 'viewStaffActions',
                onPress: () => {
                    resetAndClose(resetForm, dispatch)
                    navigate('/reports-v5/staff-actions-report')
                }
            },
        ],
        children: getUserCreatationSuccessChildren('staff-has-been-added', resetForm, dispatch, navigate)
    }
}

const getLeadSuccessProps = (resetForm, dispatch, navigate) => {
    return {
        header: t('leadAddedSuccessfully'),
        whatsNextArray: [
            {
                id: 'create-task',
                iconName: 'fa-light fa-calendar',
                header: t('book-trial-session-title'),
                headerAddOns: null,
                subheader: t('book-trial-session-text'),
                onPress: () => {
                    resetAndClose(resetForm, dispatch)
                    navigate('/calendar')
                }
            },
            {
                id: 'create-session',
                iconName: 'fa-light fa-paper-plane',
                header: t('communicate-title'),
                headerAddOns: null,
                subheader: t('communicate-text'),
                onPress: () => {
                    resetAndClose(resetForm, dispatch)
                    navigate('/settings?tab=5&action=create-auto-message')
                }
            },
            {
                id: 'track-staff-action',
                iconName: 'fa-light fa-chart-simple',
                header: t('view-lead-statistics-title'),
                headerAddOns: null,
                subheader: t('view-lead-statistics-text'),
                onPress: () => {
                    resetAndClose(resetForm, dispatch)
                    navigate('/reports/leads-statistics-report')
                }
            },
        ],
        children: getUserCreatationSuccessChildren('lead-has-been-added', resetForm, dispatch, navigate)
    }
}


const getSpaceSuccessProps = (resetForm, dispatch, navigate) => {
    return {
        header: t('space-success-header'),
        whatsNextArray: [
            {
                id: 'add-service',
                iconName: 'fa-light fa-grid-2',
                header: t('create-service-type'),
                headerAddOns: null,
                subheader: t('create-service-type-explain'),
                onPress: () => {
                    sendDataEvent('click create service type what next');
                    resetAndClose(resetForm, dispatch)
                    dispatch(openModal({modalName: BOOKING_SERVICES_DISPLAY_MODAL, props: {defaultTab: APPOINTMENT}}))
                }
            },
            {
                id: 'add-class',
                iconName: 'fa-light fa-calendar-circle-plus',
                header: t('create-session'),
                headerAddOns: null,
                subheader: t('create-session-explain'),
                onPress: () => {
                    sendDataEvent('click create event What’s Next');
                    resetForm()
                    dispatch(cleanStepper())
                    let config = initFlowConfig({})
                    if(config) dispatch(updateStepper(config))
                }
            },
            {
                id: 'schedule-link',
                iconName: 'fa-light fa-calendar',
                header: t('my-schedule-link'),
                headerAddOns: null,
                subheader: t('schedule-link-class-explain'),
                onPress: () => openSiteLink(resetForm, dispatch, navigate)
            },
        ],
        children: getSuccessChildren() //TODO: CHANGE THIS WHEN U FIGURE OUT WHAT TEXT SHOULD BE IN THE SUBHEADER
    }
}

const getBookToHugSuccessProps = (values, resetForm, dispatch, navigate, bookConvertedLead = false) => {
    const newUser = store.getState().userFlow.user
    console.log('getBookToHugSuccessProps values', values)
    const bookToHugWithCreateUser = !store.getState().stepper.props.user
    const user = bookToHugWithCreateUser ? {...values, full_name: values.first_name + ' ' + values.last_name, user_fk: newUser.user_fk} : store.getState().stepper.props.user
    const hug = store.getState().stepper.props.target.eventData.series
    const scheduleDate = store.getState().stepper.props.target.eventData.date
    const currency_symbol = store.getState().userSettings.scheduleLocation.currency_symbol
    const locale = getLocale();

    const startDateStr = moment(scheduleDate, 'YYYY-MM-DD').locale(locale).format(locale === 'he' ? 'DD MMMM, YYYY' : 'MMMM DD, YYYY')
    const endDateStr = hug.end_date ? moment(hug.end_date, 'YYYY-MM-DD').locale(locale).format(locale === 'he' ? 'DD MMMM, YYYY' : 'MMMM DD, YYYY') : null
    const paymentTypeInfo = values.payment_info.selectedPaymentType
    const isRecurring = paymentTypeInfo.type === 'PAY_NOW' && paymentTypeInfo.isRecurring

    let overrideSummaryKey
    if(bookToHugWithCreateUser) {
        overrideSummaryKey = 'hug-booking-and-create-user-success-summary'
    } else if(bookConvertedLead) {
        overrideSummaryKey = 'hug-booking-converted-lead-success-summary'
    } else {
        overrideSummaryKey = 'hug-booking-success-summary'
    }

    return {
        header: t('hug-booking-success-header'),
        children: getSuccessChildren(
            t(`hug-success-payment-explain-${paymentTypeInfo.type}${isRecurring ? '-HOK' : ''}`, {price: `${currency_symbol}${paymentTypeInfo.price?.toLocaleString()}`}),
            false,
            t(overrideSummaryKey, {userName: user.full_name, seriesName: hug.series_name, dates: endDateStr ? `${startDateStr} - ${endDateStr}` : startDateStr, interpolation: {'escapeValue': false}}),
            {dkira: `${t('you-can-see-members-profile')} `, user, resetForm, dispatch, navigate, onClose: () => {
                dispatch(cleanStepper());
                dispatch(closeModal({ modalName: CREATE_EVENT_MODAL}));
                dispatch(hideModal({ modalName: EVENT_MANAGEMENT_MODAL}));
                dispatch(closeModal({ modalName: EVENT_MANAGEMENT_MODAL}));
            }}
        ),
    }
}

const getBookToHugTrialSuccessProps = (values, resetForm, dispatch, navigate) => {
    const currency_symbol = store.getState().userSettings.scheduleLocation.currency_symbol
    const user = store.getState().stepper.props.user
    const scheduleDate = store.getState().stepper.props.target.eventData
    const hug = store.getState().stepper.props.target.eventData.series
    const locale = getLocale();
    const dateStr = moment(scheduleDate.date, 'YYYY-MM-DD').locale(locale).format(locale === 'he' ? 'Do MMM' : 'MMM Do')
    const timeStr = `${scheduleDate.time} - ${scheduleDate.end_time}`
    const paymentTypeInfo = values.payment_info.selectedPaymentType

    return {
        header: t('hug-trial-booking-success-header'),
        children: getSuccessChildren(
            t(`hug-success-payment-explain-${paymentTypeInfo.type}`, {price: `${currency_symbol}${paymentTypeInfo.price?.toLocaleString()}`}),
            false,
            t('hug-trial-booking-success-summary', {userName: user.full_name, seriesName: hug.series_name, date: dateStr, time: timeStr, interpolation: {'escapeValue': false}}),
            {dkira: `${t('you-can-see-members-profile')} `, user, resetForm, dispatch, navigate, onClose: () => {
                    dispatch(cleanStepper());
                    dispatch(closeModal({ modalName: CREATE_EVENT_MODAL}));
                    dispatch(hideModal({ modalName: EVENT_MANAGEMENT_MODAL}));
                    dispatch(closeModal({ modalName: EVENT_MANAGEMENT_MODAL}));
                }}
        )
    }
}

const getSuccessChildren = (dkiraPart1 = null, hideBottom = false, overrideSummary = null, userProfileProps = null) => {
    const {summary, selectionsTree} = store.getState().stepper
    return (
        <VStack space={'1rem'}>
            <AppText fontSize={GlobalStyleAttributes.fonts.standard16} defaultColor>{overrideSummary ?? summary}</AppText>
            {userProfileProps && getViewUserProfile(userProfileProps)}
            {!hideBottom && <AppText fontSize={GlobalStyleAttributes.fonts.standard16} defaultColor>{dkiraPart1 ?? t(`${selectionsTree[optionalSteps.EVENT_TYPE] ?? 'class'}-cta-success-part1`)} </AppText>}
        </VStack>
    )
}

const getUserCreatationSuccessChildren = (dkira, resetForm, dispatch, navigate) => {
    const user = store.getState().userFlow.user;
    return getViewUserProfile({user, dkira: `${user?.full_name} ${t('was-successfully-added')}. ${t(dkira)} `, resetForm, dispatch, navigate})
}

const getViewUserProfile = ({user, dkira, resetForm, dispatch, navigate, onClose}) => {
    const onUserClick = () => {
        resetAndClose(resetForm, dispatch, onClose)
        navigate('/user-profile/' + user?.user_fk);
    }
    return (
        <span>
            <AppText>{dkira}</AppText>
            <span onClick={onUserClick} style={{color: Colors.newDesign.mainBlue, fontFamily: 'PloniMedium', cursor: 'pointer'}}>{t('here')}</span>
        </span>
    )
}

export const resetAndClose = (resetForm, dispatch, onClose) => {
    const previousState = store.getState().stepper.previousState;
    if (previousState) {
        resetForm()
        dispatch(popStepperStack())
    } else {
        resetForm()
        dispatch(cleanStepper())
        if(onClose) {
            onClose();
        }
        else { //Default fallback
            dispatch(closeModal({modalName: CREATE_EVENT_MODAL}));
        }
    }
}

const openCreateUserPopup = (resetForm, dispatch, navigate) => {
    sendDataEvent('click Invite Clients to Your App what next');
    resetAndClose(resetForm, dispatch)
    dispatch(openModal({modalName: CREATE_USER_MODAL, props: navigate}))
}

const openSiteLink = (resetForm, dispatch, navigate) => {
    resetAndClose(resetForm, dispatch)
    sendDataEvent('click my schedule link what next');
    navigate('/my-links-schedule')
}

const sendMessage = async (params, dispatch) => {
    try {
        const numberOfRecipients = await sendMessageFetch(params, dispatch)
        if (params.type === SMS || params.type === EMAIL) {
            dispatch(updateBox(params.type === SMS ? {sms_display_phone: params.senderID} : {email: params.senderID}))
        }
        dispatch(toggleStepperSuccess({
            type: TOAST_TYPE,
            message: t('send-successfully-count', {number: numberOfRecipients})
        }))
    } catch (error) {
        dispatch(hideLoader())
        dispatch(setError({message: 'sendMessageError', error}))
    }
}

export const sendMessageFetch = async (params, dispatch) => {
    params.message_array = params.messageContent.replaceAll('[', '[{').replaceAll(']', '}]').split(/[\[\]]/g).map((s) => {
        if (new RegExp(/\{.*\}/g).test(s)) {
            const tags = getMessageTags()
            const tag = tags.find(tag => s === `{${t(tag.label)}}`)
            if(tag) return ({key: tag.label, keyDB: tag.id, dateType: tag.dateType, timeType: tag.timeType, numType: tag.numType, translate: tag.translate})
        }
        return ({text: s.replaceAll(/ +/g, ' ')}) // replace mutiple spaces with only one space
    })
    if (params.class_name === 'ReportMessage') {
        params.users = params.users?.filter(u => u.isSelected)
    }
    params.users?.forEach(user => {
        user.message = params.message_array.map(ma => {
            if(ma.text) {
                return ma
            } else if(ma.dateType) {
                return {staticKey: user[ma.keyDB] ? moment(user[ma.keyDB]).format(getBoxDateFormat()) : t('noDateMessage')}
            } else if (ma.timeType) {
                return {staticKey: user[ma.keyDB] ? moment(user[ma.keyDB], dbTimeFormat).format(getBoxTimeFormat()) : t('noTimeMessage')}
            } else if(ma.numType) {
                return {staticKey: user[ma.keyDB].toLocaleString(getLocale())}
            } else if(ma.translate) {
                return {staticKey: t(user[ma.keyDB])}
            } else {
                return {staticKey: user[ma.keyDB]}
            }
        });
    });
    if (params.locations_box_fk < 0) {
        params.locations_box_fk = null
    }
    dispatch(showLoader())
    const res = await fetchApi('messagesCenter', 'POST', params, false, true);
    dispatch(hideLoader())
    return res.users_count ?? params.users.length - Object.keys(res).length;
}

const saveEmailAfterPurchase = async (params, dispatch) => {
    try {
        const result = await fetchApi('setEmailAfterPurchase', 'post', params, false, true)
        dispatch(setAngularPath(null))
        dispatch(toggleStepperSuccess({
            type: TOAST_TYPE ,
            message: t('savedSuccessfully')
        }))
        setTimeout(() => dispatch(setAngularPath('settings')), 200)
    } catch (error) {
        dispatch(setError({message: t('email-after-purchase-error'), error}))
    }

}

const saveUser = (flowType, dispatch, params, target) => {
    if (!store.getState().userFlow.loading) {
        switch (flowType) {
            case flowOptions.STAFF_MEMBER:
                return dispatch(saveStaffMember({params, target}));
            case flowOptions.LEAD:
                return dispatch(saveLead({params}));
        }
    }
}

const savePenalty = async (params, dispatch) => {
    try {
        params.type_value = params[params.type] || 0;
        const tags = getPenaltiesTags(params.type)
        tags.forEach(tag => {
            params.message_content = params.message_content.replaceAll(`[${tag.label}]`, `{{#${tag.id}#}}`)
        });
        params.free_space = (params.allow_free_space && params.free_space) || null;
        const result = await fetchApi('penalties/set', 'post', params, false, true)
        dispatch(setAngularPath(null))
        dispatch(toggleStepperSuccess({
            type: TOAST_TYPE ,
            message: t('savedSuccessfully')
        }))
        setTimeout(() => dispatch(setAngularPath('settings')), 200)
    } catch (error) {
        dispatch(setError({message: t('penalty-error'), error}))
    }
}

const saveTask = async (params, dispatch) => {
    if (!params?.task_type_fk) return;
    try {
        if ((![targetAudienceTasks.GENERAL, targetAudienceTasks.SPECIFIC_USER, targetAudienceTasks.PERSONAL].includes(params?.targeted)) && !params?.confirmed) {
            dispatch(setError({message: 'confirm-edit-task', error: 'confirm-edit-task'}))
        }
        else {
            if (params?.targeted === targetAudienceTasks.GENERAL) {
                params.user_fk = null;
            }
            dispatch(showLoader())
            const result = await fetchApi('tasks/saveTask', 'post', params, false, true)
            if(params?.is_done_button_click) {
                await fetchApi('tasks/setTaskDone', 'POST', {id: params?.id, done: params?.done}, false, true)
            }
            dispatch(hideLoader())
            dispatch(toggleStepperSuccess({
                type: TOAST_TYPE ,
                message: result?.count ? t('task-saved-number-users', {number: result?.count}) : t('savedSuccessfully')
            }))
            dispatch(setFilters())
            const path = store.getState().rootManager.angularPath;
            if (path) {
                sendMessageToAngular('updated_tasks', {}, 'main_angular_frame_id')
            }
            const responseTasks = await fetchApi(`tasks/getTasksCount/null`, 'GET', null, false, true).catch(_ => 0);
            const responseFinance = await fetchApi(`recurringPayment/getDeclinedCount/null`, 'GET', null, false, true);
            Promise.all([responseTasks, responseFinance]).then((res) => dispatch(setCounters({ tasks: res[0], finance: res[1] })));
        }
    } catch (error) {
        dispatch(hideLoader())
        dispatch(setError({message: t('task-error'), error}))
    }
}

const sendConvertLink = async (params, dispatch) => {
    try {
        dispatch(showLoader())
        params.message_array = getMessageParams(params)
        const result = await fetchApi('usersBoxes/sendConvertLink', 'post', params, false, true)
        dispatch(hideLoader())
        dispatch(toggleStepperSuccess({
            type: TOAST_TYPE ,
            message: t('link-has-sent-to-lead', {full_name: params.full_name})
        }))
        const path = store.getState().rootManager.angularPath;
        if (path) {
            sendMessageToAngular('updated_lead_logs', {}, 'main_angular_frame_id')
        }
    } catch (error) {
        dispatch(hideLoader())
        dispatch(setError({message: 'sendMessageError', error}))
    }
}

const saveCustomField = async (params, dispatch) => {
    try {
        dispatch(showLoader());
        await fetchApi(`customField/${params.custom_field_id ? 'update' : 'create'}CustomField`, 'POST', params, false, true);
        dispatch(toggleStepperSuccess({type: TOAST_TYPE, message: t(`customField${params.custom_field_id ? 'Updated' : 'Created'}Successfully`)}))
        dispatch(getScheduleCustomFields());
        dispatch(hideLoader());
        dispatch(setAngularPath(null))
        setTimeout(() => dispatch(setAngularPath('settings')), 400);
    } catch (error) {
        dispatch(hideLoader())
        const err = error?.error || {message: t('customField-error'), error}
        dispatch(setError(err))
    }
}
const bookToHug = async (params, dispatch) => {
    try {
        dispatch(showLoader());
        dispatch(toggleScheduleLoader(true));

        const props = store.getState().stepper.props
        if(props.user) {
            const data = {payment_info: params.payment_info, user_id: props.user.user_fk}
            await fetchApi('payments/purchase', 'POST', data, false, true);
        } else {
            const res = await fetchApi('createUserAndPurchase', 'POST', params, false, true);
            dispatch(saveNewUser(res.userBox))
        }

        dispatch(toggleStepperSuccess({type: PAGE_TYPE}))

        dispatch(toggleScheduleLoader(false));
        dispatch(hideLoader());
    } catch (error) {
        dispatch(toggleScheduleLoader(false));
        dispatch(hideLoader())
        console.log('bookToHug error', error)
        dispatch(setError(error))
    }
}

const convertLeadAndBookToHug = async (params, dispatch) => {
    try {
        dispatch(showLoader());
        dispatch(toggleScheduleLoader(true));
        const res = await fetchApi('createUserAndPurchase', 'POST', params, false, true);
        dispatch(saveNewUser(res.userBox))
        dispatch(toggleStepperSuccess({type: PAGE_TYPE}))

        dispatch(toggleScheduleLoader(false));
        dispatch(hideLoader());
    } catch (error) {
        dispatch(toggleScheduleLoader(false));
        dispatch(hideLoader())
        console.log('bookToHug error', error)
        dispatch(setError(error))
    }

}

export const getSuccessPrimaryBtnConfig = (params = {}) => {
    const flowName = store.getState().stepper.name

    switch (flowName) {
        case flowOptions.BOOK_TO_HUG_FLOW:
        case flowOptions.BOOK_TO_HUG_TRIAL:
        case flowOptions.BOOK_TO_HUG_CONVERT_LEAD:
            return {text: t('back-to-hug'), onClick: () => params.onClick()}
        default:
            return null;
    }
}

export const getFlowWidth = (params = {}) => {
    const flowName = store.getState().stepper.name

    switch (flowName) {
        case flowOptions.BOOK_TO_HUG_FLOW:
        case flowOptions.BOOK_TO_HUG_TRIAL:
        case flowOptions.BOOK_TO_HUG_CONVERT_LEAD:
            return '950px'
        default:
            return null;
    }
}

const saveCreateTaskType = (params, dispatch) => {
    dispatch(saveTaskType(params))
    if(params.id) dispatch(setFilters())

}

const saveBoxSettingsFlow = async (propName, params, dispatch) => {
    try {
        dispatch(showLoader());
        const res = await fetchApi(`boxes/settings`, 'POST', {...params, prop_name: propName}, false, true);
        dispatch(hideLoader());
        dispatch(updateBox({settings: {...store.getState().box.box.settings, [propName]: res?.properties}}))
        dispatch(toggleStepperSuccess({type: TOAST_TYPE, message: t(`savedSuccessfully`)}))
    } catch (error) {
        dispatch(hideLoader())
        dispatch(setError(t('error-saving-settings')))
    }
}

const sendItemLink = async (params, dispatch) => {
    try {
        let link
        dispatch(showLoader())
        const route = params.item_type === 'item' ? 'shop' : 'membership'
        if(params.available_on_app) {
            link = generateSiteV2Link(`${route}/${params.item_id}`, {referer: 'PLUGIN'})
        } else {
            const oneTimeToken = await fetchApi('generateOneTimeLinkId', 'post', {itemId: params.item_id, pageType: params.item_type === 'item' ? 'item' : 'membership'}, false, true)
            link = generateSiteV2Link(`${route}/${params.item_id}`, {referer: 'PLUGIN'}) + `&uuid=${oneTimeToken}`
        }
        params.message_array = getMessageParams(params)
        await fetchApi('usersBoxes/sendLinkToMembership', 'post', {...params, membership_link: link}, false, true)
        dispatch(hideLoader())
        dispatch(toggleStepperSuccess({
            type: TOAST_TYPE ,
            message: t('link-has-sent-to-member', {full_name: params.full_name})
        }))
    } catch (error) {
        dispatch(hideLoader())
        dispatch(setError({message: 'sendMessageError', error}))
    }
}

const connectUserToGroup = async (params, dispatch) => {
    try {
        dispatch(showLoader())
        const res = await fetchApi('usersBoxes/connectUserToGroup', 'post', params, false, true)
        dispatch(hideLoader())
        dispatch(toggleStepperSuccess({
            type: TOAST_TYPE ,
            message: t('savedSuccessfully')
        }))
        sendMessageToAngular('refresh_group_members', {}, 'main_angular_frame_id')
    } catch (error) {
        dispatch(hideLoader())
        if (error?.error) dispatch(setUserFlow({error: error.error?.message, user: error.error?.messageToUser}))
    }

}

const bookForTrialHug = async (params, dispatch) => {
    try {
        dispatch(showLoader())
        dispatch(toggleScheduleLoader(true));
        const box = store.getState().box.box;
        const props = store.getState().stepper.props

        let extras = {
            payForSlot: true,
            purchaseData: {
                cart: params.payment_info.cart,
                payment_object: params.payment_info,
                boxes_id: box.id
            }
        }
        const reqParams = {
            lead_id: props.user.user_fk,
            isTrial: true,
            schedule_id: props.target.eventData.id,
            extras: extras
        }
        await fetchApi('purchaseTrialClass', 'POST', reqParams, false, true);
        dispatch(hideLoader())
        dispatch(toggleScheduleLoader(false));
        dispatch(toggleStepperSuccess({type: PAGE_TYPE}))

    } catch(error) {
        dispatch(toggleScheduleLoader(false));
        dispatch(hideLoader())
        console.log('bookForTrialHug error', error)
        dispatch(setError(error))
    }
}

const saveActivityHours = async (values, dispatch) => {
    try {
        dispatch(showLoader())
        let params = {apply: values.apply || 'all'};
        if (params.apply === 'all') {
            params.activityHours = values.multiDayConfig
        }
        if (params.apply === 'each') {
            params.gates = values.gates.map(gate => ({id: gate.id, activityHours: gate.multiDayConfig}))
        }
        const res = await fetchApi('gates/saveActivityHours', 'POST', {...params}, false, true)
        // update store
        const box = store.getState().box.box;
        const updatedLocations = box.locations_box.map(location => {
            return {
                ...location,
                gates: location.gates.map(gate => {
                    const resGate = res.find(resGate => resGate.id === gate.id)
                    return resGate ? {...gate, properties: resGate.properties} : gate
                })
            }
        })
        dispatch(hideLoader())
        dispatch(updateBox({locations_box: updatedLocations}))
        dispatch(toggleStepperSuccess({
            type: TOAST_TYPE ,
            message: t('activity-hours-saved')
        }))
    } catch (error) {
        dispatch(hideLoader())
        dispatch(setError(error))
    }
}

